import React, {useEffect, useState} from "react";
import styles from "./courseSessionsAnalytics.module.css";
import VTPTable from "../base/vtpTable";
import VTPSortableTableHeader, {SortingState, useSortingStateMachine,} from "../base/vtpSortableTableHeader";
import {Course, GetUserRole} from "../../lib/apiModels";
import {useEndpointPagination} from "../../hooks/usePagination";
import {useVTPCloud} from "../../context/vtpCloud-context";
import PageSection from "../base/pageSection";
import {ReactComponent as PersonIcon} from "../../assets/navigation/person.svg";
import CommonUtilities from "../../lib/common";
import {defaultDateRange, formatSessionLength} from "../../pages/analytics";
import TablePagination from "../base/table/tablePagination";
import UserAnalyticsButton from "./userAnalyticsButton";
import commonStyles from "../../pages/analytics.module.css";
import VTPStyles from "../../styles/vtpStyles";
import VTPDateRangePicker from "../base/vtpDateRangePicker";
import {useTranslation} from "react-i18next";

enum CourseSessionAnalyticsSortingKey {
    Email = "email",
    SessionName = "sessionName",
    SessionStart = "sessionStart",
    Duration = "duration",
    Username = "username",
    Role = "role"
}
const CourseSessionAnalytics = (props: { course: Course }) => {
    // Component dependencies.
    const { getSessionListAnalyticsView } = useVTPCloud();
    const {t} = useTranslation();

    // Paging and sorting
    const [dateRange, setDateRange] = useState(defaultDateRange());
    const sortingStateMachine = useSortingStateMachine(
        CourseSessionAnalyticsSortingKey.SessionStart,
        SortingState.Desc
    );
    
    const paging = useEndpointPagination(getSessionListAnalyticsView, 6, {
        sort: `${sortingStateMachine.state.value}:${sortingStateMachine.state.sortingState}`,
        courseId: props.course.courseId,
        from: dateRange.from.toISOString(),
        to: dateRange.to.toISOString(),
    });

    // Sorting
    useEffect(() => {
        const sortingParam = `${sortingStateMachine.state.value}:${sortingStateMachine.state.sortingState}`;
        if (paging.options.sort !== sortingParam) {
            paging.setOptions({
                ...paging.options,
                sort: sortingParam,
            });
        }
    }, [sortingStateMachine.state]);

    // Date range changes
    useEffect(() => {
        if (
            paging.options.from !== dateRange.from.toISOString() ||
            paging.options.to !== dateRange.to.toISOString()
        ) {
            paging.setOptions({
                ...paging.options,
                from: dateRange.from.toISOString(),
                to: dateRange.to.toISOString(),
            });
        }
    }, [dateRange]);
    
    const columns = (): React.ReactNode[] => {
        return [
            <VTPSortableTableHeader
                key={CourseSessionAnalyticsSortingKey.SessionStart}
                text={t("analyticsPage.tableColumns.login")}
                value={CourseSessionAnalyticsSortingKey.SessionStart}
                stateMachine={sortingStateMachine}
            />,
            <VTPSortableTableHeader
                key={CourseSessionAnalyticsSortingKey.Email}
                text={t("analyticsPage.tableColumns.email")}
                value={CourseSessionAnalyticsSortingKey.Email}
                stateMachine={sortingStateMachine}
            />,
            <VTPSortableTableHeader
                key={CourseSessionAnalyticsSortingKey.Username}
                text={t("analyticsPage.tableColumns.userName")}
                value={CourseSessionAnalyticsSortingKey.Username}
                stateMachine={sortingStateMachine}
            />,
            <VTPSortableTableHeader
                key={CourseSessionAnalyticsSortingKey.Role}
                text={t("analyticsPage.tableColumns.role")}
                value={CourseSessionAnalyticsSortingKey.Role}
                stateMachine={sortingStateMachine}
            />,
            <VTPSortableTableHeader
                key={CourseSessionAnalyticsSortingKey.SessionName}
                text={t("analyticsPage.tableColumns.session")}
                value={CourseSessionAnalyticsSortingKey.SessionName}
                stateMachine={sortingStateMachine}
            />,
            <VTPSortableTableHeader
                key={CourseSessionAnalyticsSortingKey.Duration}
                text={t("analyticsPage.tableColumns.duration")}
                value={CourseSessionAnalyticsSortingKey.Duration}
                stateMachine={sortingStateMachine}
            />
        ];
    };

    const rows = (): React.ReactNode[][] => {
        if (!paging.pageData) {
            return [];
        }
        
        return paging.pageData?.map((data) => {
            return [
                CommonUtilities.FormatDate(data.sessionStart),
                <UserAnalyticsButton key={data.userId} userId={data.userId} email={data.email} />,
                data.username,
                GetUserRole(data.role, t),
                data.sessionName,
                formatSessionLength(data.duration)
            ];
        });
    };

    return (
        <PageSection
            header={{
                icon: <PersonIcon />,
                text: props.course.name,
            }}
            style={{ backgroundColor: "#FFFFFF" }}
        >
            <div className={commonStyles.datePickerGroup}>
                <div className={VTPStyles.Typography.Headers.H2SubheaderSmall}>
                    {t("analyticsPage.courseSessions.subheader")}
                </div>
                <VTPDateRangePicker
                    onSelected={(from, to) => setDateRange({ from: from, to: to })}
                />
            </div>
            <div className={styles.tableContainer}>
                <VTPTable columns={columns()} rows={rows()} />
                <div className={styles.paging}>
                    <TablePagination
                        currentPage={paging.pagingInfo.CurrentPage}
                        totalPages={paging.pagingInfo.TotalPages}
                        nextPage={paging.nextPage}
                        previousPage={paging.previousPage}
                    />
                </div>
            </div>
        </PageSection>
    );
};

export default CourseSessionAnalytics;
