import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { StylingProps } from "../../../lib/common";
import styles from "./vtpTextInput.module.css";
import VTPStyles from "../../../styles/vtpStyles";

enum BorderType {
  RoundBorder,
  Underlined,
}

enum InputSize {
  Large,
  FormCard,
}

interface ValidationRule {
  validationFunction: (input: string) => boolean | Promise<boolean>;
  validationErrorMessage?: string;
}

interface InputProps extends StylingProps {
  placeholder?: string;
  value?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onSubmit?: () => void;
  readonly?: boolean;
  validationRules?: ValidationRule[];
  validateOnValueChange?: boolean;
  placeholderFloating?: boolean;
  prependElement?: ReactNode;
  appendElement?: ReactNode;
  borderStyle?: BorderType;
  inputSize?: InputSize;
}

const borderClass = (borderType?: BorderType): string => {
  switch (borderType) {
    case BorderType.Underlined: {
      return styles.vtpTextInputUnderlineBorder;
    }
    case BorderType.RoundBorder: {
      return styles.vtpTextInputRoundBorder;
    }
    default: {
      return "";
    }
  }
};

const inputSizeClass = (inputSize?: InputSize): string => {
  switch (inputSize) {
    case InputSize.Large: {
      return ""; // No class needed, this is default look
    }
    case InputSize.FormCard: {
      return styles.vtpFormCardTextInput;
    }
    default: {
      return "";
    }
  }
};

const VTPTextInput = (props: InputProps) => {
  const [value, setValue] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [placeholderFloat, setPlaceholderFloat] = useState<boolean>(true);

  const placeholderFloatClass = props.placeholderFloating
      ? styles.placeholderFloat
      : "d-none";

  useEffect(() => {
    if (props.value != undefined) {
      setValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (!isFocused) {
      setPlaceholderFloat(!!value);
    }

    if (props.validateOnValueChange) {
      doValidation();
    }
  }, [value]);

  useEffect(() => {
    if (!value) {
      setPlaceholderFloat(isFocused);
    }
  }, [isFocused]);

  const doValidation = async (): Promise<boolean> => {
    if (props.validationRules && !props.readonly) {
      for (const rule of props.validationRules) {
        const result = rule.validationFunction(value);
        const isValid = result instanceof Promise ? await result : result;
        if (!isValid) {
          setErrorMessage(rule.validationErrorMessage);
          setIsValid(false);
          return false;
        }
      }
    }
    setIsValid(true);
    setErrorMessage(undefined);
    return true;
  };

  const valueChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (!props.validateOnValueChange) {
      setIsValid(true);
    }
    setValue(event.target.value);
    props.onChange?.(event);
  };

  const handleKeyUp = async (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      const isInputValid = await doValidation();
      if (isInputValid) {
        props.onSubmit?.();
      }
    }
  };

  return (
      <div
          className={`${styles.vtpTextInputContainer} ${borderClass(props.borderStyle)}
      ${inputSizeClass(props.inputSize)} ${props.className ?? ""}
      ${isValid ? "" : styles.vtpTextInputInvalid} 
      ${props.readonly ? styles.inputReadOnly : ""}`}
          style={props.style}
      >
        {props.prependElement}
        <div className={styles.vtpTextInputGroup}>
        <span
            className={`${styles.vtpTextPlaceholderInput} ${
                placeholderFloat || props.readonly ? placeholderFloatClass : ""
            } ${VTPStyles.Typography.Body.Medium}`}
        >
          {props.placeholder ?? "Type Here"}
        </span>
          {errorMessage && !isValid ? (
              <span className={styles.vtpTextInputError}>
            *{errorMessage}
          </span>
          ) : null}
          <input
              name={props.name}
              className={`${VTPStyles.Typography.Body.Medium} ${VTPStyles.Color.Text.PrimaryColor}`}
              value={value}
              onKeyUp={handleKeyUp}
              onFocus={() => {
                setIsFocused(true);
                if (!props.validateOnValueChange) {
                  setIsValid(true);
                }
              }}
              onBlur={() => {
                setIsFocused(false);
                doValidation();
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={valueChanged}
              readOnly={props.readonly}
          />
        </div>
        {props.appendElement}
      </div>
  );
};

export default VTPTextInput;
export { BorderType, InputSize };
export type { InputProps };
