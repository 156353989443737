import React, { useEffect } from "react";
import pageSectionStyles from "../components/base/pageSection.module.css";
import { useAppContext } from "../context/app-context";
import SubscriptionInfoCard from "../components/subscriptions/subscriptionInfoCard";
import { useVTPCloud } from "../context/vtpCloud-context";
import { LicenseStatus } from "../lib/apiModels";
import PageSection from "../components/base/pageSection";
import { ReactComponent as SubscriptionsIcon } from "../assets/navigation/subscriptions.svg";
import VTPStyles from "../styles/vtpStyles";
import VTPNoItems from "../components/base/vtpNoItems";
import { useNavigate } from "react-router-dom";
import { Config } from "../config";
import { useTranslation } from "react-i18next";

const YourSubscriptions = () => {
  const { t } = useTranslation();
  const { getSelectedTenant } = useAppContext();
  const { refreshSelectedTenant } = useVTPCloud();
  const navigate = useNavigate();

  useEffect(() => {
    if (!Config.enableDashboardSubscriptionsPage) {
      navigate(Config.users);
    }
    refreshSelectedTenant();
  }, []);

  return (
    <div className={pageSectionStyles.singleSectionPage}>
      <PageSection
        header={{
          icon: <SubscriptionsIcon />,
          text: t("subscriptionsPage.header"),
        }}
        subheader={t("subscriptionsPage.subheader")}
      >
        {!getSelectedTenant?.licenses.length ? (
          <VTPNoItems>
            <div className={VTPStyles.Typography.Body.Medium}>
              {t("subscriptionsPage.noItems")}
            </div>
          </VTPNoItems>
        ) : null}
        {getSelectedTenant?.licenses
          .filter((l) => l.status == LicenseStatus.Active)
          .map((license) => {
            return <SubscriptionInfoCard key={license.id} license={license} />;
          })}
      </PageSection>
    </div>
  );
};

export default YourSubscriptions;
