const env = window as any;
export class Config {
  // Dashboard routes
  static loginRoute = "/login";
  static dashboardRoute = "/dashboard";
  static loginDeviceCodeRoute = "/code";
  static buySubscriptionRoute = "/subscriptions/buy";
  static subscriptions = "/subscriptions";
  static users = "/users";
  static analytics = "/analytics";
  static acceptInvitationRoute = "/organization/accept";
  static verifyEmail = "/verify";
  static firstTimeSetup = "/setup";
  static almostThere = "/almost-there";
  static invoiceOverview = "/invoices";
  static accountSettings = "/account-settings";
  static files = "/files";
  static support = "/support";

  // Default routes
  static defaultTraineePage = this.files;
  static defaultPage = this.users;

  // Other constants
  static localStorageTenantKey = "selectedTenant";
  static localStorageAlertsKey = "alerts";
  static localStorageUploadsKey = "uploadQueue";

  // API config
  static clientName = env.CLIENT_NAME;
  static environment = env.ENVIRONMENT;
  static versionTag = env.VERSION_TAG;
  static envUrlPrefix = env.ENV_URL_PREFIX;
  static apiBaseHost = env.API_HOST_NAME;
  static apiPath = `${Config.envUrlPrefix}${Config.apiBaseHost}/${Config.clientName}/v2`;
  static dashboardOrigin =
    window.location.protocol + "//" + window.location.hostname;

  // Kanda urls

  static addCourseUrl =
    Config.environment == "prod"
      ? "https://www.kanda.dk/marketplace"
      : `${Config.buySubscriptionRoute}?stripe=price_1N16mDAKW6QQma571DVstEIi`; // Link directly to buy a test course;

  // OpenID Connect config
  static oidcAuthDomain = env.AUTH0_CUSTOM_DOMAIN;
  static oidcClientId = env.OIDC_CLIENT_ID;
  static oidcScopes = ["openid", "profile", "email", "offline_access"];
  static oidcDeviceCodeInputUrl = env.OIDC_DEVICE_CODE_INPUT_URL;

  // Feature flags
  static enableDashboardSubscriptionsPage = this.ParseBooleanFeatureFlag(
    env.ENABLE_DASHBOARD_SUBSCRIPTIONS_PAGE
  );
  static enableDashboardCoursesPage = this.ParseBooleanFeatureFlag(
    env.ENABLE_DASHBOARD_COURSES_PAGE
  );
  static enableDashboardDownloadAppLink = this.ParseBooleanFeatureFlag(
    env.ENABLE_DASHBOARD_DOWNLOAD_APP_LINK
  );
  static enableDashboardGetSupportTab = this.ParseBooleanFeatureFlag(
    env.ENABLE_DASHBOARD_GET_SUPPORT_TAB
  );
  static enableDashboardCreateOrganisation = this.ParseBooleanFeatureFlag(
    env.ENABLE_DASHBOARD_CREATE_ORGANISATION
  );

  static enableAnalytics = this.ParseBooleanFeatureFlag(env.ENABLE_ANALYTICS);

  // TODO: Deprecate this once Auth0 is fully rolled out
  static oidcLegacyDeviceCodeInputUrl = "https://microsoft.com/devicelogin";

  private static ParseBooleanFeatureFlag(flag: string): boolean {
    return flag?.toLowerCase() === "true";
  }
}
