import { CSSProperties, PropsWithChildren } from "react";
import { createSearchParams, NavigateFunction } from "react-router-dom";
import { Config } from "../config";

class CommonUtilities {
  public static IsLocalhost() {
    return (
      location.hostname === "localhost" || location.hostname === "127.0.0.1"
    );
  }

  public static IsEmail(inputValue: string | undefined): boolean {
    if (!inputValue) return false;

    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputValue);
  }

  public static NavigateToUrlWithRedirectParam(
    navigateFunction: NavigateFunction,
    navigateToUrl: string,
    redirectUrl: string
  ) {
    navigateFunction({
      pathname: navigateToUrl,
      search: createSearchParams({
        redirect_url: encodeURI(redirectUrl),
      }).toString(),
    });
  }

  public static GetRedirectUrlFromParams(
    searchParams: URLSearchParams,
    defaultUrl: string | undefined = Config.users
  ) {
    const redirectUrl = searchParams.get("redirect_url");

    if (redirectUrl) {
      return decodeURI(redirectUrl);
    } else {
      return defaultUrl;
    }
  }

  public static FormatDate(value: Date | string | number | undefined) {
    if (!value) {
      return "-";
    }

    const parsedDate = new Date(value);

    const day = String(parsedDate.getDate()).padStart(2, '0');
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const year = parsedDate.getFullYear();

    return `${day}/${month}/${year}`;
  } 

  public static FormatFileSize(bytes: number, roundDecimals?: boolean) {
    const K_UNIT = 1000;
    const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

    if (bytes == 0) {
      return "0 Byte";
    }

    const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
    let parsedValue = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(2));

    if (roundDecimals) {
      parsedValue = Math.round(parsedValue);
    }

    return parsedValue + " " + SIZES[i];
  }
}

interface StylingProps extends PropsWithChildren {
  style?: CSSProperties;
  className?: string;
}

export default CommonUtilities;
export type { StylingProps };
