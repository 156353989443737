import React, { useEffect, useState } from "react";
import styles from "./invitationHandler.module.css";
import { useAppContext } from "../../context/app-context";
import {UserTenantInviteResponse} from "../../lib/apiModels";
import { useVTPCloud } from "../../context/vtpCloud-context";
import VTPSimpleConfirmationModal from "../modal/vtpSimpleConfirmationModal";
import useInterval from "../../hooks/useInterval";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { AlertType, useAlert } from "../../hooks/useAlert";
import VTPLoadingSpinner from "../base/vtpLoadingSpinner";
import { useNavigate } from "react-router-dom";
import { Config } from "../../config";
import { StylingProps } from "../../lib/common";
import { useTranslation } from "react-i18next";

interface InvitationHandlerProps extends StylingProps {
  startPolling: boolean;
  onInvitationAccepted?: () => any;
}

const InvitationHandler = (props: InvitationHandlerProps) => {
  const [pendingInvitations, setPendingInvitations] = useState<
    UserTenantInviteResponse[]
  >([]);
  const {
    getInvites,
    declineInvite,
    acceptInvite,
    refreshUserTenants,
    refreshUserProfile,
    getTenant,
  } = useVTPCloud();
  const { t } = useTranslation();
  const { getUserProfile, setSelectedTenant } = useAppContext();
  const { pushAlert } = useAlert();
  const navigate = useNavigate();

  const [declineLoading, setDeclineLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);

  useEffect(() => {
    if (getUserProfile && props.startPolling) {
      pollInvites();
    }
  }, [getUserProfile, props.startPolling]);

  // Poll tenant invites every 10 seconds.
  useInterval(() => {
    pollInvites();
  }, 10000);

  const pollInvites = () => {
    if (
      getUserProfile &&
      props.startPolling
    ) {
      getInvites()
        .then((response) => {
          setPendingInvitations(response);
        })
        .catch((res) => console.log("Failed to poll invitations."));
    }
  };

  const handleDecline = (invitation: UserTenantInviteResponse) => {
    if (declineLoading) {
      return;
    }

    setDeclineLoading(true);
    declineInvite(invitation.id)
      .then(() =>
        pushAlert({
          message: t("invitationHandler.notifications.declineInviteSuccess", { organisationName: invitation.tenantName }),
          type: AlertType.Success,
        })
      )
      .catch(() =>
        pushAlert({
          message: t("invitationHandler.notifications.declineInviteError", { organisationName: invitation.tenantName }),
          type: AlertType.Error,
        })
      )
      .finally(() => {
        setDeclineLoading(false);
        pollInvites();
      });
  };

  const handleAccept = (invitation: UserTenantInviteResponse) => {
    if (acceptLoading) {
      return;
    }

    setAcceptLoading(true);
    acceptInvite(invitation.id)
      .then(() => {
            pushAlert({
              message: t("invitationHandler.notifications.acceptInviteSuccess", {organisationName: invitation.tenantName}),
              type: AlertType.Success,
            });
            props.onInvitationAccepted?.();
          }
      )
      .catch(() =>
        pushAlert({
          message: t("invitationHandler.notifications.acceptInviteError", { organisationName: invitation.tenantName }),
          type: AlertType.Error,
        })
      )
      .finally(() => {
        setAcceptLoading(false);
        pollInvites();
        refreshUserProfile()
          .then(() => {
            refreshUserTenants().then(() => {
              getTenant(invitation.tenantId)
                .then((res) => setSelectedTenant(res))
                .finally(() => navigate(Config.users));
            });
          })
          .catch(() =>
            console.log(
              "Failed to refresh and select tenant after accepting invitation."
            )
          );
      });
  };

  return (
    <>
      {pendingInvitations[0] ? (
        <VTPSimpleConfirmationModal
          header={t("invitationHandler.header")}
          body={
            pendingInvitations[0].invitedByEmail
              ? t("invitationHandler.text1", {
                  email: pendingInvitations[0].invitedByEmail,
                  organisationName: pendingInvitations[0].tenantName,
                })
              : t("invitationHandler.text2", {
                  organisationName: pendingInvitations[0].tenantName,
                })
          }
          className={props.className}
          buttons={[
            <VTPButton
              key={"cancel"}
              className={`${styles.cancelButton} ${styles.invitationModalButton}`}
              size={ButtonSize.Medium}
              type={ButtonType.Tertiary}
              onClick={() => handleDecline(pendingInvitations[0])}
            >
              {declineLoading ? (
                <VTPLoadingSpinner className={styles.cancelButtonSpinner} />
              ) : (
                  t("invitationHandler.inputs.decline")
              )}
            </VTPButton>,
            <VTPButton
              key={"join"}
              className={styles.invitationModalButton}
              size={ButtonSize.Medium}
              type={ButtonType.Primary}
              onClick={() => handleAccept(pendingInvitations[0])}
            >
              {acceptLoading ? <VTPLoadingSpinner /> : t("invitationHandler.inputs.join")}
            </VTPButton>,
          ]}
        />
      ) : null}
    </>
  );
};

export default InvitationHandler;
