import React from "react";
import AccountInfoCard from "../components/settings/accountInfoCard";
import SigninInformation from "../components/settings/signinInformation";
import DeleteAccount from "../components/settings/deleteAccount";
import PageSection from "../components/base/pageSection";
import { ReactComponent as SettingsIcon } from "../assets/navigation/settings.svg";
import { useTranslation } from "react-i18next";

const AccountSettings = () => {
  const { t } = useTranslation();

  return (
    <div>
      <PageSection
        header={{ 
            icon: <SettingsIcon />, 
            text: t("accountPage.header")
      }}
        subheader={t("accountPage.subheader")}
      >
        <AccountInfoCard />
      </PageSection>
      <PageSection subheader={t("accountPage.signInMethod")}>
        <SigninInformation />
      </PageSection>
      <PageSection subheader={t("accountPage.deleteAccount")}>
        <DeleteAccount />
      </PageSection>
    </div>
  );
};

export default AccountSettings;
