import styles from "../users/inviteUsersList.module.css"
import React, { ReactNode, useState } from "react";
import { Row } from "react-bootstrap";
import InviteUsersList, { UserInvite } from "../users/inviteUsersList";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { AlertType, useAlert } from "../../hooks/useAlert";
import { useAppContext } from "../../context/app-context";
import { User } from "../../lib/apiModels";
import VTPModal from "./vtpModal";
import {useTranslation} from "react-i18next";

interface Props {
  onCancel?: () => void;
  onSave?: () => void;
}

const InviteUsersModal = (props: Props) => {
  const {t} = useTranslation();
  const { inviteUser } = useVTPCloud();
  const { pushAlert } = useAlert();
  const { getSelectedTenant } = useAppContext();

  const [userInvites, setUserInvites] = useState<UserInvite[]>([]);

  const inviteUsers = () => {
    const activeTenant = getSelectedTenant!;
    userInvites.forEach(function (inv) {
      inviteUser({
        email: inv.email,
        role: inv.role,
      }).then((u: User) => {
        props.onSave?.();
        pushAlert({
          message: t("inviteUserModal.notifications.userInvited", {email: u.email, tenantName: activeTenant.displayName}),
          type: AlertType.Success,
        });
      });
    });
    props.onCancel?.();
  };

  const getModalButtons = (): ReactNode[] => {
    return [
      <VTPButton
        key="cancelButton"
        onClick={() => {
          props.onCancel?.();
        }}
        style={{ marginRight: "16px" }}
        size={ButtonSize.Medium}
        type={ButtonType.Tertiary}
      >
        {t("common.inputs.cancel")}
      </VTPButton>,
      <VTPButton
        key="inviteButton"
        onClick={() => inviteUsers()}
        disabled={userInvites.length < 1}
        size={ButtonSize.Medium}
        type={ButtonType.Primary}
      >
        {userInvites.length > 1 ? t("inviteUserModal.inputs.inviteUsers") : t("inviteUserModal.inputs.inviteUser")}
      </VTPButton>,
    ];
  };

  return (
    <VTPModal title={t("inviteUserModal.header")} className={styles.modalContainer} buttons={getModalButtons()}>
      <Row style={{ height: "403px" }}>
        <InviteUsersList
          listUpdateCallback={(items) => setUserInvites(items)}
          canDoLaterHint={false}
          showLabel={false}
        />
      </Row>
    </VTPModal>
  );
};

export default InviteUsersModal;
