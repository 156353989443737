import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useRef,
} from "react";
import { BridgeErrorCode } from "../lib/apiModels";

// Alert Types
enum AlertType {
  Error = "Error",
  Success = "Success",
  Info = "Info",
}

interface AlertData {
  message: string;
  type: AlertType;
  errorCode?: BridgeErrorCode;
  actions?: { action: () => any; text: string }[];
  id?: string;
  closeAlert?: () => void;
}

interface AlertManagerContextType {
  pushAlert: (alert: AlertData) => void;
  subscribe: (listener: (alert: string | AlertData) => void) => () => void;
  removeAlert: (alertId: string) => void;
}

const AlertManagerContext = createContext<AlertManagerContextType | undefined>(
  undefined
);

interface AlertManagerProviderProps {
  children: ReactNode;
}

export const AlertManagerProvider: React.FC<AlertManagerProviderProps> = ({
  children,
}) => {
  const listeners = useRef<((alert: AlertData | string) => void)[]>([]);

  const pushAlert = useCallback((alert: AlertData) => {
    if (!alert.id) {
      alert.id = Math.random().toString(36).substring(2, 9);
    }
    // Notifying listeners with the new alert
    listeners.current.forEach((listener) => listener(alert));
  }, []);

  const removeAlert = useCallback((alertId: string) => {
    // Notifying listeners with the ID of the alert to be removed
    listeners.current.forEach((listener) => listener(alertId));
  }, []);

  const subscribe = useCallback(
    (listener: (alert: AlertData | string) => void) => {
      listeners.current.push(listener);
      return () => {
        const index = listeners.current.indexOf(listener);
        if (index > -1) {
          listeners.current.splice(index, 1);
        }
      };
    },
    []
  );

  const value = { pushAlert, subscribe, removeAlert };

  return (
    <AlertManagerContext.Provider value={value}>
      {children}
    </AlertManagerContext.Provider>
  );
};

export const useAlert = (): AlertManagerContextType => {
  const context = useContext(AlertManagerContext);
  if (!context) {
    throw new Error(
      "useAlertManager must be used within an AlertManagerProvider"
    );
  }
  return context;
};

export { AlertType };
export type { AlertData };
