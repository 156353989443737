import React from "react";
import styles from "./deleteAccount.module.css";
import vtpStyles from "../../styles/vtpStyles";
import {useTranslation} from "react-i18next";

const DeleteAccount = () => {
  const { t } = useTranslation();
  
  return (
    <p
      className={`${vtpStyles.Typography.Body.Medium} ${vtpStyles.Color.Text.PrimaryColor} ${styles.deleteAccountInfo} `}
    >
      {`${t("accountPage.deleteAccountSection.text1")} `}
      <a href={`mailto:support@kanda.dk`}>support@kanda.dk</a> 
      {` ${t("accountPage.deleteAccountSection.text2")}`}
    </p>
  );
};

export default DeleteAccount;
