import React, { useEffect, useState } from "react";
import pageSectionStyles from "../components/base/pageSection.module.css";
import CoursesSummaryAnalytics from "../components/analytics/coursesSummaryAnalytics";
import CourseSessionAnalytics from "../components/analytics/courseSessionsAnalytics";
import { useLocation, useNavigate } from "react-router-dom";
import { Course, User } from "../lib/apiModels";
import { useAppContext } from "../context/app-context";
import { useVTPCloud } from "../context/vtpCloud-context";
import UserSessionsAnalytics from "../components/analytics/userSessionsAnalytics";
import {Config} from "../config";

const Analytics = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getCourses, getUser } = useVTPCloud();
  const { getSelectedTenant } = useAppContext();

  // TODO: Move this to appContext to avoid pulling same data over and over.
  const [courses, setCourses] = useState<Course[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<Course>();
  const [selectedUser, setSelectedUser] = useState<User>();

  useEffect(() => {
    if(!Config.enableAnalytics){
      navigate(Config.defaultPage);
    }
  }, []);
  
  useEffect(() => {
    if (getSelectedTenant) {
      getCourses().then((res) => {
        setCourses(res.items);
      });
    }
  }, [getSelectedTenant]);

  useEffect(() => {
    // Parse the query string to get the course parameter
    const searchParams = new URLSearchParams(location.search);
    const courseParam = searchParams.get("course");
    const userParam = searchParams.get("user");

    // Update the state with the extracted course parameter
    if (courseParam) {
      setSelectedCourse(
        courses?.find((c) => c.courseId === courseParam.toLowerCase())
      );
    } else {
      setSelectedCourse(undefined);
    }

    if (userParam && getSelectedTenant) {
      getUser(userParam)
        .then((res) => setSelectedUser(res))
        .catch(() =>
          console.log(`Failed to fetch user (${userParam}) for analytics`)
        );
    } else {
      setSelectedUser(undefined);
    }
  }, [location.search, courses]);

  const getAnalyticsView = () => {
    if (getSelectedTenant) {
      if (selectedCourse) {
        return <CourseSessionAnalytics course={selectedCourse} />;
      } else if (selectedUser) {
        return <UserSessionsAnalytics courses={courses} user={selectedUser} />;
      } else {
        return <CoursesSummaryAnalytics courses={courses} />;
      }
    }
  };

  return (
    <div
      className={pageSectionStyles.singleSectionPage}
      style={{ backgroundColor: "#FFFFFF" }}
    >
      {getAnalyticsView()}
    </div>
  );
};

const formatSessionLength = (lengthInMinutes: number) => {
  const days = Math.floor(lengthInMinutes / (60 * 24));
  const remainingMinutes = lengthInMinutes % (60 * 24);
  const hours = Math.floor(remainingMinutes / 60);
  const minutes = remainingMinutes % 60;

  const formattedDays = `${days}d`;
  const formattedHours = `${hours}h`;
  const formattedMinutes = `${minutes} min`;

  if (days > 0) {
    return `${formattedDays} ${formattedHours} ${formattedMinutes}`;
  } else if (hours > 0) {
    return `${formattedHours} ${formattedMinutes}`;
  } else {
    return formattedMinutes;
  }
};

const defaultDateRange = () => {
  const fromDate = new Date();
  fromDate.setUTCMonth(fromDate.getUTCMonth() - 3);

  return {from: fromDate, to: new Date()};
}

export default Analytics;
export { formatSessionLength, defaultDateRange };
