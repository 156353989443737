import React, { ReactElement, useEffect, useState } from "react";
import VTPSimpleModal from "../modal/vtpSimpleModal";
import styles from "./batchInfoModal.module.css";
import VTPStyles from "../../styles/vtpStyles";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import VTPTable from "../base/vtpTable";
import {
  GetUserRole,
  TenantLicense,
  TokenAssignment,
  User,
} from "../../lib/apiModels";
import { useEndpointListPagination } from "../../hooks/usePagination";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { useAppContext } from "../../context/app-context";
import VTPCheckbox from "../base/form/vtpCheckbox";
import { ReactComponent as UserIcon } from "../../assets/img/profile-icon.svg";
import VTPPagination from "../base/vtpPagination";
import CommonUtilities from "../../lib/common";
import { useAlert, AlertType } from "../../hooks/useAlert";
import VTPConfirmationModal from "../modal/vtpConfirmationModal";
import { ModalIcon } from "../modal/vtpModal";
import { useTranslation } from "react-i18next";

interface Props {
  tokenAssignments: TokenAssignment[];
  addedOnDate: string;
  license: TenantLicense;
  buttonClicked: () => void;
}

const BatchInfoModal = (props: Props) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<User[]>([]);
  const { getUser, updateToken } = useVTPCloud();
  const { getSelectedTenant } = useAppContext();
  const { pushAlert } = useAlert();
  const [tokenAssignments, setTokenAssignments] = useState<TokenAssignment[]>(
    []
  );

  const [renewalModalState, setRenewalModalState] = useState<{
    show: boolean;
    user?: User;
    onComplete?: () => void;
  }>({ show: false });

  const userPaging = useEndpointListPagination(
    getUser,
    props.tokenAssignments.map((a) => a.assignedToUserId),
    5
  );

  useEffect(() => {
    if (userPaging.pageData) {
      setUsers(userPaging.pageData);
    }
  }, [userPaging.pageData]);

  const getTokenAssignment = (user: User) => {
    return props.tokenAssignments.find((t) => t.assignedToUserId === user.id);
  };

  const isActiveInTenant = (user: User, tenantId: string): boolean => {
    return (
      user.userTenantRoles.find((ut) => ut.tenantId === tenantId) != undefined
    );
  };

  const userEmailCell = (email: string) => {
    return (
      <div className={`${styles.userEmailCell}`}>
        <UserIcon fill={"#0C615A"} />
        <span>{email}</span>
      </div>
    );
  };

  const userRoleInTenant = (
    user: User,
    tenantId: string
  ): string | undefined => {
    const role = isActiveInTenant(user, tenantId)
      ? user.userTenantRoles.find((ut) => ut.tenantId === tenantId)?.role
      : user.tenantInvites.find((i) => i.tenantId == tenantId)?.role;

    return GetUserRole(role, t);
  };

  const tokenRenewalChanged = (
    isChecked: boolean,
    tokenAssignment: TokenAssignment,
    user: User
  ) => {
    if (isChecked) {
      setRenewalModalState({
        show: true,
        user: user,
        onComplete: () => {
          handleUpdateToken(isChecked, tokenAssignment, user);
          setRenewalModalState({ show: false });
        },
      });
    } else {
      handleUpdateToken(isChecked, tokenAssignment, user);
    }
  };

  const handleUpdateToken = (
    isChecked: boolean,
    tokenAssignment: TokenAssignment,
    user: User
  ) => {
    // Trigger UI state update
    tokenAssignment.autoRenew = isChecked;
    setTokenAssignments(
      tokenAssignments.filter((t) => t.id !== tokenAssignment.id)
    );
    setTokenAssignments((oldArray) => [...oldArray, tokenAssignment]);

    // Do the API update
    updateToken({ id: tokenAssignment.id, autoRenew: isChecked })
      .then(() => {
        if (isChecked) {
          pushAlert({
            message: t(
              "subscriptionsPage.notifications.subscriptionRenewalEnabled",
              { email: user.email }
            ),
            type: AlertType.Success,
          });
        } else {
          pushAlert({
            message: t(
              "subscriptionsPage.notifications.subscriptionRenewalDisabled",
              { email: user.email }
            ),
            type: AlertType.Success,
          });
        }
      })
      .catch(() => {
        pushAlert({
          message: t(
            "subscriptionsPage.notifications.subscriptionRenewalError"
          ),
          type: AlertType.Error,
        });
      });
  };

  const getRows = (): ReactElement[][] => {
    if (!getSelectedTenant) {
      return [];
    }

    return users.map((user, index) => {
      const tokenAssignment = getTokenAssignment(user);
      if (!tokenAssignment) return [];

      return [
        userEmailCell(user.email),
        userRoleInTenant(user, getSelectedTenant.id),
        tokenAssignment.courseId,
        <div key={user.id} className={"d-flex justify-content-center"}>
          <VTPCheckbox
            checked={tokenAssignment.autoRenew}
            onChange={(checked) =>
              tokenRenewalChanged(
                !tokenAssignment?.autoRenew,
                tokenAssignment,
                user
              )
            }
          />
        </div>,
      ] as ReactElement[];
    });
  };

  const batchInfoModal = () => {
    return (
      <VTPSimpleModal>
        <div className={styles.container}>
          <h2
            className={`${VTPStyles.Typography.Headers.H2SubheaderLarge} ${VTPStyles.Color.Text.PrimaryColor}`}
          >
            {props.license.name}
          </h2>
          <h2
            className={`${VTPStyles.Typography.Headers.H2SubheaderSmall} ${VTPStyles.Color.Text.SecondaryColor}`}
            style={{ marginTop: "16px" }}
          >
            Users added on {CommonUtilities.FormatDate(props.addedOnDate)}
          </h2>
          <VTPTable
            columns={[
              t("subscriptionsPage.tableColumns.user"),
              t("subscriptionsPage.tableColumns.role"),
              t("subscriptionsPage.tableColumns.course"),
              t("subscriptionsPage.tableColumns.autoRenewal"),
            ]}
            rows={getRows()}
          />
          <VTPPagination
            onPreviousPageSelect={userPaging.previousPage}
            onNextPageSelect={userPaging.nextPage}
            nextPageEnabled={userPaging.hasNextPage}
            previousPageEnabled={userPaging.hasPreviousPage}
          />
          <div className={`${styles.buttonSection}`}>
            <VTPButton
              size={ButtonSize.Medium}
              type={ButtonType.Primary}
              onClick={props.buttonClicked}
            >
              {t("common.inputs.close")}
            </VTPButton>
          </div>
        </div>
      </VTPSimpleModal>
    );
  };

  return (
    <>
      {renewalModalState?.show ? (
        <VTPConfirmationModal
          confirmationButtonText={t("common.inputs.confirm")}
          onCompletionFunction={() => renewalModalState.onComplete?.()}
          onCancel={() => setRenewalModalState({ show: false })}
          icon={ModalIcon.AssignToken}
          header={t("subscriptionsPage.renewalModal.header", { email: renewalModalState.user?.email })}
          bodyText={t("subscriptionsPage.renewalModal.text1", { email: renewalModalState.user?.email })}
        />
      ) : (
        batchInfoModal()
      )}
    </>
  );
};

export default BatchInfoModal;
