import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { Config } from "./config";

// Function to extract the third part or fallback to full version tag
function extractVersion(versionTag: string | null): string {
    if (versionTag === null) {
        return '1'; // Fallback value if versionTag is null
    }

    // Split versionTag by '/' and get the third part (index 2)
    const parts = versionTag.split('/');
    return parts.length >= 3 ? parts[2] : versionTag; // Return third part or full versionTag as fallback
}

// Initialize i18next
i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
        lng: "en",
        // Other configurations as needed
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json?v=${encodeURIComponent(extractVersion(Config.versionTag))}`,
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
