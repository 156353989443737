import React, { useEffect, useState } from "react";
import styles from "./guestCourseList.module.css";
import { Course, TokenAssignment } from "../../lib/apiModels";
import { useVTPCloud } from "../../context/vtpCloud-context";
import VTPCard from "../base/vtpCard";
import CommonUtilities from "../../lib/common";
import VTPStyles from "../../styles/vtpStyles";
import { ReactComponent as KandaLogo } from "../../assets/logo/light_kanda_stacked.svg";
import {useTranslation} from "react-i18next";

const GuestCourseList = () => {
  const { t } = useTranslation();
  const { getCourses, getTokens } = useVTPCloud();
  const [courses, setCourses] = useState<Course[]>([]);
  const [tokens, setTokens] = useState<TokenAssignment[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Promise.all([getCourses(), getTokens({ limit: -1, includeInactive: true })])
      .then(([coursesResponse, tokensResponse]) => {
        setCourses(coursesResponse.items);
        setTokens(tokensResponse.items);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className={styles.container}>
      {tokens.length === 0 && !isLoading ? (
        <div className={styles.noCourses}>
          <KandaLogo className={styles.kandaLogo} />
          <div
            className={`${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Text.SecondaryColor}`}
          >
            {t("coursesPage.traineeView.noCourses")}
          </div>
        </div>
      ) : null}
      {courses
        .filter((course) =>
          tokens.some((token) => token.courseId === course.courseId)
        ) // Filter only courses with matching tokens
        .map((course) => {
          // Find tokens matching the current course id
          const matchedTokens = tokens
            .filter((token) => token.courseId === course.courseId)
            .sort((a, b) => {
              // Sort by isActive first (active tokens before inactive ones)
              if (a.isActive && !b.isActive) return -1;
              if (!a.isActive && b.isActive) return 1;
              // If both tokens have the same isActive status, sort by createdAt
              return (
                new Date(b.expiryDate).getTime() -
                new Date(a.expiryDate).getTime()
              );
            });

          // Get the newest token (if any)
          const token = matchedTokens.length > 0 ? matchedTokens[0] : null;

          return (
            <VTPCard key={course.id} className={styles.courseCard}>
              <div className={styles.courseName}>
                {course.name}
                <div
                  className={`${styles.underLine} ${
                    token?.isActive ? styles.activeUnderline : ""
                  }`}
                />
              </div>
              {token?.isActive ? (
                <div
                  className={`${styles.statusLabel} ${styles.activeLabel} ${VTPStyles.Typography.Body.Small}`}
                >
                  {t("coursesPage.traineeView.active")}
                </div>
              ) : (
                <div
                  className={`${styles.statusLabel} ${styles.expiredLabel} ${VTPStyles.Typography.Body.Small}`}
                >
                  {t("coursesPage.traineeView.expired")}
                </div>
              )}
              <div>
                <div
                  className={`${VTPStyles.Typography.Headers.H4Caption} ${VTPStyles.Color.Text.SecondaryColor}`}
                >
                  {t("coursesPage.traineeView.expiryDate")}
                </div>
                <div className={`${VTPStyles.Typography.Body.Medium}`}>
                  {CommonUtilities.FormatDate(token?.expiryDate)}
                </div>
              </div>
            </VTPCard>
          );
        })}
    </div>
  );
};

export default GuestCourseList;
