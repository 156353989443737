import React, { useEffect } from "react";

function useClickOutside(
    elementsToTrack: React.RefObject<HTMLElement>[],
    onClickedOutside: () => void
) {
  const handleClick = (event: Event) => {
    const clickedOutside = elementsToTrack.every((ref) => {
      return ref.current && !ref.current.contains(event.target as Node);
    });

    if (clickedOutside) {
      onClickedOutside();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [elementsToTrack, onClickedOutside]);
}

export default useClickOutside;