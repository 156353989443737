import React from "react";
import styles from "./tablePagination.module.css";
import { ReactComponent as PagingIcon } from "../../../assets/icons/paging_icon.svg";
import VTPStyles from "../../../styles/vtpStyles";

const PagingButton = (props: { disabled?: boolean; reverse?: boolean, onClick?: () => any }) => {
  return (
    <div
      className={`
             ${styles.pageButton}
             ${props.disabled ? styles.disabled : ""} 
             no-text-select
         `}
      onClick={props.onClick}
    >
      <PagingIcon className={props.reverse ? styles.previousPageIcon : ""} />
    </div>
  );
};

interface TablePaginationProps {
  currentPage: number;
  totalPages: number;

  // Callbacks
  nextPage?: () => any;
  previousPage?: () => any;
}

const TablePagination = (props: TablePaginationProps) => {
  const previousPageDisabled = props.currentPage <= 1;
  const nextPageDisabled = props.totalPages <= props.currentPage;

  return (
    <div className={styles.container}>
      <PagingButton reverse={true} disabled={previousPageDisabled} onClick={props.previousPage}/>
      <div
        className={`${styles.pagingInfo} ${VTPStyles.Typography.Buttons.SizeSmall} no-text-select`}
      >
        {props.currentPage} of {props.totalPages}
      </div>
      <PagingButton disabled={nextPageDisabled} onClick={props.nextPage}/>
    </div>
  );
};

export default TablePagination;
