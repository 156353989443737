import React, { ReactElement } from "react";
import { StylingProps } from "../../lib/common";
import styles from "./pageSection.module.css";
import vtpStyles from "../../styles/vtpStyles";

interface Header {
  icon: ReactElement;
  text: string;
  appendHeader?: React.ReactNode;
}

interface Props extends StylingProps {
  header?: Header;
  subheader?: string;
}

const PageSection = (props: Props) => {
  return (
    <div
      className={`${props.className ?? ""} ${styles.container} ${
        vtpStyles.Color.UI.PrimaryPanelContrastBackgroundColor
      }`}
      style={props.style}
    >
      {/*==== Section header ====*/}
      {!props.header ? null : (
        <div className={styles.header}>
          <div className={styles.icon}>{props.header.icon}</div>
          <h1
            className={`${vtpStyles.Typography.Headers.H1TitleSmall} ${vtpStyles.Color.Text.PrimaryColor}`}
          >
            {props.header.text}
          </h1>
          {props.header.appendHeader}
        </div>
      )}

      {/*==== Section subheader ====*/}
      {!props.subheader ? null : (
        <h2
          className={`${styles.subheader} ${vtpStyles.Typography.Headers.H2SubheaderSmall} ${vtpStyles.Color.Text.PrimaryColor}`}
        >
          {props.subheader}
        </h2>
      )}

      {/*==== Section content ====*/}
      <div className={`${styles.content}`}>{props.children}</div>
    </div>
  );
};

export default PageSection;
