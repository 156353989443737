import React, { ReactNode } from "react";
import styles from "./vtpSimpleConfirmationModal.module.css";
import VTPSimpleModal from "./vtpSimpleModal";
import { StylingProps } from "../../lib/common";
import VTPStyles from "../../styles/vtpStyles";

interface Props extends StylingProps {
  header?: ReactNode;
  body?: ReactNode;
  buttons?: ReactNode[];
}

const ConfirmationHeader = (props: { icon: ReactNode; title: string }) => {
  return (
    <div className={`${styles.confirmationModalHeader}`}>
      {props.icon}
      <div>{props.title}</div>
    </div>
  );
};

const VTPSimpleConfirmationModal = (props: Props) => {
  return (
    <VTPSimpleModal>
      <div className={`${styles.container} ${props.className}`}>
        {/* Render header */}
        {props.header ? (
          <div
            className={`${styles.modalSection} ${styles.header} ${VTPStyles.Typography.Headers.H2SubheaderSmall} ${VTPStyles.Color.Text.PrimaryColor}`}
          >
            {props.header}
          </div>
        ) : null}

        {/* Render body */}
        {props.body ? (
          <div
            className={`${styles.modalSection} ${styles.body} ${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Text.PrimaryColor}`}
          >
            {props.body}
          </div>
        ) : null}

        {/* Render button section */}
        {props.buttons ? (
          <div className={`${styles.modalSection} ${styles.buttonSection}`}>
            {props.buttons}
          </div>
        ) : null}
      </div>
    </VTPSimpleModal>
  );
};

export default VTPSimpleConfirmationModal;
export { ConfirmationHeader };
