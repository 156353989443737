import React from "react";
import ui from "./ui.module.css";
import interactables from "./interactables.module.css";
import body from "./body.module.css";
import text from "./text.module.css";
import panels from "./panels.module.css";
import headers from "./headers.module.css";

// ==== Typography ====
const Links = {
  Link: interactables.link,
};

const Buttons = {
  SizeMedium: interactables.buttonsMedium,
  SizeLarge: interactables.buttonsLarge,
  SizeSmall: interactables.buttonsSmall,
};

const Body = {
  Small: body.smallBody,
  Large: body.largeBody,
  Medium: body.mediumBody,
};

const Headers = {
  Mega: headers.mega,
  H1TitleLarge: headers.h1TitleLarge,
  H1TitleMedium: headers.h1TitleMedium,
  H1TitleSmall: headers.h1TitleSmall,
  H2SubheaderLarge: headers.h2SubheaderLarge,
  H2SubheaderMedium: headers.h2SubheaderMedium,
  H2SubheaderSmall: headers.h2SubheaderSmall,
  H3EyebrowLarge: headers.h3EyebrowLarge,
  H3EyebrowMedium: headers.h3EyebrowMedium,
  H3EyebrowSmall: headers.h3EyebrowSmall,
  H4Caption: headers.h4Caption,
};

// ==== Color ====
const PanelsColors = {
  WhiteColor: panels.whiteColor,
  InactivePanelColor: panels.inactiveColor,
  VirtualTagColor: panels.virtualTagColor,
};

const TextColors = {
  PrimaryColor: text.primaryColor,
  SecondaryColor: text.secondaryColor,
  BodyStyleText: text.bodyStyleText,
  ContrastPrimary: text.contrastPrimaryColor,
  ContrastSecondary: text.contrastSecondaryColor,
  DarkModePrimary: text.darkModePrimary,
  DarkModePrimaryPanel: text.darkModePrimaryPanel,
  KandaBrandingPrimary: text.kandaBrandingPrimaryColor,
  KandaBrandingPrimaryGradient: text.kandaBrandingPrimaryGradient,
  KandaBrandingAccent: text.kandaBrandingAccentColor,
  KandaBrandingSecondary: text.kandaBrandingSecondaryColor,
  KandaBrandingPanelContrast: text.kandaBrandingPanelContrastColor,
  KandaBrandingNeutralsOnLightDeepGrey:
    text.kandaBrandingNeutralsOnLightDeepGrey,
  KandaBrandingNeutralsOnLightNondescriptGrey:
    text.kandaBrandingNeutralsOnLightNondescriptGrey,
  KandaBrandingschemeNeutralsOnPrimaryDarkNaturalWhite:
    text.kandaBrandingschemeNeutralsOnPrimaryDarkNaturalWhite,
  KandaBrandingSchemeNeutralsOnPrimaryDarkBrightGrey:
    text.kandaBrandingSchemeNeutralsOnPrimaryDarkBrightGrey,
  SemanticLightSuccess: text.semanticLightSuccessColor,
  SemanticLightWarning: text.semanticLightWarningColor,
  SemanticLightError: text.semanticLightErrorColor,
  SemanticLightInformation: text.semanticLightInformationColor,
  SemanticDarkSuccess: text.semanticDarkSuccessColor,
  SemanticDarkWarning: text.semanticDarkWarningColor,
  SemanticDarkError: text.semanticDarkErrorColor,
  SemanticDarkInformation: text.semanticDarkInformationColor,
};

const UIColors = {
  PrimaryColor: ui.primaryColor,
  SecondaryColor: ui.secondaryColor,
  PrimaryPanelContrast: ui.primaryPanelContrastColor,
  PrimaryPanelContrastBackgroundColor: ui.primaryPanelContrastBackgroundColor,
  SecondPanelContrastColor: ui.secondPanelContrastColor,
  SecondPanelContrastBackgroundColor: ui.secondPanelContrastBackgroundColor,
  AccentColor: ui.accentColor,
  StatesInactive: ui.statesInactiveColor,
  StatesHoverShade: ui.statesHoverShadeColor,
  StatesSelectedShade: ui.statesSelectedShadeColor,
  StatesHover: ui.statesHoverColor,
};

const VTPStyles = {
  Typography: {
    Links: Links,
    Buttons: Buttons,
    Body: Body,
    Headers: Headers
  },
  Color: {
    Panel: PanelsColors,
    Text: TextColors,
    UI: UIColors
  }
};

export default VTPStyles;
