import React, { useState } from "react";
import styles from "./vtpRadioInput.module.css";
import VTPStyles from "../../../styles/vtpStyles";
import { StylingProps } from "../../../lib/common";

interface RadioInputProps extends StylingProps {
  name: string;
  options: RadioInputOption[];
  onChanged?: (option: RadioInputOption) => void;
  selectedValue?: any;
  disabled?: boolean;
}

interface RadioInputOption {
  name: string;
  value?: any;
}

const VTPRadioInput = (props: RadioInputProps) => {
  const [selectedOption, setSelectedOption] = useState(props.selectedValue);
  const handleChange = (selected: RadioInputOption) => {
    setSelectedOption(selected.value);
    props.onChanged?.(selected);
  };

  return (
    <div className={`${styles.container} ${props.className} ${props.disabled ? styles.disabled : ""}`}>
      {props.options.map((o, pos) => {
        const isChecked = o.value === selectedOption;
        return (
          <label key={pos} className={`${styles.inputContainer} `}>
            <span
              className={`
                  ${VTPStyles.Typography.Body.Small} 
                  ${VTPStyles.Color.Text.SecondaryColor}
                  ${isChecked ? styles.checkedLabel : ''}
              `}
            >
              {o.name}
            </span>
            <input
              type="radio"
              name={props.name}
              value={o.value}
              checked={isChecked}
              onChange={() => handleChange(o)}
            />
            <span className={`${styles.checkmark}`}></span>
          </label>
        );
      })}
    </div>
  );
};

export default VTPRadioInput;
export type { RadioInputOption };
