import { TokenPoolState, useAppContext } from "../../context/app-context";
import styles from "./tokenState.module.css";
import React, { useEffect } from "react";
import { useVTPCloud } from "../../context/vtpCloud-context";
import VTPStyles from "../../styles/vtpStyles";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-circle.svg";
import { useTranslation } from "react-i18next";

const TokenState = (props: {
  tokenState?: TokenPoolState;
  remainingView?: boolean;
}) => {
  const { t } = useTranslation();
  const { getTokenPoolState } = useAppContext();
  const { refreshSelectedTenant } = useVTPCloud();

  useEffect(() => {
    if (!props.tokenState) {
      refreshSelectedTenant().catch(() =>
        console.log("Failed to refresh token state")
      );
    }
  }, []);

  const tokenPoolState = () => props.tokenState ?? getTokenPoolState;

  const getTokenPillColor = () => {
    // return styles.warning;
    const prcnt =
      1 - tokenPoolState().usedTokens / tokenPoolState().totalTokens;

    // No tokens left
    if (prcnt <= 0 || tokenPoolState().totalTokens == 0) {
      return styles.danger;
    }

    if (prcnt >= 0.25) {
      // Up to 75% of used tokens
      return "";
    } else {
      // Less than 25% left show warning
      return styles.warning;
    }
  };

  return (
    <div
      className={`${styles.container} ${getTokenPillColor()} ${
        VTPStyles.Typography.Buttons.SizeSmall
      }`}
    >
      {!props.remainingView ? (
        <>
          {`${t("tokenState.usedLicenses")} `}{tokenPoolState().usedTokens}/{tokenPoolState().totalTokens}
        </>
      ) : (
        <>{`${t("tokenState.remainingLicenses")} `}{tokenPoolState().unusedTokens}</>
      )}
      <PlusIcon
        className={styles.addLicenseButton}
        onClick={() =>
          window.open("https://www.kanda.dk/get-licenses", "_blank")
        }
      />
    </div>
  );
};

export default TokenState;
