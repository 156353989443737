import React, { useEffect, useState } from "react";
import styles from "./courseCard.module.css";
import VTPCard from "../base/vtpCard";
import VTPStyles from "../../styles/vtpStyles";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import {
  Course,
  GetUserRole,
  TokenAssignment,
  TokenSortingKey,
} from "../../lib/apiModels";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { useEndpointPagination } from "../../hooks/usePagination";
import { useVTPCloud } from "../../context/vtpCloud-context";
import VTPTable from "../base/vtpTable";
import VTPSortableTableHeader, {
  useSortingStateMachine,
} from "../base/vtpSortableTableHeader";
import { useAppContext } from "../../context/app-context";
import CommonUtilities from "../../lib/common";
import VTPCheckbox from "../base/form/vtpCheckbox";
import { ReactComponent as WarningCheckboxIcon } from "../../assets/icons/warning-checkbox.svg";
import { AlertType, useAlert } from "../../hooks/useAlert";
import TablePagination from "../base/table/tablePagination";
import VTPSimpleConfirmationModal from "../modal/vtpSimpleConfirmationModal";
import { Collapse } from "react-bootstrap";
import AssignTokensModal from "./modal/assignTokensModal";
import UserStatusLabel from "../common/userStatusLabel";
import VTPTooltip, { TooltipPosition } from "../base/vtpTooltip";
import UserAnalyticsButton from "../analytics/userAnalyticsButton";
import { ReactComponent as AnalyticsIcon } from "../../assets/icons/analytics.svg";
import { Config } from "../../config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface CourseCardProps {
  course: Course;
  hasTokens?: (hasTokens: boolean) => any;
  searchQuery?: string;
}

const CourseCard = (props: CourseCardProps) => {
  const translation = useTranslation();
  const { getTokens, updateToken, refreshSelectedTenant } = useVTPCloud();
  const { getSelectedTenant } = useAppContext();
  const navigate = useNavigate();
  const { pushAlert } = useAlert();
  const sortingStateMachine = useSortingStateMachine(TokenSortingKey.Email);
  const [renewalModalState, setRenewalModalState] = useState<TokenAssignment>();
  const [showAssignTokenModal, setShowAssignTokenModal] = useState(false);
  const [totalMembers, setTotalMembers] = useState(0);
  const [listExpanded, setListExpanded] = useState(false);
  const paging = useEndpointPagination(getTokens, 20, {
    all: true,
    courseId: props.course.courseId,
    loadUsers: true,
    search: "",
    sort: `${sortingStateMachine.state.value}:${sortingStateMachine.state.sortingState}`,
  });

  useEffect(() => {
    if (!props.searchQuery) {
      setTotalMembers(paging.pagingInfo.TotalItems);
    }
    props.hasTokens?.(paging.pagingInfo.TotalItems > 0);
  }, [paging.pageData]);

  // Sorting
  useEffect(() => {
    const sortingParam = `${sortingStateMachine.state.value}:${sortingStateMachine.state.sortingState}`;
    if (paging.options.sort !== sortingParam) {
      paging.setOptions({
        ...paging.options,
        sort: sortingParam,
      });
    }
  }, [sortingStateMachine.state]);

  // Search query with delay
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (paging.options.search !== props.searchQuery) {
        paging.setOptions({
          ...paging.options,
          search: props.searchQuery,
        });
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [props.searchQuery]);

  const isExpanded =
    listExpanded || ((paging.pageData?.length ?? 0) > 0 && !!props.searchQuery);

  const getTenantCourseRenewingMemberCount = (courseId: string) => {
    return (
      getSelectedTenant?.licenses
        .flatMap((l) => l.tokenAssignments)
        .filter((t) => t.isActive)
        .filter((t) => t.courseId === courseId)
        .filter((t) => t.autoRenew).length ?? 0
    );
  };
  
  const isExternalToken = (tokenAssignment: TokenAssignment) => {
    if (getSelectedTenant?.licenses) {
      const allTokens = getSelectedTenant.licenses.flatMap(
        (license) => license.tokenAssignments
      );
      return !allTokens.some(
        (assignment) => assignment.id === tokenAssignment.id
      );
    }

    return true;
  };

  const handleAutoRenewalToggle = (tokenAssignment: TokenAssignment) => {
    const newToggleState = !tokenAssignment.autoRenew;
    if (newToggleState) {
      // If set to true, prompt confirmation window before updating token.
      setRenewalModalState(tokenAssignment);
    } else {
      // If toggled off, update token right away.
      toggleAutoRenewal(tokenAssignment);
    }
  };

  const toggleAutoRenewal = (tokenAssignment: TokenAssignment) => {
    // Do the API update
    updateToken({
      id: tokenAssignment.id,
      autoRenew: !tokenAssignment.autoRenew,
    })
      .then(() => {
        if (!tokenAssignment.autoRenew) {
          pushAlert({
            message: translation.t(
              "coursesPage.notifications.autoRenewEnabled",
              { email: tokenAssignment.assignedToUser?.email }
            ),
            type: AlertType.Success,
          });
        } else {
          pushAlert({
            message: translation.t(
              "coursesPage.notifications.autoRenewDisabled",
              { email: tokenAssignment.assignedToUser?.email }
            ),
            type: AlertType.Success,
          });
        }
      })
      .catch(() => {
        pushAlert({
          message: translation.t("coursesPage.notifications.autoRenewError"),
          type: AlertType.Error,
        });
      })
      .finally(() => {
        refreshSelectedTenant();
        paging.reloadPage();
        setRenewalModalState(undefined);
      });
  };

  const columnHeaders = [
    <VTPSortableTableHeader
      key={TokenSortingKey.Email}
      text={translation.t("coursesPage.tableColumns.enrolledUsers")}
      value={TokenSortingKey.Email}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={TokenSortingKey.UserName}
      text={translation.t("coursesPage.tableColumns.userName")}
      value={TokenSortingKey.UserName}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={TokenSortingKey.Role}
      text={translation.t("coursesPage.tableColumns.role")}
      value={TokenSortingKey.Role}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={TokenSortingKey.Status}
      text={translation.t("coursesPage.tableColumns.status")}
      value={TokenSortingKey.Status}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={TokenSortingKey.Expiration}
      text={translation.t("coursesPage.tableColumns.expiration")}
      value={TokenSortingKey.Expiration}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={TokenSortingKey.AutoRenewal}
      text={translation.t("coursesPage.tableColumns.autoRenewal")}
      value={TokenSortingKey.AutoRenewal}
      stateMachine={sortingStateMachine}
    />,
  ];

  const rows = (): any[][] => {
    if (!paging.pageData || !getSelectedTenant) return [];

    return paging.pageData.map((t) => {
      const user = t.assignedToUser;
      const isActiveInTenant =
        user?.userTenantRoles.find(
          (ut) => ut.tenantId === getSelectedTenant.id
        ) !== undefined;
      const role = isActiveInTenant
        ? user?.userTenantRoles.find(
            (ut) => ut.tenantId === getSelectedTenant.id
          )?.role
        : user?.tenantInvites.find((i) => i.tenantId == getSelectedTenant.id)
            ?.role;

      return [
        <UserAnalyticsButton
          key={user?.id}
          userId={user?.id ?? ""}
          email={user?.email}
        />,
        user?.fullName ?? "-",
        <div
          key={role}
          className={`${styles.userRoleLabel} ${VTPStyles.Typography.Headers.H3EyebrowSmall}`}
        >
          {GetUserRole(role, translation.t)}
        </div>,
        <UserStatusLabel key={t.assignedToUserId} user={t.assignedToUser} />,
        CommonUtilities.FormatDate(t.expiryDate),
        <div key={t.id + t.autoRenew} className={styles.autoRenewal}>
          {isExternalToken(t) ? (
            <VTPTooltip
              position={TooltipPosition.Top}
              tooltip={translation.t("coursesPage.tooltips.externalLicense")}
              tooltipWidth={159}
            >
              <WarningCheckboxIcon />
            </VTPTooltip>
          ) : (
            <VTPCheckbox
              checked={t.autoRenew}
              onChange={(_) => handleAutoRenewalToggle(t)}
            />
          )}
        </div>,
      ];
    });
  };

  return (
    <>
      <VTPCard key={props.course.id} className={styles.courseCard}>
        <div
          className={`${styles.courseCardHeader} ${
            totalMembers > 0 ? styles.active : ""
          } no-text-select`}
          onClick={() => setListExpanded((prevState) => !prevState)}
        >
          <div
            className={`${styles.courseName} ${VTPStyles.Typography.Body.Medium}`}
          >
            {props.course.name}
            {props.course.bundledCourses.length > 0 ? (
                <div
                    className={`${styles.courseDescription} ${VTPStyles.Typography.Headers.H4Caption} ${VTPStyles.Color.Text.PrimaryColor}`}
                >
                  Gives access to all courses for 10 licenses per user, per month
                </div>
            ) : null}
            <div className={styles.underline} />
          </div>
          {getTenantCourseRenewingMemberCount(props.course.courseId) > 0 ? (
            <span
              className={`${styles.courseMembersLabel} ${VTPStyles.Typography.Body.Medium}`}
            >
              {translation.t("coursesPage.courseList.autoRenewCount", {
                autoRenewCount: getTenantCourseRenewingMemberCount(
                  props.course.courseId
                ),
              })}
            </span>
          ) : null}
          <span
            className={`${styles.courseMembersLabel} ${VTPStyles.Typography.Body.Medium}`}
          >
            {translation.t("coursesPage.courseList.userCount", {
              totalUsers: totalMembers,
            })}
          </span>
          <VTPButton
            size={ButtonSize.Small}
            type={ButtonType.Tertiary}
            onClick={(e) => {
              e.stopPropagation();
              setShowAssignTokenModal(true);
            }}
          >
            <PlusIcon className={styles.plusIcon} />
            {translation.t("coursesPage.inputs.addUsers")}
          </VTPButton>
          {Config.enableAnalytics ? (
            <VTPButton
              key={props.course.courseId}
              size={ButtonSize.Small}
              type={ButtonType.Tertiary}
              className={styles.analyticsButton}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${Config.analytics}?course=${props.course.courseId}`);
              }}
            >
              <AnalyticsIcon />
            </VTPButton>
          ) : null}
        </div>
        <Collapse in={isExpanded}>
          {/*Extra div is needed here so collapse animation is not broken*/}
          <div>
            <div className={styles.tokenTableContainer}>
              <VTPTable
                columns={columnHeaders}
                rows={rows()}
                isLoading={paging.isLoading}
              />
              <div className={styles.tokenPaging}>
                <TablePagination
                  currentPage={paging.pagingInfo.CurrentPage}
                  totalPages={paging.pagingInfo.TotalPages}
                  previousPage={paging.previousPage}
                  nextPage={paging.nextPage}
                />
              </div>
            </div>
          </div>
        </Collapse>
      </VTPCard>

      {/*====== Modals ======*/}
      {renewalModalState ? (
        <VTPSimpleConfirmationModal
          header={translation.t("coursesPage.autoRenewalModal.header", {email: renewalModalState.assignedToUser?.email})}
          body={translation.t("coursesPage.autoRenewalModal.text1", {email: renewalModalState.assignedToUser?.email})}
          buttons={[
            <VTPButton
              key={"cancel"}
              size={ButtonSize.Medium}
              type={ButtonType.Tertiary}
              onClick={() => setRenewalModalState(undefined)}
            >
              {translation.t("common.inputs.cancel")}
            </VTPButton>,
            <VTPButton
              key={"remove"}
              size={ButtonSize.Medium}
              type={ButtonType.Primary}
              onClick={() => toggleAutoRenewal(renewalModalState)}
            >
              {translation.t("common.inputs.confirm")}
            </VTPButton>,
          ]}
        />
      ) : null}

      {showAssignTokenModal ? (
        <AssignTokensModal
          onCancel={() => {
            setShowAssignTokenModal(false);
          }}
          onSubmit={() => {
            setShowAssignTokenModal(false);
            refreshSelectedTenant().then(() => {
              paging.reset();
            });
          }}
          course={props.course}
        />
      ) : null}
    </>
  );
};

export default CourseCard;
