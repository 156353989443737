import React from "react";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import {Course} from "../../lib/apiModels";
import {Config} from "../../config";
import {useNavigate} from "react-router-dom";

const CourseAnalyticsButton = (props: { course: Course }) => {
  const navigate = useNavigate();
  
  return (
    <VTPButton
      size={ButtonSize.Small}
      type={ButtonType.Tertiary}
      style={{ userSelect: "text" }}
      onClick={() => Config.enableAnalytics ? navigate(`${Config.analytics}?course=${props.course.courseId}`) : "#"}
    >
      {props.course.name}
    </VTPButton>
  );
};

export default CourseAnalyticsButton;
