import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { AlertType, useAlert } from "../../hooks/useAlert";

// General-purpose callback handler
const CallbackHandler = () => {
  const { pushAlert } = useAlert();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const path = location.pathname;

    if (path == "/email/verified") {
      pushAlert({
        message: "Your email was verified.",
        type: AlertType.Success,
      });
    }
  }, []);

  useEffect(() => {
    const error_type_name = "error";
    const errorType = searchParams.get(error_type_name);
    const error_description_name = "error_description";
    const errorMessage = searchParams.get(error_description_name);
    const errorOccured = errorType != null && errorMessage != null;

    if (errorOccured) {
      pushAlert({
        message: errorMessage,
        type: AlertType.Error,
      });

      searchParams.delete(error_type_name);
      searchParams.delete(error_description_name);
      setSearchParams(searchParams);
    }
  }, []);

  return null;
};

export default CallbackHandler;
