import React, { ReactNode, useEffect, useState } from "react";
import TableSearchInput from "../base/table/tableSearchInput";
import styles from "./userUploads.module.css";
import VTPTable from "../base/vtpTable";
import useStateMachine from "../../hooks/useStateMachine";
import { useEndpointPagination } from "../../hooks/usePagination";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as TrashbinIcon } from "../../assets/icons/trashbin.svg";
import { useAppContext } from "../../context/app-context";
import VTPContextMenu from "../base/form/vtpContextMenu";
import { UserFile, UserFileSortingKey } from "../../lib/apiModels";
import CommonUtilities from "../../lib/common";
import { GetFileCategoryIcon } from "../../pages/filesPage";
import { AlertType, useAlert } from "../../hooks/useAlert";
import { useUploadManagerContext } from "../../context/useUploadManager-context";
import VTPConfirmationModal from "../modal/vtpConfirmationModal";
import { getSortingHeader, SortingState } from "./fileSorting";
import tableStyles from "../common/table.module.css";
import TablePagination from "../base/table/tablePagination";
import { formatContentType, getIcon, getSortKey } from "./userUploads";
import { useTranslation } from "react-i18next";

const SessionFiles = () => {
  const { t } = useTranslation();
  const { getUserFiles, deleteUserFile, getUserFile } = useVTPCloud();
  const { getUserProfile } = useAppContext();
  const {
    subscribeOnFileListChanged,
    unsubscribeOnFileListChanged,
    fileListChanged,
  } = useUploadManagerContext();
  const { pushAlert } = useAlert();
  const [searchQuery, setSearchQuery] = useState<string>();
  const [deleteUserFileModal, setDeleteUserFileModal] = useState<UserFile>();

  const initialSortState = {
    value: UserFileSortingKey.DisplayName,
    state: SortingState.Asc,
  };

  const sortingState = useStateMachine(initialSortState);
  const userFilesPaging = useEndpointPagination(getUserFiles, 10, {
    search: searchQuery,
    sort: getSortKey(initialSortState),
    excludeScenarioFiles: true,
    roomContainerOnly: true,
    loadRoomContainers: true,
  });

  useEffect(() => {
    const eventId = window.crypto.randomUUID();
    subscribeOnFileListChanged(eventId, () => {
      userFilesPaging.reset();
    });

    return () => {
      unsubscribeOnFileListChanged(eventId);
    };
  }, []);

  // Sorting
  useEffect(() => {
    const sortKey = getSortKey(sortingState.state);
    if (userFilesPaging.options.sort !== sortKey) {
      userFilesPaging.setOptions({
        ...userFilesPaging.options,
        sort: sortKey,
      });
    }
  }, [sortingState.state]);

  // Search query with delay
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (userFilesPaging.options.search !== searchQuery) {
        userFilesPaging.setOptions({
          ...userFilesPaging.options,
          search: searchQuery,
        });
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const initiateFileDownload = (
    userFileId: string,
    previewInBrowser: boolean
  ) => {
    getUserFile(userFileId, { preview: previewInBrowser }).then((res) => {
      window.open(res.transferUrl, "_blank");
    });
  };

  const handleUserFileDelete = (userFile: UserFile) => {
    deleteUserFile(userFile.id)
      .then(() => {
        pushAlert({
          message: t("filesPage.notifications.fileDeletedSuccess", {
            fileName: userFile.displayName,
          }),
          type: AlertType.Success,
        });
      })
      .catch(() =>
        pushAlert({
          message: t("filesPage.notifications.fileDeletedError", {
            fileName: userFile.displayName,
          }),
          type: AlertType.Error,
        })
      )
      .finally(() => {
        setDeleteUserFileModal(undefined);
        fileListChanged();
      });
  };

  const columns = () => {
    return [
      getSortingHeader(
        t("filesPage.tableColumns.fileName"),
        UserFileSortingKey.DisplayName,
        sortingState
      ),
      getSortingHeader(
        t("filesPage.tableColumns.type"),
        UserFileSortingKey.ContentType,
        sortingState
      ),
      getSortingHeader(
        t("filesPage.tableColumns.shared"),
        UserFileSortingKey.SharingOption,
        sortingState
      ),
      getSortingHeader(
        t("filesPage.tableColumns.size"),
        UserFileSortingKey.Size,
        sortingState
      ),
      getSortingHeader(
        t("filesPage.tableColumns.sessionName"),
        UserFileSortingKey.RoomContainer,
        sortingState
      ),
      null,
    ];
  };

  const contextMenuOptions = [
    {
      icon: <TrashbinIcon style={{ width: "14px", height: "18px" }} />,
      name: t("filesPage.inputs.delete"),
      value: "delete",
    },
    {
      icon: <DownloadIcon className={`${styles.downloadIcon}`} />,
      name: t("filesPage.inputs.download"),
      value: "download",
    },
  ];

  const rows = (): ReactNode[][] => {
    const userFiles = userFilesPaging.pageData ?? [];

    return userFiles.map((u, pos) => {
      return [
        <div
          key={pos}
          className={styles.fileInfoColumn}
          onClick={() => initiateFileDownload(u.id, true)}
        >
          <span className={styles.fileIcon}>
            {GetFileCategoryIcon(u.contentType)}
          </span>
          <div className={styles.fileName} title={u.displayName}>
            {u.displayName.split("/").pop()}
          </div>
        </div>,
        formatContentType(u.contentType),
        <div key={u.id} className={styles.sharedIconColumn}>
          {getIcon(u, t, getUserProfile)}
        </div>,
        CommonUtilities.FormatFileSize(u.sizeKilobytes * 1000, true),
        <div key={u.id} className={styles.uploadedByColumn}>
          {u.roomContainer?.roomName}
        </div>,
        <VTPContextMenu
          className={styles.userFileContextMenu}
          key={u.id}
          options={contextMenuOptions}
          onOptionSelected={(selectedOption) => {
            if (selectedOption.value === "delete") {
              setDeleteUserFileModal(u);
            } else if (selectedOption.value === "download") {
              initiateFileDownload(u.id, false);
            }
          }}
          alignRight={true}
        />,
      ];
    });
  };

  return (
    <div>
      <div className={`${styles.tableContainer}`}>
        <div className={`${tableStyles.tableButtons} ${tableStyles.absolute}`}>
          <TableSearchInput
            key={"search"}
            placeHolder={t("filesPage.inputs.searchFiles")}
            onChange={(val) => setSearchQuery(val.target.value)}
          />
        </div>
        <VTPTable
          columns={columns()}
          rows={rows()}
          isLoading={userFilesPaging.isLoading}
        />
        <TablePagination
          currentPage={userFilesPaging.pagingInfo.CurrentPage}
          totalPages={userFilesPaging.pagingInfo.TotalPages}
          nextPage={userFilesPaging.nextPage}
          previousPage={userFilesPaging.previousPage}
        />
      </div>
      {deleteUserFileModal ? (
        <VTPConfirmationModal
          header={t("filesPage.deleteFileModal.header", {
            fileName: deleteUserFileModal.displayName,
          })}
          bodyText={t("filesPage.deleteFileModal.text1", {
            fileName: deleteUserFileModal.displayName,
          })}
          onCancel={() => setDeleteUserFileModal(undefined)}
          onCompletionFunction={() => handleUserFileDelete(deleteUserFileModal)}
          confirmationButtonText={t("common.inputs.confirm")}
        />
      ) : null}
    </div>
  );
};

export default SessionFiles;
