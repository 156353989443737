import './i18n';
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import reportWebVitals from "./reportWebVitals";

import { Auth0Provider } from "@auth0/auth0-react";
import { Config } from "./config";
import { createRoot } from "react-dom/client";
import { VTPCloudProvider } from "./context/vtpCloud-context";
import { AppContextProvider } from "./context/app-context";
import CallbackHandler from "./components/utility/callbackHandler";
import AlertHandler from "./components/utility/alertHandler";
import UploadManager from "./components/utility/uploadManager";
import { UploadManagerProvider } from "./context/useUploadManager-context";
import { AlertManagerProvider } from "./hooks/useAlert";
import EmailHandler from "./components/utility/emailHandler";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Auth0Provider
    domain={Config.oidcAuthDomain}
    clientId={Config.oidcClientId}
    redirectUri={window.location.origin + Config.dashboardRoute}
  >
    <BrowserRouter>
      <AlertManagerProvider>
        <AppContextProvider>
          <VTPCloudProvider>
            <UploadManagerProvider>
              <App />
              <CallbackHandler />
              <AlertHandler />
              <UploadManager />
              <EmailHandler />
            </UploadManagerProvider>
          </VTPCloudProvider>
        </AppContextProvider>
      </AlertManagerProvider>
    </BrowserRouter>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
