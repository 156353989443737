import pageSectionStyles from "../components/base/pageSection.module.css";
import { ReactComponent as CoursesIcon } from "../assets/navigation/dashboard.svg";
import React, { useEffect } from "react";
import { Config } from "../config";
import PageSection from "../components/base/pageSection";
import { useNavigate } from "react-router-dom";
import CourseList from "../components/courses/courseList";
import { useAppContext } from "../context/app-context";
import { useRoleBasedAccess } from "../hooks/useRoleBasedAccess";
import { UserRoles } from "../lib/apiModels";
import GuestCourseList from "../components/courses/guestCourseList";
import { useTranslation } from "react-i18next";

const YourCoursesSection = () => {
  const { t } = useTranslation();
  const { getSelectedTenant } = useAppContext();
  const { userHasRequiredRoleOrHigher } = useRoleBasedAccess();
  const navigate = useNavigate();
  useEffect(() => {
    if (!Config.enableDashboardCoursesPage) {
      navigate(Config.users);
    }
  }, []);

  return (
    <PageSection
      header={{
        icon: <CoursesIcon />,
        text: t("coursesPage.header")
      }}
      subheader={t("coursesPage.subHeader")}
    >
      {getSelectedTenant ? (
        userHasRequiredRoleOrHigher(UserRoles.RegularUser) ? (
          <CourseList />
        ) : (
          <GuestCourseList />
        )
      ) : null}
    </PageSection>
  );
};

const Courses = () => {
  return (
    <div className={pageSectionStyles.singleSectionPage}>
      <YourCoursesSection />
    </div>
  );
};

export default Courses;
