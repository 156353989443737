import React, { ReactNode } from "react";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import VTPModal, { ModalIcon } from "../modal/vtpModal";
import styles from "./vtpConfirmationModal.module.css";
import VTPStyles from "../../styles/vtpStyles";
import { StylingProps } from "../../lib/common";
import {useTranslation} from "react-i18next";

interface Props extends StylingProps {
  header: string;
  bodyText: string;
  confirmationButtonText: string;
  onCancel: () => void;
  onCompletionFunction: () => void;
  icon?: ModalIcon;
  show?: boolean;
}

/**
 * @deprecated Use VTPSimpleConfirmationModal instead.
 */
const VTPConfirmationModal = (props: Props) => {
  const { t } = useTranslation();
  
  const getModalButtons = (): ReactNode[] => {
    return [
      <VTPButton
        key="cancelButton"
        onClick={() => {
          props.onCancel();
        }}
        size={ButtonSize.Medium}
        type={ButtonType.Tertiary}
        style={{ marginRight: "16px" }}
      >
        {t("common.inputs.cancel")}
      </VTPButton>,
      <VTPButton
        key="confirmationButton"
        size={ButtonSize.Medium}
        type={ButtonType.Primary}
        onClick={() => props.onCompletionFunction()}
      >
        {props.confirmationButtonText}
      </VTPButton>,
    ];
  };

  return (
    <VTPModal
      title={props.header}
      className={`
        ${styles.vtpConfirmationModal} 
        ${props.icon != undefined ? styles.vtpConfirmationIcon : ""} 
        ${props.className ?? ""}`}
      style={props.style}
      buttons={getModalButtons()}
      icon={props.icon}
      show={props.show}
    >
      <div
        className={`${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Text.PrimaryColor} row`}
      >
        {props.bodyText}
      </div>
      {props.children}
    </VTPModal>
  );
};

export default VTPConfirmationModal;
