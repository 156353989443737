import React, { useEffect, useState } from "react";
import styles from "./notificationBanner.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { INotification } from "../../lib/apiModels";
import VTPStyles from "../../styles/vtpStyles";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import CookieUtils from "../../lib/cookieUtils";

const COOKIE_NAME = "closed_system_notifications";

const GetClosedNotificationIds = (): string[] => {
    const cookie = CookieUtils.GetCookie(COOKIE_NAME);
    return cookie ? JSON.parse(cookie) : [];
}

const NotificationBanner: React.FC = () => {
    const { isAuthenticated } = useAuth0();
    const { getNotifications } = useVTPCloud();
    const [notifications, setNotifications] = useState<INotification[]>([]);

    useEffect(() => {
        if (isAuthenticated) {
            getNotifications()
                .then((res) => {
                    const closedNotificationIds = GetClosedNotificationIds();
                    const newNotifications = res.items
                        ? res.items
                            // Filter out expired notifications.
                            .filter(n => !n.expiryDate || new Date(n.expiryDate) >= new Date())
                            // Filter out closed notifications
                            .filter(n => !closedNotificationIds.includes(n.id))
                        : [];

                    setNotifications(newNotifications);
                })
                .catch((error) =>
                    console.log(`Failed to load notifications, reason: ${error}`)
                );
        }
    }, [isAuthenticated]);

    const handleClose = (notificationId: string) => {
        // Get current closed notifications from cookie
        const closedNotificationIds = GetClosedNotificationIds();

        // Add new closed notification ID
        const updatedClosedNotificationIds = [...closedNotificationIds, notificationId];

        // Set updated closed notifications in cookie
        CookieUtils.SetCookie(COOKIE_NAME, JSON.stringify(updatedClosedNotificationIds), 24);

        // Remove notification from state
        setNotifications(notifications.filter(n => n.id !== notificationId));
    };

    return !notifications.length ? (
        <></>
    ) : (
        <div className={`${styles.banner} ${VTPStyles.Typography.Body.Medium}`}>
            {notifications.map((notification) => (
                <div key={notification.id} className={styles.notification}>
                    <div
                        className={styles.notificationMessage}
                        dangerouslySetInnerHTML={{ __html: notification.message }}
                    />
                    <CloseIcon
                        className={styles.closeIcon}
                        onClick={() => handleClose(notification.id)}
                    />
                </div>
            ))}
        </div>
    );
};

export default NotificationBanner;
