import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import styles from "./createOrganisationModal.module.css";
import { Row } from "react-bootstrap";
import VTPLabel from "../base/form/vtpLabel";
import VTPTextInput, { BorderType } from "../base/form/vtpTextInput";
import InviteUsersList, { UserInvite } from "../users/inviteUsersList";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { AlertType, useAlert } from "../../hooks/useAlert";
import { useAppContext } from "../../context/app-context";
import { BridgeErrorCode, IErrorResponse, Tenant } from "../../lib/apiModels";
import VTPModal from "./vtpModal";

interface Props {
  onCancel?: () => void;
}

const CreateOrganisationModal = (props: Props) => {
  const {
    getTenants,
    postTenant,
    inviteUser,
    refreshUserTenants,
    refreshUserProfile,
  } = useVTPCloud();
  const { pushAlert } = useAlert();
  const { setSelectedTenant, getSelectedTenant } = useAppContext();
  const [, setUserTenants] = useState<Tenant[]>([]);

  const [companyNameInput, setCompanyNameInput] = useState<string>("");
  const [userInvites, setUserInvites] = useState<UserInvite[]>([]);

  const [createOrgEnabled, setCreateOrgEnabled] = useState<boolean>(false);
  const [invitesArePending, setInvitesArePending] = useState<boolean>(false);

  const handleCompanyNameInput = (event: ChangeEvent<HTMLInputElement>) => {
    setCompanyNameInput(event.target.value);
  };

  // Validates if input is not empty.
  const companyNameInputValidation = (inputValue: string) => {
    if (inputValue) {
      setCreateOrgEnabled(true);
      return true;
    } else {
      setCreateOrgEnabled(false);
      return false;
    }
  };

  const createOrganization = () => {
    postTenant({ displayName: companyNameInput })
      .then((t) => {
        // Update app context with latest tenant list.
        pushAlert({
          message: "You successfully created an organization.",
          type: AlertType.Success,
        });

        getTenants().then((tenantResponse) => {
          setUserTenants(tenantResponse.items);
        });

        // Make newly created tenant the active one
        setInvitesArePending(true);
        setSelectedTenant(t);
        refreshUserTenants();
        refreshUserProfile();
      })
      .catch((error: IErrorResponse) => {
        if (error.bridgeErrorCode == BridgeErrorCode.TenantNameNotUnique) {
          pushAlert({
            message: "Organization with this company name already exists.",
            type: AlertType.Error,
          });
        }
      });
  };

  useEffect(() => {
    if (invitesArePending) {
      // Inviting users to newly created tenant.
      userInvites.forEach(function (inv) {
        inviteUser({
          email: inv.email,
          role: inv.role,
        }).then(() =>
          pushAlert({
            message: `You successfully added a user to ${getSelectedTenant?.displayName}.`,
            type: AlertType.Success,
          })
        );
      });

      setInvitesArePending(false);
      props.onCancel?.();
    }
  }, [getSelectedTenant]);

  const getModalButtons = (): ReactNode[] => {
    return [
      <VTPButton
        key="cancelButton"
        onClick={() => {
          props.onCancel?.();
        }}
        size={ButtonSize.Medium}
        type={ButtonType.Tertiary}
        style={{ marginRight: "16px" }}
      >
        Cancel
      </VTPButton>,
      <VTPButton
        key="createButton"
        size={ButtonSize.Medium}
        type={ButtonType.Primary}
        onClick={() => createOrganization()}
        disabled={!createOrgEnabled}
      >
        Create organization
      </VTPButton>,
    ];
  };

  return (
    <VTPModal
      style={{ display: "none" }}
      className={styles.container}
      title={"Create organization"}
      buttons={getModalButtons()}
    >
      <div style={{ height: "403px" }}>
        <Row>
          <VTPLabel text={"Company name"} />
          <VTPTextInput
            style={{ marginTop: "32px" }}
            placeholder={"Enter company name"}
            borderStyle={BorderType.RoundBorder}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleCompanyNameInput(event)
            }
            // Validates if input is not empty on input focus lost.
            validationRules={[
              {
                validationFunction: companyNameInputValidation,
                validationErrorMessage: 'Company name cannot be empty',
              }
            ]}
          />
        </Row>
        <Row style={{ height: "255px" }}>
          <InviteUsersList
            listUpdateCallback={(items) => setUserInvites(items)}
            style={{ marginTop: "34px" }}
            canDoLaterHint={true}
            showLabel={true}
          />
        </Row>
      </div>
    </VTPModal>
  );
};

export default CreateOrganisationModal;
