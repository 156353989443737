import React from "react";
import styles from "./vtpInputHeader.module.css";
import { VTPFormCardTextInput } from "./vtpFormCard";
import { StylingProps } from "../../lib/common";

interface Props extends StylingProps {
  value?: string;
  readonly?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const VTPInputHeader = (props: Props) => {
  return (
    <div className={`${styles.title} ${props.className}`}>
      <div className={`${styles.inputContainer}`}>
        <VTPFormCardTextInput
          value={props.value}
          onChange={props.onChange}
          readonly={props.readonly}
        />
      </div>
    </div>
  );
};

export default VTPInputHeader;
