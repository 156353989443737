import { useEffect, useState } from "react";
import { AlertType, useAlert } from "../../hooks/useAlert";
import { useAuth0 } from "@auth0/auth0-react";
import { useVTPCloud } from "../../context/vtpCloud-context";
import useInterval from "../../hooks/useInterval";
import {useTranslation} from "react-i18next";

const EmailHandler = () => {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const { sendEmailVerificationMail, refreshUserTenants, refreshUserProfile } = useVTPCloud();
  const { pushAlert, removeAlert } = useAlert();

  // State to control polling
  const [pollData, setPollData] = useState(false);

  useEffect(() => {
    if (user) {
      if (!user.email_verified) {
        // Push notification that e-mail is not verified, with the possibility to resend the verification e-mail.
        pushAlert({
          id: "email-not-verified", // Ensuring idempotency of the alert
          message: t("emailHandler.notVerified"),
          type: AlertType.Info,
          actions: [
            {
              text: t("emailHandler.resendVerification"),
              action: () => {
                sendEmailVerificationMail()
                  .then(() =>
                    pushAlert({
                      message: t("emailHandler.notifications.emailSentSuccess", { email: user.email }),
                      type: AlertType.Success,
                    })
                  )
                  .catch(() =>
                    pushAlert({
                      message: t("emailHandler.notifications.emailSentError", { email: user.email }),
                      type: AlertType.Error,
                    })
                  );
              },
            },
          ],
        });

        // Start polling if the email is not verified
        setPollData(true);
      } else if (pollData) {
        // Stop polling since the email is verified
        setPollData(false);
        removeAlert("email-not-verified");
        pushAlert({
          message: t("emailHandler.notifications.emailVerified"),
          type: AlertType.Success,
        });
        
        // Refresh user related data in app context. 
        refreshUserTenants();
        refreshUserProfile();
      }
    }
  }, [user, pushAlert, sendEmailVerificationMail]);

  useInterval(
    () => {
      if (pollData) {
          getAccessTokenSilently({ ignoreCache: true })
              .catch((e) => console.log(`Failed to poll! ${e}`));
      }
    },
    pollData ? 10000 : null
  ); // Directly control the polling based on pollData's state

  return null;
};

export default EmailHandler;
