import React, { useState } from "react";
import VTPFormCard from "../base/vtpFormCard";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { AlertType, useAlert } from "../../hooks/useAlert";
import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import { useAppContext } from "../../context/app-context";
import vtpStyles from "../../styles/vtpStyles";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { ReactComponent as EmailIcon } from "../../assets/icons/email.svg";
import {useTranslation} from "react-i18next";

const SigninInformation: React.FC<WithAuth0Props> = (props: WithAuth0Props) => {
  const { t } = useTranslation();
  const { user } = props.auth0;
  const { pushAlert } = useAlert();
  const { triggerPasswordResetFlow } = useVTPCloud();
  const { getIsAuthenticatedWithSso } = useAppContext();
  const [enabledPasswordLink, setEnabledPasswordLink] = useState<boolean>(true);

  const goToAuth0ResetPassword = () => {
    setEnabledPasswordLink(false);
    triggerPasswordResetFlow()
      .then((response) => {
        pushAlert({
          message: t("accountPage.notifications.changePasswordFlowSuccess", {email: user!.email}),
          type: AlertType.Success,
        });
      })
      .catch((error) => {
        pushAlert({
          message:
              t("accountPage.notifications.changePasswordFlowError"),
          type: AlertType.Error,
        });
      })
      .finally(() => setEnabledPasswordLink(true));
  };

  return (
    <VTPFormCard
      includeEditButtons={false}
      header={""}
      formCardRows={[
        {
          left: <div>{t("accountPage.accountInfo.email")}</div>,
          right: <span>{user!.email}</span>,
        },
        {
          left: <div>{t("accountPage.signInInformation.password")}</div>,
          right: getIsAuthenticatedWithSso ? (
            <span className={vtpStyles.Typography.Body.Medium}>
              {t("accountPage.signInInformation.changePasswordOnSingleSignOn")}
            </span>
          ) : enabledPasswordLink ? (
            <VTPButton
              style={{ transform: "translateX(15px)" }}
              size={ButtonSize.Small}
              type={ButtonType.Tertiary}
              onClick={() => goToAuth0ResetPassword()}
            >
              {t("accountPage.signInInformation.changePassword")} <EmailIcon />
            </VTPButton>
          ) : (
            <VTPButton
              style={{ transform: "translateX(15px)" }}
              size={ButtonSize.Small}
              type={ButtonType.Tertiary}
              disabled={true}
            >
              {t("accountPage.signInInformation.changePassword")} <EmailIcon />
            </VTPButton>
          ),
        },
      ]}
    />
  );
};

export default withAuth0(SigninInformation);
