import React from "react";
import styles from "./confirmState.module.css";
import { StateMachineHandle } from "../../../../hooks/useStateMachine";
import { Course, User, UserSortingKey } from "../../../../lib/apiModels";
import { AssignTokenModalState } from "../assignTokensModal";
import VTPStyles from "../../../../styles/vtpStyles";
import VTPSortableTableHeader, {
  SortingState,
  useSortingStateMachine,
} from "../../../base/vtpSortableTableHeader";
import VTPTable from "../../../base/vtpTable";
import VTPButton, { ButtonSize, ButtonType } from "../../../base/button";
import { useVTPCloud } from "../../../../context/vtpCloud-context";
import { AlertType, useAlert } from "../../../../hooks/useAlert";
import { useTranslation } from "react-i18next";

const ConfirmState = (props: {
  modalState: StateMachineHandle<AssignTokenModalState>;
  selectedUsers: User[];
  course: Course;
  onSubmit: () => any;
}) => {
  const { t } = useTranslation();
  const sortingStateMachine = useSortingStateMachine(UserSortingKey.Email);
  const { assignTokens } = useVTPCloud();
  const { pushAlert } = useAlert();

  const assignUsers = () => {
    assignTokens({
      courseId: props.course.courseId,
      tokenAssignments: props.selectedUsers.map((u) => {
        return { assignedToUserId: u.id };
      }),
    })
      .then(() => {
        pushAlert({
          message: t("licenseAssignmentModal.notifications.assignedSuccess", {
            userCount: props.selectedUsers.length,
          }),
          type: AlertType.Success,
        });
        props.onSubmit();
      })
      .catch(() => {
        pushAlert({
          message: t("licenseAssignmentModal.notifications.assignedError", {
            userCount: props.selectedUsers.length,
          }),
          type: AlertType.Error,
        });
      });
  };

  const sortSelectedUsers = (a: User, b: User) => {
    const key =
      sortingStateMachine.state.value === UserSortingKey.Email
        ? "email"
        : "fullName";
    const valueA = a[key] ?? "";
    const valueB = b[key] ?? "";
    return sortingStateMachine.state.sortingState === SortingState.Asc
      ? valueA.localeCompare(valueB)
      : valueB.localeCompare(valueA);
  };

  const columns = [
    <VTPSortableTableHeader
      key={UserSortingKey.Email}
      text={t("licenseAssignmentModal.tableColumns.email")}
      value={UserSortingKey.Email}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={UserSortingKey.UserName}
      text={t("licenseAssignmentModal.tableColumns.userName")}
      value={UserSortingKey.UserName}
      stateMachine={sortingStateMachine}
    />,
  ];

  const rows = (): any[][] => {
    return props.selectedUsers.sort(sortSelectedUsers).map((u, pos) => {
      return [
        <div
          key={u.email}
          className={`${VTPStyles.Typography.Body.Small} ${styles.email}`}
        >
          {u.email}
        </div>,
        <div
          key={u.fullName}
          className={`${VTPStyles.Typography.Body.Small} ${styles.userName}`}
        >
          {u.fullName ?? "-"}
        </div>,
      ];
    });
  };

  return (
    <div className={styles.container}>
      <div
        className={`${VTPStyles.Typography.Headers.H2SubheaderSmall} ${styles.header}`}
      >
        {t("licenseAssignmentModal.aboutToAddUsers", {
          userCount: props.selectedUsers.length,
        })}
        <br />
        {props.course.name}
      </div>
      <div className={`${VTPStyles.Typography.Body.Small} ${styles.subheader}`}>
        {props.course.bundledCourses.length === 0
          ? t("licenseAssignmentModal.usersWillBeAdded", {
              courseName: props.course.name,
            })
          : t("licenseAssignmentModal.usersWillBeAddedToAllCourses", {
              courseName: props.course.name,
            })}
      </div>
      <VTPTable columns={columns} rows={[]} />
      <div className={styles.userList}>
        <VTPTable columns={columns} rows={rows()} />
      </div>
      <div className={styles.btnGroup}>
        <VTPButton
          size={ButtonSize.Medium}
          type={ButtonType.Tertiary}
          onClick={() =>
            props.modalState.setState(AssignTokenModalState.UserSelection)
          }
        >
          {t("common.inputs.cancel")}
        </VTPButton>
        <VTPButton
          size={ButtonSize.Medium}
          type={ButtonType.Primary}
          onClick={() => assignUsers()}
        >
          {t("common.inputs.confirm")}
        </VTPButton>
      </div>
    </div>
  );
};

export default ConfirmState;
