import React, { ReactNode } from "react";
import styles from "./vtpTooltip.module.css";
import { StylingProps } from "../../lib/common";
import VTPStyles from "../../styles/vtpStyles";

enum TooltipPosition {
  Top,
  Right,
  Bottom,
  Left,
}

const TooltipPositionClass = (position: TooltipPosition) => {
  switch (position) {
    case TooltipPosition.Top:
      return styles.tooltipTop;
    case TooltipPosition.Right:
      return styles.tooltipRight;
    case TooltipPosition.Left:
      return styles.tooltipLeft;
    case TooltipPosition.Bottom:
      return styles.tooltipBottom;
    default:
      throw Error("Unknown tooltip position");
  }
};

interface TooltipProps extends StylingProps {
  position: TooltipPosition;
  tooltip: ReactNode;
  tooltipWidth?: number;
  disabled?: boolean;
}

const VTPTooltip = (props: TooltipProps) => {
  return (
    <div
      className={`${styles.tooltip} ${TooltipPositionClass(props.position)}`}
    >
      {props.children}
      {props.disabled ? null : (
        <span
          className={`${styles.tooltipText} ${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Panel.WhiteColor} no-text-select`}
          style={{ width: props.tooltipWidth ?? "auto" }}
        >
          {props.tooltip}
        </span>
      )}
    </div>
  );
};

export default VTPTooltip;
export { TooltipPosition };
