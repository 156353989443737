import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { ReactComponent as ErrorIcon } from "../../assets/alert/error.svg";
import { ReactComponent as SuccessIcon } from "../../assets/alert/success.svg";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import {
  AlertData,
  AlertType,
  useAlert,
} from "../../hooks/useAlert";
import styles from "./vtpAlert.module.css";

const alertIcon = (alertType: AlertType) => {
  switch (alertType) {
    case AlertType.Success: {
      return <SuccessIcon />;
    }
    case AlertType.Error: {
      return <ErrorIcon />;
    }
    case AlertType.Info: {
      return <InfoIcon className={styles.infoIcon} />;
    }
    default: {
      throw new Error("Unknown alert type.");
    }
  }
};

interface AlertProps extends PropsWithChildren<any> {
  data: AlertData;
  onClose: () => void;
}

const AlertNotification = (props: AlertProps) => {
  const [isClosed, setIsClosed] = useState(false);
  const alertRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(!props.data.actions) {
      const timerId = setTimeout(() => setIsClosed(true), 10000);
      return () => clearTimeout(timerId);
    }
  }, []);

  useEffect(() => {
    if (isClosed && alertRef.current) {
      const alertElement = alertRef.current;
      const handleTransitionEnd = () => {
        props.onClose();
      };

      alertElement.addEventListener("transitionend", handleTransitionEnd);

      return () => {
        alertElement.removeEventListener("transitionend", handleTransitionEnd);
      };
    }
  }, [isClosed, props]);

  if (props) {
    return (
      <Alert
        className={`${styles.vtpAlert} ${isClosed ? styles.closed : ""}`}
        closeVariant={"white"}
        onClose={() => setIsClosed(true)}
        dismissible
        style={{}}
        ref={alertRef}
      >
        <div className={`${styles.vtpAlertTop}`}>
          <div className={`${styles.vtpAlertTopIcon}`}>
            {alertIcon(props.data.type)}
          </div>
          <div className={`${styles.vtpAlertTopMessageContainer}`}>
            <div className={`${styles.vtpAlertTopMessage}`}>
              {props.data.message}
            </div>
          </div>
        </div>
        {props.data.errorCode ? (
          <div className={`${styles.vtpAlertBottom}`}>
            <div className={`${styles.vtpAlertBottomErrorCode}`}>
              Error code: [{props.data.errorCode}]
            </div>
          </div>
        ) : null}
        {props.data.actions ? (
          <div className={styles.alertActionGroup}>
            {props.data.actions.map((a, pos) => {
              return <div key={pos} className={`${styles.alertAction} no-text-select`} onClick={() => a.action()}>
                {a.text}
              </div>
            })}
          </div>
        ) : null}
      </Alert>
    );
  } else return null;
};

const AlertHandler = () => {
  const [alerts, setAlerts] = useState<AlertData[]>([]);
  const { subscribe } = useAlert();

  useEffect(() => {
    const unsubscribe = subscribe((message: AlertData | string) => {
      setAlerts(currentAlerts => {
        if (typeof message === 'string') {
          // Handling the alert closure by removing the alert with the given ID
          return currentAlerts.filter(alert => alert.id !== message);
        } else {
          // Check if the alert with the same id already exists
          const alertExists = currentAlerts.some(alert => alert.id === message.id);
          
          if (!alertExists) {
            // If it doesn't exist, add the new alert to the list
            return [...currentAlerts, message];
          }
          
          // If it exists, just return the current alerts without adding the new one
          return currentAlerts;
        }
      });
    });

    return () => unsubscribe();
  }, [subscribe]);
  const handleClose = (alertId: string) => {
    // Remove the alert from state when it's closed
    setAlerts((currentAlerts) => currentAlerts.filter(alert => alert.id !== alertId));
  };

  return (
      <div className={styles.vtpAlertContainer}>
        {alerts.map((alert) => (
            <AlertNotification
                key={alert.id}
                data={alert}
                onClose={() => handleClose(alert.id!)} // The '!' asserts that id is non-null
            />
        ))}
      </div>
  );
};

export default AlertHandler;
