import React, { ReactElement, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import styles from "./vtpContextMenu.module.css";
import { ReactComponent as MoreButtonIcon } from "../../../assets/icons/more_button.svg";
import useClickOutside from "../../../hooks/useClickOutside";
import { StylingProps } from "../../../lib/common";
import VTPStyles from "../../../styles/vtpStyles";
import VTPTooltip, { TooltipPosition } from "../vtpTooltip";

export interface IContextMenuOption {
  icon: ReactElement;
  name: string;
  value?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  downloadLink?: string;
  downloadFileName?: string;
}

interface ContextMenuProps extends StylingProps {
  options: IContextMenuOption[];
  onOptionSelected: (selectedOption: IContextMenuOption) => void;
  alignRight?: boolean;
}

const VTPContextMenu = (props: ContextMenuProps) => {
  const fullElementRef = useRef(null);
  useClickOutside([fullElementRef], () => setShowMenu(false));

  const menuElementRef = useRef<HTMLInputElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div
      ref={fullElementRef}
      style={props.style}
      className={`${styles.container} ${props.className ?? ""}`}
    >
      <MoreButtonIcon
        className={`${styles.moreButton} no-text-select`}
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu(!showMenu);
        }}
      />
      {showMenu ? (
        <Card
          ref={menuElementRef}
          className={styles.contextMenu + " no-text-select"}
          style={{ transform: props.alignRight ? "translateX(-94%)" : "" }}
        >
          {props.options.map((option, index) => {
            return (
              <VTPTooltip
                key={index}
                position={TooltipPosition.Top}
                tooltip={option.disabledTooltip}
                disabled={!option.disabled}
                tooltipWidth={184}
              >
                <a
                  href={option.downloadLink}
                  download={option.downloadFileName ?? true}
                  target={option.downloadLink ? "_blank" : undefined}
                  rel={"noreferrer"}
                  className={`${styles.contextMenuRow} ${
                    option.disabled ? styles.disabled : ""
                  } align-items-center`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!option.disabled) {
                      props.onOptionSelected(option);
                      setShowMenu(false);
                    }
                  }}
                >
                  <div className={styles.contextMenuIcon}>{option.icon}</div>
                  <span
                    className={`${VTPStyles.Typography.Buttons.SizeSmall} ${VTPStyles.Color.Text.PrimaryColor}`}
                  >
                    {option.name}
                  </span>
                </a>
              </VTPTooltip>
            );
          })}
        </Card>
      ) : null}
    </div>
  );
};

export default VTPContextMenu;
