import React, { useEffect, useState } from "react";
import styles from "./userSelectionState.module.css";
import { Course, User, UserSortingKey } from "../../../../lib/apiModels";
import { StateMachineHandle } from "../../../../hooks/useStateMachine";
import { useVTPCloud } from "../../../../context/vtpCloud-context";
import { TokenPoolState, useAppContext } from "../../../../context/app-context";
import VTPSortableTableHeader, {
  useSortingStateMachine,
} from "../../../base/vtpSortableTableHeader";
import { useEndpointPagination } from "../../../../hooks/usePagination";
import VTPCheckbox from "../../../base/form/vtpCheckbox";
import VTPStyles from "../../../../styles/vtpStyles";
import UserStatusLabel from "../../../common/userStatusLabel";
import VTPTooltip, { TooltipPosition } from "../../../base/vtpTooltip";
import CommonUtilities from "../../../../lib/common";
import TokenState from "../../tokenState";
import TableSearchInput from "../../../base/table/tableSearchInput";
import VTPTable from "../../../base/vtpTable";
import VTPButton, { ButtonSize, ButtonType } from "../../../base/button";
import { AssignTokenModalState } from "../assignTokensModal";
import { ReactComponent as CheckboxIcon } from "../../../../assets/icons/checkbox.svg";
import { useTranslation } from "react-i18next";

const UserSelectionState = (props: {
  course: Course;
  selectedUsers: User[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  modalState: StateMachineHandle<AssignTokenModalState>;
}) => {
  const { t } = useTranslation();
  const { getUsers, getTokens } = useVTPCloud();
  const { getSelectedTenant, getTokenPoolState } = useAppContext();
  const sortingStateMachine = useSortingStateMachine(UserSortingKey.Email);
  const [tokenPoolState, setTokenPoolState] = useState<TokenPoolState>({
    totalTokens: 0,
    usedTokens: 0,
    unusedTokens: 0,
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const userPaging = useEndpointPagination(getUsers, -1, {
    sort: `${sortingStateMachine.state.value}:${sortingStateMachine.state.sortingState}`,
    search: searchQuery,
  });
  const tokenPaging = useEndpointPagination(getTokens, -1, {
    all: true,
    courseId: props.course.courseId,
  });

  useEffect(() => {
    setTokenPoolState({
      ...getTokenPoolState,
      usedTokens:
        getTokenPoolState.usedTokens +
        props.selectedUsers.length * props.course.licenseCost,
    });
  }, [props.selectedUsers]);

  // Sorting
  useEffect(() => {
    const sortingParam = `${sortingStateMachine.state.value}:${sortingStateMachine.state.sortingState}`;
    if (userPaging.options.sort !== sortingParam) {
      userPaging.setOptions({
        ...userPaging.options,
        sort: sortingParam,
      });
    }
  }, [sortingStateMachine.state]);

  // Search query with delay
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (userPaging.options.search !== searchQuery) {
        userPaging.setOptions({
          ...userPaging.options,
          search: searchQuery,
        });
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const unlicensedUsers = (): User[] => {
    const filtered = userPaging.pageData?.filter(
      (u) =>
        tokenPaging.pageData?.some((t) => t.assignedToUserId == u.id) == false
    );

    return filtered ?? [];
  };

  const licensedUsers = (): User[] => {
    const filtered = userPaging.pageData?.filter((u) =>
      tokenPaging.pageData?.some((t) => t.assignedToUserId == u.id)
    );

    return filtered ?? [];
  };

  const userSelected = (user: User) => {
    props.setSelectedUsers((prevSelectedUsers) => {
      // Check if the user is already selected
      const isUserSelected = prevSelectedUsers.some(
        (selectedUser) => selectedUser.id === user.id
      );

      if (isUserSelected) {
        // If the user is already selected, filter them out to "remove" them
        return prevSelectedUsers.filter(
          (selectedUser) => selectedUser.id !== user.id
        );
      } else {
        // If the user is not selected, add them to the selectedUsers array
        return [...prevSelectedUsers, user];
      }
    });
  };

  const columns = [
    <VTPCheckbox
      key={"check"}
      checked={unlicensedUsers().length === props.selectedUsers.length}
      onChange={() => {
        if (unlicensedUsers().length === props.selectedUsers.length) {
          props.setSelectedUsers([]);
        } else {
          props.setSelectedUsers(unlicensedUsers());
        }
      }}
    />,
    <VTPSortableTableHeader
      key={UserSortingKey.Email}
      text={t("licenseAssignmentModal.tableColumns.email")}
      value={UserSortingKey.Email}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={UserSortingKey.UserName}
      text={t("licenseAssignmentModal.tableColumns.userName")}
      value={UserSortingKey.UserName}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={UserSortingKey.Status}
      text={t("licenseAssignmentModal.tableColumns.status")}
      value={UserSortingKey.Status}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={UserSortingKey.UserCreated}
      text={t("licenseAssignmentModal.tableColumns.userCreated")}
      value={UserSortingKey.UserCreated}
      stateMachine={sortingStateMachine}
    />,
  ];

  const rows = (users: User[], licensed: boolean): any[][] => {
    if (!userPaging.pageData || !getSelectedTenant) return [];

    return users.map((u, pos) => {
      return [
        licensed ? (
          <CheckboxIcon />
        ) : (
          <VTPCheckbox
            key={u.id}
            checked={props.selectedUsers.some((su) => su.id == u.id)}
            onChange={() => userSelected(u)}
          />
        ),
        <div key={u.email} className={VTPStyles.Typography.Body.Small}>
          {u.email}
        </div>,
        <div key={u.email} className={VTPStyles.Typography.Body.Small}>
          {u.fullName ?? "-"}
        </div>,
        <UserStatusLabel
          key={u.id}
          user={u}
          licensed={licensed}
          tooltipPosition={
            pos < 2 && !licensed ? TooltipPosition.Bottom : TooltipPosition.Top
          }
        />,
        <div key={u.createdAt} className={VTPStyles.Typography.Body.Small}>
          {CommonUtilities.FormatDate(u.createdAt)}
        </div>,
      ];
    });
  };

  return (
    <div className={styles.assignUsersContainer}>
      <div className={styles.header}>
        <div
          className={`${VTPStyles.Typography.Headers.H2SubheaderLarge} ${VTPStyles.Color.Text.PrimaryColor}`}
        >
          {t("licenseAssignmentModal.addUserTo", {
            courseName: props.course.name,
          })}
        </div>
      </div>
      {props.course.bundledCourses.length > 0 ? (
        <div
          className={`${styles.subHeader} ${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Text.PrimaryColor}`}
        >
          {t("licenseAssignmentModal.kandaBundle")}
        </div>
      ) : null}
      <div className={styles.controls}>
        <div className={`${VTPStyles.Typography.Body.Medium}`}>
          <span className={VTPStyles.Color.Text.PrimaryColor}>
            {props.selectedUsers.length}
          </span>
          <span className={VTPStyles.Color.Text.SecondaryColor}>
            {" "}
            {t("licenseAssignmentModal.selected")}
          </span>
        </div>
        <TokenState tokenState={tokenPoolState} />
        <TableSearchInput
          placeHolder={t("licenseAssignmentModal.inputs.searchUsers")}
          onChange={(val) => setSearchQuery(val.target.value)}
        />
      </div>
      <VTPTable columns={columns} rows={[]} />
      <div className={styles.userList}>
        <VTPTable columns={columns} rows={rows(unlicensedUsers(), false)} />
        {licensedUsers().length ?? 0 > 0 ? (
          <>
            <div
              className={`${styles.alreadyAssignedLabel} ${VTPStyles.Typography.Body.Medium}`}
            >
              {t("licenseAssignmentModal.alreadyAssigned")}
            </div>
            <VTPTable columns={columns} rows={rows(licensedUsers(), true)} />
          </>
        ) : null}
      </div>
      <div className={styles.buttonGroup}>
        <VTPTooltip
          position={TooltipPosition.Top}
          tooltip={t("licenseAssignmentModal.tooltips.missingLicenses")}
          disabled={
            tokenPoolState &&
            tokenPoolState.totalTokens >= tokenPoolState.usedTokens
          }
          tooltipWidth={192}
        >
          <VTPButton
            size={ButtonSize.Medium}
            type={ButtonType.Primary}
            disabled={
              props.selectedUsers.length === 0 ||
              (tokenPoolState &&
                tokenPoolState.totalTokens < tokenPoolState.usedTokens)
            }
            onClick={() =>
              props.modalState.setState(AssignTokenModalState.Confirm)
            }
          >
            {t("licenseAssignmentModal.inputs.addUsers")}
          </VTPButton>
        </VTPTooltip>
      </div>
    </div>
  );
};

export default UserSelectionState;
