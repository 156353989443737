import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./almostThereFlow.module.css";
import dropdownStyles from "../base/form/vtpDropdown.module.css";
import flowLayoutStyles from "../../pages/guidedFlowLayout.module.css";
import useStateMachine from "../../hooks/useStateMachine";
import { Row } from "react-bootstrap";
import { ReactComponent as RadioUnchecked } from "../../assets/dropdown-radio-unchecked.svg";
import { ReactComponent as RadioChecked } from "../../assets/dropdown-radio-checked.svg";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { IDropdownOption } from "../base/form/vtpDropdown";
import { useAppContext } from "../../context/app-context";
import { useVTPCloud } from "../../context/vtpCloud-context";
import CreateOrganisationModal from "../modal/createOrganisationModal";
import { AlertType, useAlert } from "../../hooks/useAlert";
import GuidedFlowLayout from "../../pages/guidedFlowLayout";
import CommonUtilities from "../../lib/common";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tenant } from "../../lib/apiModels";
import VTPStyles from "../../styles/vtpStyles";

enum FlowState {
  ChooseTenant = 0,
}

interface FlowLifecycleProps {
  onFlowFinished?: () => void;
  onFlowError?: () => void;
}

const AlmostThereFlow = (props: FlowLifecycleProps) => {
  const stateMachine = useStateMachine<FlowState>(FlowState.ChooseTenant);
  const navigate = useNavigate();
  const { pushAlert } = useAlert();
  const { getTenants } = useVTPCloud();
  const { getSelectedTenant, setSelectedTenant } = useAppContext();
  const [getUserTenants, setUserTenants] = useState<Tenant[]>([]);
  const [selectedTenantValue, setSelectedTenantValue] = useState<string>(
    getSelectedTenant?.id ?? ""
  );
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const [showCreateOrganisation, setShowCreateOrganisation] =
    useState<boolean>(false);
  const [dropdownOptions, setDropdownOptions] = useState([
    { name: "...", value: "loading" },
  ]);
  const listItemRefs = useRef<(null | HTMLDivElement)[]>([]);

  useEffect(() => {
    // Populate the tenants list if empty
    if (getUserTenants.length < 1) {
      getTenants().then((tenantResponse) => {
        setUserTenants(tenantResponse.items);
        return;
      });
    }

    // Map tenants list to dropdown options
    const options = getUserTenants.map<IDropdownOption>((tenant) => {
      return { name: tenant.displayName, value: tenant.id };
    });
    setDropdownOptions(options);
  }, [getUserTenants]);

  useLayoutEffect(() => {
    if (selectedIndex > 2) {
      listItemRefs.current[selectedIndex]?.scrollIntoView({
        block: "center",
        behavior: "auto",
      });
    }
  }, [selectedTenantValue]);

  const finishFlow = async () => {
    navigate(CommonUtilities.GetRedirectUrlFromParams(searchParams));
  };

  const AvailableTenantsList = () => {
    return (
      <>
        {dropdownOptions.map((option, index) => {
          const isSelected = option.value === selectedTenantValue;
          return (
            <div
              data-item={"true"}
              ref={(i) => (listItemRefs.current[index] = i)}
              key={index}
              onClick={() => {
                setSelectedTenantValue(option.value);
                setSelectedIndex(index);
              }}
              className={styles.almostThereTenantOption}
            >
              <div
                className={
                  dropdownStyles.vtpDropdownOptionIcon +
                  " d-flex flex-row-reverse"
                }
              >
                {isSelected ? <RadioChecked /> : <RadioUnchecked />}
              </div>
              <div className={dropdownStyles.vtpDropdownOptionLabel}>
                {option.name}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const SelectTenant = () => {
    return (
      <>
        <div className={"d-flex flex-column align-items-center"}>
          <h2 className={`${VTPStyles.Typography.Headers.H2SubheaderLarge} ${VTPStyles.Color.Text.PrimaryColor}`}>Almost there...</h2>
          <span className={`${flowLayoutStyles.guidedFlowText} ${VTPStyles.Typography.Body.Medium} ${VTPStyles.Color.Text.PrimaryColor}`}>
            Where should we assign the course you&apos;re about to buy?
          </span>
        </div>
        <Row className={"d-flex align-items-center justify-content-center"}>
          <div
            //ref={containerRef}
            className={"d-flex flex-column " + styles.almostThereTenantList}
          >
            <AvailableTenantsList></AvailableTenantsList>
          </div>
          <div className={styles.almostThereSeparatorContainer}>
            <div
              className={styles.almostThereSeparator}
              style={{ marginRight: "22px" }}
            />
            <span className={styles.almostThereSeparatorText}>or</span>
            <div
              className={styles.almostThereSeparator}
              style={{ marginLeft: "22px" }}
            />
          </div>
          <div className={styles.almostThereAdditionalButtonContainer}>
            <VTPButton
              size={ButtonSize.Medium}
              type={ButtonType.Secondary}
              onClick={() => {
                setShowCreateOrganisation(true);
              }}
            >
              Create organization
            </VTPButton>
          </div>
        </Row>
        <div
          className={flowLayoutStyles.guidedFlowButtons}
          style={{ marginRight: "16px" }}
        >
          <VTPButton
            onClick={() => {
              try {
                const tenant = getUserTenants.find(
                  (t) => t.id == selectedTenantValue
                );
                if (tenant) {
                  setSelectedTenant(tenant);
                }
                finishFlow();
              } catch (err) {
                pushAlert({
                  message: "No organization selected!",
                  type: AlertType.Error,
                });
              }
            }}
            disabled={selectedTenantValue === ""}
            size={ButtonSize.Medium}
            type={ButtonType.Primary}
          >
            Continue
          </VTPButton>
        </div>
      </>
    );
  };

  const RenderState = (state: FlowState) => {
    switch (state) {
      case FlowState.ChooseTenant:
        return <SelectTenant />;
      default:
        throw new Error(`Unknown state "${state}"`);
    }
  };

  return (
    <GuidedFlowLayout>
      <div className={styles.almostThereContainer}>
        {RenderState(stateMachine.state)}
        {showCreateOrganisation ? (
          <CreateOrganisationModal
            onCancel={() => setShowCreateOrganisation(false)}
          />
        ) : null}
      </div>
    </GuidedFlowLayout>
  );
};

export default AlmostThereFlow;
