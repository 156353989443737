import styles from "./vtpCard.module.css";
import React, { CSSProperties, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  style?: CSSProperties;
  className?: string;
}

const VTPCard = (props: Props) => {
  return (
    <div
      className={`${styles.vtpCardContainer} ${props.className ?? ""}`}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default VTPCard;
