import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/app-context";
import { Config } from "../config";
import PageSection from "../components/base/pageSection";
import VTPButton, { ButtonSize, ButtonType } from "../components/base/button";
import styles from "./support.module.css";
import { ReactComponent as PlusIcon } from "../assets/plus.svg";
import { ReactComponent as SupportIcon } from "../assets/navigation/support.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import { ReactComponent as EditIcon } from "../assets/icons/edit_pencil.svg";
import { ReactComponent as TrashbinIcon } from "../assets/icons/trashbin.svg";
import CreateOrganisationModal from "../components/modal/createOrganisationModal";
import VTPDropdown, {
  IDropdownOption,
} from "../components/base/form/vtpDropdown";
import { useVTPCloud } from "../context/vtpCloud-context";
import { Tenant, TenantLicense, TokenAssignment, User } from "../lib/apiModels";
import VTPTextInput, { BorderType } from "../components/base/form/vtpTextInput";
import VTPStyles from "../styles/vtpStyles";
import VTPCard from "../components/base/vtpCard";
import VTPInputHeader from "../components/base/vtpInputHeader";
import CommonUtilities from "../lib/common";
import VTPContextMenu from "../components/base/form/vtpContextMenu";
import VtpTable from "../components/base/vtpTable";
import VTPCheckbox from "../components/base/form/vtpCheckbox";
import { useDataPagination } from "../hooks/usePagination";
import VTPPagination from "../components/base/vtpPagination";
import { AlertType, useAlert } from "../hooks/useAlert";
import VTPConfirmationModal from "../components/modal/vtpConfirmationModal";
import LicenseModal from "../components/support/licenseModal";
import TokenModal from "../components/support/tokenModal";
import OrganisationInfoCard from "../components/support/organisationInfoCard";
import pageSectionStyles from "../components/base/pageSection.module.css";

const SupportPage = () => {
  const navigate = useNavigate();
  const { pushAlert } = useAlert();
  const { getUserProfile, getSelectedTenant } = useAppContext();
  const {
    supportGetTenants,
    supportGetUsers,
    supportDeleteTenant,
    supportDeleteLicense,
  } = useVTPCloud();

  // Modal states
  const [showCreateOrganisation, setShowCreateOrganisation] = useState(false);
  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const [showDeleteOrganizationModal, setShowDeleteOrganizationModal] =
    useState(false);
  const [showDeleteLicenseModal, setShowDeleteLicenseModal] = useState(false);
  const [showEditTokenModal, setShowEditTokenModal] = useState(false);

  // States
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<Tenant>();
  const [selectedLicense, setSelectedLicense] = useState<TenantLicense>();
  const [selectedTokenAssignment, setSelectedTokenAssignment] =
    useState<TokenAssignment>();
  const [userSearchText, setUserSearchText] = useState<string>();

  // If user is not support user navigate to dashboard.
  useEffect(() => {
    if (getUserProfile && !getUserProfile.isSupport) {
      navigate(Config.users);
    }
  }, []);

  useEffect(() => {
    getTenants();
  }, []);

  useEffect(() => {
    if (!selectedTenant) {
        setSelectedTenant(tenants.find(t => t.id === getSelectedTenant?.id));
    }
  }, [tenants]);

  const getTenants = () => {
    supportGetTenants({ limit: -1 }).then((res) => {
      // Set tenant list
      setTenants(res.items);

      // Refresh selected tenant
      const previoslySelectedTenant = res.items.find(
        (t) => t.id === selectedTenant?.id
      );
      setSelectedTenant(previoslySelectedTenant ?? tenants[0]);
    });
  };

  const getTenantDropdownRows = (): IDropdownOption[] | undefined => {
    return tenants
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
      .filter((u) => {
        if (userSearchText) {
          return u.displayName
            .toLowerCase()
            .includes(userSearchText.toLowerCase());
        } else {
          return u;
        }
      })
      .map((tenant, pos) => {
        return { name: tenant.displayName, value: tenant.id };
      });
  };

  const LicenseList = () => {
    return (
      <div className={`${styles.licenseList}`}>
        <VTPButton
          className={`${styles.supportPageButton}`}
          size={ButtonSize.Small}
          type={ButtonType.Secondary}
          onClick={() => {
            setShowLicenseModal(true);
          }}
        >
          <PlusIcon fill={"#0c605a"} />
          Create new subscription
        </VTPButton>
        {selectedTenant?.licenses?.map((license) => {
          return <LicenseInfoCard key={license.id} license={license} />;
        })}
      </div>
    );
  };

  const LicenseCardHeader = (props: { license: TenantLicense }) => {
    return (
      <div className={styles.header}>
        <div style={{ position: "relative" }}>
          <VTPInputHeader
            className={styles.licenseName}
            value={props.license.name}
            readonly={true}
          />
          <div className={styles.licenseReference}>
            {props.license.reference}
          </div>
        </div>
        <div
          className={`
            ${VTPStyles.Typography.Body.Small} 
            ${VTPStyles.Color.Text.SecondaryColor}
        `}
        >
          <div>Status: {props.license.status}</div>
        </div>
        <div
          className={`
            ${VTPStyles.Typography.Body.Small} 
            ${VTPStyles.Color.Text.SecondaryColor}
        `}
        >
          Autorenewal: {props.license.autoRenew ? "Enabled" : "Disabled"}
        </div>
        <div className={`${VTPStyles.Typography.Body.Small}`}>
          <div className={`${VTPStyles.Color.Text.SecondaryColor}`}>
            Licenses
          </div>
          <div className={`${VTPStyles.Color.Text.PrimaryColor}`}>
            {props.license.usedTokenCount}/{props.license.tokenCount}
          </div>
        </div>
        <div className={`${VTPStyles.Typography.Body.Small}`}>
          <div className={`${VTPStyles.Color.Text.SecondaryColor}`}>
            Expiration
          </div>
          <div className={`${VTPStyles.Color.Text.PrimaryColor}`}>
            {CommonUtilities.FormatDate(props.license.expiryDate)}
          </div>
        </div>
        <VTPContextMenu
          alignRight={true}
          onOptionSelected={(option) => {
            if (option.value === "edit") {
              {
                setSelectedLicense(props.license);
                setShowLicenseModal(true);
              }
            }
            if (option.value === "remove") {
              {
                setSelectedLicense(props.license);
                setShowDeleteLicenseModal(true);
              }
            }
          }}
          options={[
            {
              icon: <EditIcon style={{ width: "14px", height: "18px" }} />,
              name: "Edit subscription",
              value: "edit",
            },
            {
              icon: <TrashbinIcon style={{ width: "14px", height: "18px" }} />,
              name: "Remove subscription",
              disabled:
                props.license.tokenAssignments.filter((l) => l.isActive)
                  .length > 0,
              disabledTooltip: "Has active license assignments",
              value: "remove",
            },
          ]}
        />
      </div>
    );
  };

  const LicenseCardTokenAssignments = (props: { license: TenantLicense }) => {
    const [users, setUsers] = useState<User[]>();
    const [tokenAssignments, setTokenAssignments] =
      useState<TokenAssignment[]>();
    const paging = useDataPagination(props.license.tokenAssignments, 8);

    useEffect(() => {
      if (paging.pageData && paging.pageData.length > 0) {
        supportGetUsers({
          limit: -1,
          ids: paging.pageData?.map((p) => p.assignedToUserId),
        }).then((r) => {
          setUsers(r.items);
        });
      }
    }, [paging.pageData]);

    useEffect(() => {
      if (users) {
        setTokenAssignments(paging.pageData);
      }
    }, [users]);

    const getRows = (): ReactNode[][] => {
      return (tokenAssignments ?? []).map((t) => {
        return [
          users?.find((u) => u.id == t.assignedToUserId)?.email,
          t.courseId,
          <VTPCheckbox
            style={{ marginLeft: "45px" }}
            className={styles.readOnlyCheckbox}
            key={t.id}
            checked={t.isActive}
          />,
          <VTPCheckbox
            style={{ marginLeft: "60px" }}
            className={styles.readOnlyCheckbox}
            key={t.id}
            checked={t.autoRenew}
          />,
          CommonUtilities.FormatDate(t.expiryDate),
          <VTPContextMenu
            className={styles.licenseAssignmentContextMenu}
            key={t.id}
            alignRight={true}
            onOptionSelected={(option) => {
              if (option.value === "edit") {
                {
                  setSelectedTokenAssignment(t);
                  setShowEditTokenModal(true);
                }
              }
            }}
            options={[
              {
                icon: <EditIcon style={{ width: "14px", height: "18px" }} />,
                name: "Edit assignment",
                value: "edit",
              },
            ]}
          />,
        ];
      });
    };

    return (
      <div className={styles.licenseTokenList}>
        <VtpTable
          columns={[
            "User",
            "Course",
            "Course Access",
            "Automatic renewal",
            "Expiration date",
            "",
          ]}
          rows={getRows()}
        />
        <VTPPagination
          previousPageEnabled={paging.hasPreviousPage}
          nextPageEnabled={paging.hasNextPage}
          onNextPageSelect={paging.nextPage}
          onPreviousPageSelect={paging.previousPage}
        ></VTPPagination>
      </div>
    );
  };

  const LicenseInfoCard = (props: { license: TenantLicense }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <VTPCard className={styles.licenseCard}>
        <div onClick={() => setIsExpanded(!isExpanded)}>
          <LicenseCardHeader license={props.license} />
        </div>
        {isExpanded ? (
          <LicenseCardTokenAssignments license={props.license} />
        ) : null}
      </VTPCard>
    );
  };

  return (
    <div className={pageSectionStyles.singleSectionPage}>
      <PageSection
        header={{
          icon: <SupportIcon />,
          text: "Support",
          appendHeader: (
            <VTPButton
              className={styles.supportPageButton}
              size={ButtonSize.Small}
              type={ButtonType.Secondary}
              onClick={() => {
                setShowCreateOrganisation(true);
              }}
            >
              <PlusIcon fill={"#0c605a"} />
              Create new organization
            </VTPButton>
          ),
        }}
        subheader={"Support all clients organizations from this page"}
      >
        <div>
          <VTPTextInput
            className={styles.search}
            borderStyle={BorderType.RoundBorder}
            placeholder={"Search"}
            onChange={(event) => {
              setUserSearchText(event.target.value);
            }}
            prependElement={<SearchIcon />}
          />
          <div className={`${styles.organizationDropdownSection}`}>
            <VTPDropdown
              className={styles.organizationDropdown}
              options={getTenantDropdownRows() ?? []}
              optionSelectedCallback={(val) =>
                setSelectedTenant(tenants.find((t) => t.id == val))
              }
              selectedValue={selectedTenant?.id}
            />
            <VTPButton
              className={styles.deleteOrganizationButton}
              size={ButtonSize.Small}
              type={ButtonType.Primary}
              onClick={() => setShowDeleteOrganizationModal(true)}
            >
              Deactivate organization
            </VTPButton>
          </div>
        </div>
      </PageSection>
      <PageSection subheader={"Organization information"}>
        <OrganisationInfoCard key={selectedTenant?.id} selectedTenant={selectedTenant} onComplete={() => getTenants()}/>
      </PageSection>
      <PageSection
        subheader={`Subscriptions (${selectedTenant?.licenses.length ?? 0})`}
      >
        <LicenseList />
      </PageSection>
      {showDeleteOrganizationModal ? (
        <VTPConfirmationModal
          header={`Deactivate ${selectedTenant?.displayName} organization`}
          bodyText={`You are about to deactivate ${selectedTenant?.displayName} organization.`}
          confirmationButtonText={"Confirm"}
          onCancel={() => setShowDeleteOrganizationModal(false)}
          onCompletionFunction={() => {
            if (selectedTenant) {
              supportDeleteTenant(selectedTenant?.id)
                .then(() => {
                  pushAlert({
                    message: `Successfully deactivated ${selectedTenant.displayName} organization`,
                    type: AlertType.Success,
                  });
                  getTenants();
                })
                .catch(() => {
                  pushAlert({
                    message: `Failed to deactivate ${selectedTenant.displayName} organization`,
                    type: AlertType.Error,
                  });
                })
                .finally(() => setShowDeleteOrganizationModal(false));
            }
          }}
        />
      ) : null}
      {showDeleteLicenseModal ? (
        <VTPConfirmationModal
          header={`Remove ${selectedLicense?.name} subscription`}
          bodyText={`You are about to remove ${selectedLicense?.name} subscription.`}
          confirmationButtonText={"Confirm"}
          onCancel={() => {
            setSelectedLicense(undefined);
            setShowDeleteLicenseModal(false);
          }}
          onCompletionFunction={() => {
            if (selectedLicense && selectedTenant) {
              supportDeleteLicense(selectedLicense.id, selectedTenant.id)
                .then(() => {
                  pushAlert({
                    message: `Successfully removed ${selectedLicense?.name} license`,
                    type: AlertType.Success,
                  });
                  getTenants();
                })
                .catch(() => {
                  pushAlert({
                    message: `Failed to remove ${selectedLicense?.name} license`,
                    type: AlertType.Error,
                  });
                })
                .finally(() => {
                  setSelectedLicense(undefined);
                  setShowDeleteLicenseModal(false);
                });
            }
          }}
        />
      ) : null}
      {showLicenseModal ? (
        <LicenseModal
          tenant={selectedTenant!}
          license={selectedLicense}
          onCancel={() => {
            setShowLicenseModal(false);
            setSelectedLicense(undefined);
          }}
          onConfirm={() => {
            setShowLicenseModal(false);
            setSelectedLicense(undefined);
            getTenants();
          }}
        />
      ) : null}
      {showCreateOrganisation ? (
        <CreateOrganisationModal
          onCancel={() => setShowCreateOrganisation(false)}
        />
      ) : null}
      {showEditTokenModal && selectedTokenAssignment && selectedTenant ? (
        <TokenModal
          tenant={selectedTenant}
          tokenAssignment={selectedTokenAssignment}
          onCancel={() => {
            setSelectedTokenAssignment(undefined);
            setShowEditTokenModal(false);
          }}
          onConfirm={() => {
            setSelectedTokenAssignment(undefined);
            setShowEditTokenModal(false);
            getTenants();
          }}
        />
      ) : null}
    </div>
  );
};

export default SupportPage;
