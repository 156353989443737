import React, { useState } from "react";
import styles from "./editUserFile.module.css";
import VTPSimpleModal from "../modal/vtpSimpleModal";
import { FileSharingOptions, UserFile, UserRoles } from "../../lib/apiModels";
import VTPStyles from "../../styles/vtpStyles";
import VTPTextInput, { BorderType } from "../base/form/vtpTextInput";
import VTPRadioInput, { RadioInputOption } from "../base/form/vtpRadioInput";
import { useAppContext } from "../../context/app-context";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { AlertType, useAlert } from "../../hooks/useAlert";
import { useRoleBasedAccess } from "../../hooks/useRoleBasedAccess";
import modalStyles from "../modal/vtpSimpleModal.module.css";
import CloseButton from "../common/closeButton";
import { useTranslation } from "react-i18next";

interface Props {
  userFile: UserFile;
  onCancel?: () => void;
  onConfirm?: () => void;
  scenarioFile?: boolean;
}

const getFileAndFolderName = (path: string) => {
  const split = path.split("/");
  if (split.length < 2) {
    return { folderName: undefined, fileName: split[0] };
  }

  return {
    fileName: split[split.length - 1],
    folderName: split[split.length - 2],
  };
};
const EditUserFileModal = (props: Props) => {
  const { t } = useTranslation();
  const { getSelectedTenant } = useAppContext();
  const { updateUserFile } = useVTPCloud();
  const { pushAlert } = useAlert();
  const { userHasValidRole } = useRoleBasedAccess();

  const [displayName, setDisplayName] = useState<string>(
    getFileAndFolderName(props.userFile.displayName).fileName
  );
  const [sharingOption, setSharingOption] = useState(
    props.userFile.sharingOption
  );

  const formatUploadName = (file: UserFile) => {
    const folderName = getFileAndFolderName(file.displayName).folderName;
    const formattedFolderName = folderName ? `/${folderName}/` : "";

    return formattedFolderName + displayName;
  };

  const handleConfirm = () => {
    updateUserFile({
      id: props.userFile.id,
      displayName: formatUploadName(props.userFile),
      sharingOption: sharingOption,
    })
      .then(() =>
        pushAlert({
          message: t("filesPage.notifications.fileUpdateSuccess"),
          type: AlertType.Success,
        })
      )
      .catch(() =>
        pushAlert({
          message: t("filesPage.notifications.fileUpdateError"),
          type: AlertType.Error,
        })
      )
      .finally(props.onConfirm);
  };

  const radioInputOptions = (): RadioInputOption[] => {
    const traineeOptions = [
      {
        name: t("filesPage.editFileModal.sharingOption.private"),
        value: FileSharingOptions.Private,
      },
    ];

    const instructorOptions = traineeOptions.concat([
      {
        name: t("filesPage.editFileModal.sharingOption.instructor"),
        value: FileSharingOptions.Instructor,
      },
    ]);

    const adminOptions = instructorOptions.concat([
      {
        name: t("filesPage.editFileModal.sharingOption.everyone", {
          organisationName: getSelectedTenant?.displayName,
        }),
        value: FileSharingOptions.Everyone,
      },
    ]);

    // User has instructor role.
    if (userHasValidRole([UserRoles.RegularUser])) {
      return instructorOptions;
    }

    // User has admin role.
    if (userHasValidRole([UserRoles.Administrator])) {
      return adminOptions;
    }

    // Trainee role.
    return traineeOptions;
  };

  return (
    <VTPSimpleModal>
      <div className={modalStyles.closeButton}>
        <CloseButton onClick={props.onCancel} />
      </div>
      <div className={styles.container}>
        <h2
          className={`${styles.header} ${VTPStyles.Typography.Headers.H2SubheaderLarge} ${VTPStyles.Color.Text.PrimaryColor}`}
        >
          {t("filesPage.editFileModal.header", {
            fileName: getFileAndFolderName(props.userFile.displayName).fileName,
          })}
        </h2>
        <div className={styles.nameInputGroup}>
          <VTPTextInput
            className={styles.nameInput}
            borderStyle={BorderType.RoundBorder}
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <VTPButton
            size={ButtonSize.Large}
            type={ButtonType.Primary}
            onClick={handleConfirm}
          >
            {t("common.inputs.save")}
          </VTPButton>
        </div>
        {
          // Scenario files are always shared to everyone
          props.scenarioFile !== true ? (
            <>
              <label
                className={`${styles.radioInputLabel} ${VTPStyles.Typography.Body.Medium} ${VTPStyles.Color.Text.PrimaryColor}`}
              >
                {t("filesPage.editFileModal.visibilityLabel")}
              </label>
              <VTPRadioInput
                className={`${styles.radioInputs}`}
                name={"visibility"}
                selectedValue={props.userFile.sharingOption}
                onChanged={(e) => setSharingOption(e.value)}
                options={radioInputOptions()}
              />
            </>
          ) : null
        }
      </div>
    </VTPSimpleModal>
  );
};

export default EditUserFileModal;
