import { IdToken, User } from "@auth0/auth0-react";
import { Config } from "../config";
import { IBridgeCredentials } from "./apiModels";

class AuthUtilities {
  public static GetFirstName(idToken: IdToken | User | undefined): string {
    // Given name is first name of the user.
    if (idToken?.given_name) return idToken.given_name!;

    // If given name is not provided parse it from name field which contains user full name.
    if (idToken?.name)
      return idToken.name?.includes(" ")
        ? idToken.name.split(" ")[0]
        : idToken.name!;

    return "Unknown";
  }

  public static StoreBridgeCredentials(credentials: IBridgeCredentials): void {
    sessionStorage.setItem("bridgeCredentials", JSON.stringify(credentials));
  }

  public static ClearBridgeCredentials(): void {
    sessionStorage.removeItem("bridgeCredentials");
  }

  public static StartDeviceCodeInput() {
    // Try to open code window in popup, otherwise redirect
    if (!window.open(Config.oidcDeviceCodeInputUrl))
      this.Redirect(Config.oidcDeviceCodeInputUrl);
  }

  // TODO: Deprecate this once Auth0 integration is completed for all clients
  public static StartLegacyDeviceCodeInput() {
    if (!window.open(Config.oidcLegacyDeviceCodeInputUrl))
      this.Redirect(Config.oidcLegacyDeviceCodeInputUrl);
  }

  public static Redirect(route: string) {
    window.location.href = route;
  }

  public static RedirectNewTab(route: string) {
    window.open(route, "_blank", "noreferrer");
  }
}

export default AuthUtilities;
