import "./App.css";
import React, {useEffect, useState} from "react";
import {
    Route,
    Routes,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";

import {User, withAuth0, WithAuth0Props} from "@auth0/auth0-react";
import {Config} from "./config";

import Courses from "./pages/courses";
import Login from "./pages/login";
import LoginDeviceCode from "./pages/loginDeviceCode";

import MainLayout from "./pages/layout/mainLayout";
import {useVTPCloud} from "./context/vtpCloud-context";
import Users from "./pages/users";
import Common from "./lib/common";
import CommonUtilities from "./lib/common";
import FirstTimeSetupFlow from "./components/flows/firstTimeSetupFlow";
import {useAppContext} from "./context/app-context";
import AlmostThereFlow from "./components/flows/almostThereFlow";
import YourSubscriptions from "./pages/yourSubscriptions";
import AccountSettings from "./pages/accountSettings";
import {useRoleBasedAccess} from "./hooks/useRoleBasedAccess";
import HeaderLayout from "./pages/layout/headerLayout";
import FilesPage from "./pages/filesPage";
import SupportPage from "./pages/support";
import {Tenant} from "./lib/apiModels";
import {useMediaQuery} from "react-responsive";
import MobilePage from "./pages/mobilePage";
import Analytics from "./pages/analytics";

const App: React.FC<WithAuth0Props> = (props: WithAuth0Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {
        checkIn,
        getUserProfile: getUserProfileFromCloud,
        isCheckedIn,
        refreshUserTenants,
    } = useVTPCloud();
    const {userIsAdmin} = useRoleBasedAccess();
    const {
        getUserProfile,
        setUserProfile,
        setSelectedTenant,
        getUserTenants,
        getSelectedTenant,
    } = useAppContext();
    const location = useLocation();
    const {isLoading, getAccessTokenSilently, user} =
        props.auth0;
    const isMobile = useMediaQuery({maxWidth: "767px", type: "screen"});

    useEffect(() => {
        if (!isLoading) {
            // For some reason, isAuthenticated is false when first logging in, even though the access token exchange can be done successfully
            // Instead, we see if we can get an access token, and assume user is authenticated if that is the case
            getAccessTokenSilently().catch(() => {
                if (location.pathname != Config.loginRoute) {
                    navigate(Config.loginRoute);
                }
            });
        }
    }, [isLoading]);

    useEffect(() => {
        if (isCheckedIn && !isLoading) {
            // Populate the tenants list if empty
            if (
                getUserTenants.length < 1 &&
                location.pathname !== Config.firstTimeSetup
            ) {
                refreshUserTenants().then((res) => {
                    if (
                        res.items.length < 1 &&
                        Config.enableDashboardCreateOrganisation
                    ) {
                        // Navigate to setup flow so user can create organisation.
                        CommonUtilities.NavigateToUrlWithRedirectParam(
                            navigate,
                            Config.firstTimeSetup,
                            ""
                        );
                    }
                });
            }

            // Try to get a cached tenant, otherwise select the first one
            if (getUserTenants && getSelectedTenant == undefined) {
                const tenantToUse = tryGetCachedTenant();
                setSelectedTenant(tenantToUse ?? getUserTenants[0]);
            }
        }
    }, [isCheckedIn, isLoading, getUserTenants]);

    function tryGetCachedTenant(): Tenant | undefined {
        const cachedTenantJson = window.localStorage.getItem(
            Config.localStorageTenantKey
        );
        if (cachedTenantJson) {
            const cachedTenant = JSON.parse(cachedTenantJson) as Tenant;
            if (getUserTenants.find((t) => t.id == cachedTenant.id)) {
                return cachedTenant;
            }
        }
    }

    useEffect(() => {
        if (user) {
            getUserProfileFromCloud().then((profile) => setUserProfile(profile));
            performLoginAction(user).catch(console.error);
        }
    }, [user]);

    useEffect(() => {
        if (
            getUserProfile &&
            !getUserProfile.completedFirstTimeSetup &&
            location.pathname != Config.acceptInvitationRoute
        ) {
            const redirectUrl = CommonUtilities.GetRedirectUrlFromParams(
                searchParams,
                undefined
            );

            // Navigate to setup flow if user not set up
            CommonUtilities.NavigateToUrlWithRedirectParam(
                navigate,
                Config.firstTimeSetup,
                redirectUrl ?? location.pathname + location.search
            );
        }
    }, [getUserProfile]);

    const performLoginAction = async (auth0User: User | undefined) => {
        // Otherwise, check in
        await checkIn();
    };

    const [versionWasLogged, setVersionWasLogged] = useState<boolean>(false);

    const logAndRenderVersionTag = () => {
        if (!versionWasLogged) {
            console.log(
                `[VTP Dashboard]\nClient name: ${Config.clientName}\nEnvironment: ${Config.environment}\nVersion: ${Config.versionTag}`
            );
            setVersionWasLogged(true);
        }

        return null;
    };

    return (
        <React.Fragment>
            <Routes>
                {isMobile ? (
                    <Route path={"/*"} element={<MobilePage/>}/>
                ) : (
                    <Route element={<MainLayout/>}>
                        <Route path={Config.analytics} element={<Analytics/>}/>
                        <Route path={Config.dashboardRoute} element={<Courses/>}/>
                        <Route path={Config.users} element={<Users/>}/>
                        <Route
                            path={Config.subscriptions}
                            element={userIsAdmin() ? <YourSubscriptions/> : <Courses/>}
                        />
                        <Route
                            path={Config.support}
                            element={
                                getUserProfile?.isSupport ? <SupportPage/> : <Courses/>
                            }
                        />
                        <Route path={Config.files} element={<FilesPage/>}/>
                        <Route
                            path={Config.accountSettings}
                            element={<AccountSettings/>}
                        />
                    </Route>
                )}
                <Route element={<HeaderLayout/>}>
                    <Route path={Config.almostThere} element={<AlmostThereFlow/>}/>
                    <Route
                        path={Config.loginDeviceCodeRoute}
                        element={<LoginDeviceCode/>}
                    />
                    <Route
                        path={Config.firstTimeSetup}
                        element={<FirstTimeSetupFlow/>}
                    />
                </Route>
                <Route path={Config.loginRoute} element={<Login/>}/>
                <Route path="/*" element={<Login/>}/>
            </Routes>
            {Common.IsLocalhost() ? null : logAndRenderVersionTag()}
        </React.Fragment>
    );
};
export default withAuth0(App);
