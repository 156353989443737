import React, { useEffect, useState } from "react";
import VTPCard from "../base/vtpCard";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import VTPInputHeader from "../base/vtpInputHeader";
import VTPContextMenu from "../base/form/vtpContextMenu";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_pencil.svg";
import VTPStyles from "../../styles/vtpStyles";
import { useRoleBasedAccess } from "../../hooks/useRoleBasedAccess";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { AlertType, useAlert } from "../../hooks/useAlert";
import VTPTextInput from "../base/form/vtpTextInput";
import {
  IErrorResponse,
  ISupportUpdateTenantRequest,
  Tenant,
  TokenDuration,
} from "../../lib/apiModels";

import styles from "./organisationInfoCard.module.css";
import VTPRadioInput from "../base/form/vtpRadioInput";

const OrganisationInfoCard = (props: {
  selectedTenant?: Tenant;
  onComplete: () => any;
}) => {
  const { userIsAdmin } = useRoleBasedAccess();
  const { supportUpdateTenant, isCheckedIn, refreshUserTenants } =
    useVTPCloud();
  const { pushAlert } = useAlert();

  const [isEditMode, setIsEditMode] = useState(false);
  const [companyNameInput, setCompanyNameInput] = useState("");
  const [domainNamesInput, setDomainNamesInput] = useState<string[]>([]);
  const [newDomainNameInput, setNewDomainNameInput] = useState("");
  const [tokenDuration, setTokenDuration] = useState(TokenDuration.Year);

  useEffect(() => {
    if (!isCheckedIn) return;

    if (props.selectedTenant) {
      setCompanyNameInput(props.selectedTenant.displayName);
      setDomainNamesInput(props.selectedTenant.associatedDomains);
      setTokenDuration(props.selectedTenant.tokenDuration);
      setNewDomainNameInput("");
    }
  }, [props.selectedTenant, isCheckedIn]);

  useEffect(() => {
    if (!isEditMode && props.selectedTenant) {
      setCompanyNameInput(props.selectedTenant.displayName);
      setDomainNamesInput(props.selectedTenant.associatedDomains);
      setTokenDuration(props.selectedTenant.tokenDuration);
      setNewDomainNameInput("");
    }
  }, [isEditMode]);

  const saveChanges = () => {
    const updateTenantRequest: ISupportUpdateTenantRequest = {
      id: props.selectedTenant!.id,
      displayName: companyNameInput,
      associatedDomains: domainNamesInput,
      tokenDuration: tokenDuration,
    };

    supportUpdateTenant(updateTenantRequest)
      .then((updatedTenant) => {
        refreshUserTenants().then(() => setIsEditMode(false));
      })
      .catch((error: IErrorResponse) => {
        pushAlert({
          message: "Organization with this company name already exists.",
          type: AlertType.Error,
        });
      })
      .finally(() => {
        setIsEditMode(false);
        props.onComplete();
      });
  };

  const domainIsValid = (domain: string) =>
    domain.match("^(?!-)[A-Za-z0-9-]+([-.]{1}[a-z0-9]+)*.[A-Za-z]{2,6}$") !==
    null;

  const addNewDomain = () => {
    if (domainIsValid(newDomainNameInput)) {
      const newDomainNameList = [...domainNamesInput, newDomainNameInput];
      setDomainNamesInput(newDomainNameList);
      setNewDomainNameInput("");
    }
  };

  const domains = domainNamesInput.map((domainName) => (
    <li key={domainName} className={styles.organisationInfoDomainPill}>
      {domainName}
      {isEditMode && (
        <div
          className={styles.organisationInfoDomainPillCloseButton}
          onClick={() => {
            setDomainNamesInput(
              domainNamesInput.filter((d) => d !== domainName)
            );
          }}
        />
      )}
    </li>
  ));

  return (
    <VTPCard className={`${styles.organisationInfoCard}`}>
      <div className={`${styles.organisationInfoHeader}`}>
        <VTPInputHeader
          value={companyNameInput}
          readonly={!isEditMode}
          onChange={(event) => setCompanyNameInput(event.target.value)}
        />
        {userIsAdmin() && (
          <VTPContextMenu
            className={styles.organisationInfoContextMenu}
            alignRight={true}
            onOptionSelected={(option) => {
              if (option.value === "edit-organization-info") {
                setIsEditMode(true);
              }
            }}
            options={[
              {
                icon: <EditIcon style={{ width: "14px", height: "18px" }} />,
                name: "Edit information",
                value: "edit-organization-info",
                disabled: isEditMode,
              },
            ]}
          />
        )}
      </div>
      <div className={styles.companyDomainsRow}>
        <div
          className={`${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Text.SecondaryColor}`}
        >
          Company Domains
        </div>
        <div>
          <ul className={styles.organisationInfoDomainList}>
            {domains}
            {isEditMode && (
              <li
                key="add"
                className={`${styles.organisationInfoDomainPill} ${styles.editable}`}
              >
                <VTPTextInput
                  className={styles.organisationInfoTextInput}
                  placeholder="Add domain"
                  validationRules={[
                    {
                      validationFunction: domainIsValid,
                      validationErrorMessage: "Invalid domain",
                    },
                  ]}
                  value={newDomainNameInput}
                  onChange={(event) =>
                    setNewDomainNameInput(event.target.value)
                  }
                  onSubmit={() => addNewDomain()}
                />
                <div
                  className={styles.organisationInfoDomainPillAddButton}
                  onClick={() => {
                    addNewDomain();
                  }}
                />
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className={styles.licenseDuration}>
        <div
            className={`${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Text.SecondaryColor}`}
        >
          License duration
        </div>
        <VTPRadioInput
          key={`tokenDurationInput${isEditMode}${props.selectedTenant?.tokenDuration}`}
          disabled={!isEditMode}
          name={"tokeDuration"}
          selectedValue={props.selectedTenant?.tokenDuration}
          onChanged={(e) => setTokenDuration(e.value)}
          options={[
            {
              name: "Year",
              value: TokenDuration.Year,
            },
            {
              name: `Month`,
              value: TokenDuration.Month,
            },
          ]}
        />
      </div>
      {isEditMode && (
        <div className={styles.buttonsRow}>
          <VTPButton
            size={ButtonSize.Small}
            type={ButtonType.Tertiary}
            onClick={() => setIsEditMode(false)}
          >
            Cancel
          </VTPButton>
          <VTPButton
            size={ButtonSize.Small}
            type={ButtonType.Primary}
            onClick={() => saveChanges()}
          >
            Save changes
          </VTPButton>
        </div>
      )}
    </VTPCard>
  );
};

export default OrganisationInfoCard;
