import React, { useEffect } from "react";
import { useRoleBasedAccess } from "../hooks/useRoleBasedAccess";
import { ReactComponent as OrganizationIcon } from "../assets/navigation/organization.svg";
import PageSection from "../components/base/pageSection";
import pageSectionStyles from "../components/base/pageSection.module.css";
import UserList from "../components/users/userList";
import { UserRoles } from "../lib/apiModels";
import { Config } from "../config";
import {useNavigate} from "react-router-dom";
import {useAppContext} from "../context/app-context";
import { useTranslation } from "react-i18next";

const Users = () => {
  const { userHasRequiredRoleOrHigher } = useRoleBasedAccess();
  const { getSelectedTenant } = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (getSelectedTenant && !userHasRequiredRoleOrHigher(UserRoles.RegularUser)) {
      navigate(Config.defaultTraineePage);
    }
  }, [getSelectedTenant]);

  return (
    <div
      className={pageSectionStyles.singleSectionPage}
      style={{ backgroundColor: "#FFFFFF" }}
    >
      {userHasRequiredRoleOrHigher(UserRoles.RegularUser) ? (
        <PageSection
          header={{
            icon: <OrganizationIcon />,
            text: t("usersPage.header"),
          }}
          style={{ backgroundColor: "#FFFFFF" }}
          subheader={t("usersPage.subHeader")}
        >
          <UserList />
        </PageSection>
      ) : null}
    </div>
  );
};

export default Users;
