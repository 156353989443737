import React from "react";
import {User} from "../../lib/apiModels";
import styles from "./userStatusLabel.module.css";
import {useAppContext} from "../../context/app-context";
import VTPTooltip, {TooltipPosition} from "../base/vtpTooltip";
import {useTranslation} from "react-i18next";

const UserStatusLabel = (props: {user?: User, licensed?: boolean, tooltipPosition?: TooltipPosition}) => {
    const {t} = useTranslation();
    const { getSelectedTenant } = useAppContext();
    const isActiveInTenant = props.user?.userTenantRoles.find(ut => ut.tenantId === getSelectedTenant?.id)!= undefined;
    const getStatusLabel = () => {
        if(props.licensed)
            return t("usersPage.status.licensed");
        
        if(isActiveInTenant && props.user?.lastSeen)
            return t("usersPage.status.active");
        else
            return t("usersPage.status.invited");
    }
    
    const getStatusClass = () => {
        if(props.licensed)
            return styles.licensed;
        
        if(isActiveInTenant && props.user?.lastSeen)
            return styles.active;
        else 
            return "";
    }
    
    const getToolTip= (): {text: string, width: number} => {
        if(props.licensed)
            return { text: t("usersPage.tooltips.userLicensed"), width: 132};

        if(isActiveInTenant && props.user?.lastSeen)
            return { text: t("usersPage.tooltips.userActive"), width: 198};
        else
            return { text: t("usersPage.tooltips.userInvited"), width: 202};
    }
    
    
    return (
        <VTPTooltip position={props.tooltipPosition ?? TooltipPosition.Top} tooltip={getToolTip().text} tooltipWidth={getToolTip().width}>
            <div className={`${styles.statusPill} ${getStatusClass()}`}>
                {getStatusLabel()}
            </div>
        </VTPTooltip>
    )
}

export default UserStatusLabel;