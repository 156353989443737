import React from "react";
import styles from "./closeButton.module.css";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

const CloseButton = (props: { onClick?: React.MouseEventHandler }) => {
  return (
    <VTPButton
      className={styles.container}
      size={ButtonSize.Small}
      type={ButtonType.Tertiary}
      onClick={props.onClick}
    >
      <CloseIcon />
    </VTPButton>
  );
};

export default CloseButton;
