import React, { PropsWithChildren } from "react";
import {FileSharingOptions} from "../lib/apiModels";

const UploadManagerContext = React.createContext<Context>({} as Context);

interface Context {
  queueFileUploads: (filesUploadRequests: FileUploadRequest[]) => void;
  fileListChanged: () => void;

  subscribeOnRequestsAdded: (
    eventId: string,
    callback: {
      (requests: FileUploadRequest[]): void;
    }
  ) => void;
  unsubscribeOnRequestsAdded: (eventId: string) => void;

  subscribeOnFileListChanged: (
    eventId: string,
    callback: {
      (): void;
    }
  ) => void;
  unsubscribeOnFileListChanged: (eventId: string) => void;
}

interface FileUploadRequest {
  file: File;
  fileName?: string;
  scenarioId?: string;
  contentType: string;
  sharingOption: FileSharingOptions;
}

const UploadManagerProvider = ({ children }: PropsWithChildren) => {
  const onRequestAddedListeners: {
    eventId: string;
    callback: {
      (requests: FileUploadRequest[]): void;
    };
  }[] = [];

  const onFileListChangedListeners: {
    eventId: string;
    callback: {
      (): void;
    };
  }[] = [];

  const queueFileUploads = (filesUploadRequests: FileUploadRequest[]) => {
    onRequestAddedListeners.forEach((listener) => {
      // Invoke event listener callbacks
      listener.callback(filesUploadRequests);
    });
  };

  const subscribeOnRequestsAdded = (
    eventId: string,
    callback: {
      (requests: FileUploadRequest[]): void;
    }
  ) => {
    unsubscribeEvent(eventId, onRequestAddedListeners);
    onRequestAddedListeners.push({ eventId, callback });
  };

  const unsubscribeOnRequestsAdded = (eventId: string) => {
    unsubscribeEvent(eventId, onRequestAddedListeners);
  };

  const fileListChanged = () => {
    onFileListChangedListeners.forEach((listener) => {
      // Invoke event listener callbacks
      listener.callback();
    });
  };

  const subscribeOnFileListChanged = (
    eventId: string,
    callback: {
      (): void;
    }
  ) => {
    unsubscribeOnFileListChanged(eventId);
    onFileListChangedListeners.push({ eventId, callback });
  };

  const unsubscribeOnFileListChanged = (eventId: string) => {
    unsubscribeEvent(eventId, onFileListChangedListeners);
  };

  const unsubscribeEvent = (
    eventId: string,
    eventList: { eventId: string; callback: { (args: any): void } }[]
  ) => {
    const event = eventList.find((e) => e.eventId === eventId);
    if (event) {
      eventList.splice(eventList.indexOf(event), 1);
    }
  };

  return (
    <UploadManagerContext.Provider
      value={{
        queueFileUploads,
        subscribeOnRequestsAdded,
        subscribeOnFileListChanged,
        unsubscribeOnFileListChanged,
        unsubscribeOnRequestsAdded,
        fileListChanged,
      }}
    >
      {children}
    </UploadManagerContext.Provider>
  );
};

const useUploadManagerContext = () => {
  const context = React.useContext(UploadManagerContext);
  if (context === undefined) {
    throw new Error(
      "useUploadManagerContext must be used within a AppContextProvider"
    );
  }

  return context;
};

export { UploadManagerProvider, useUploadManagerContext };
export type { FileUploadRequest };
