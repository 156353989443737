import React, { useEffect, useState } from "react";
import styles from "./courseList.module.css";
import { Course } from "../../lib/apiModels";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { AlertType, useAlert } from "../../hooks/useAlert";
import CourseCard from "./courseCard";
import TokenState from "./tokenState";
import tableStyles from "../common/table.module.css";
import TableSearchInput from "../base/table/tableSearchInput";
import {useTranslation} from "react-i18next";

interface CourseState {
  course: Course;
  isActive: boolean;
}

const CourseList = () => {
  const { t } = useTranslation();
  const { getCourses } = useVTPCloud();
  const [courses, setCourses] = useState<CourseState[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { pushAlert } = useAlert();

  useEffect(() => {
    getCourses()
      .then((res) =>
        setCourses(res.items.map((c) => ({ course: c, isActive: false })))
      )
      .catch(() => {
        pushAlert({ message: t("coursesPage.notifications.courseLoadError"), type: AlertType.Error });
      });
  }, []);

  const sortCourses = (a: CourseState, b: CourseState) => {
    return a.course.name.localeCompare(b.course.name);
  };

  const updateCourseState = (courseToUpdate: Course, isActive: boolean) => {
    // Find if there's actually a change required
    const courseState = courses.find((c) => c.course.id === courseToUpdate.id);
    if (courseState && courseState.isActive !== isActive) {
      // Proceed with state update
      const updatedCourses = courses.map((courseState) => {
        if (courseState.course.id === courseToUpdate.id) {
          return { ...courseState, isActive: isActive };
        }
        return courseState;
      });
      setCourses(updatedCourses);
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${tableStyles.tableButtons} ${tableStyles.absolute}`}>
        <TokenState remainingView={true}/>
        <TableSearchInput
          key={"search"}
          placeHolder={t("coursesPage.inputs.searchUsers")}
          onChange={(val) => setSearchQuery(val.target.value)}
        />
      </div>
      <div className={styles.courseCardList}>
        {courses?.sort(sortCourses).map((c) => {
          return (
            <CourseCard
              key={c.course.id}
              course={c.course}
              searchQuery={searchQuery}
              hasTokens={(hasTokens) => updateCourseState(c.course, hasTokens)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CourseList;
