import React, { CSSProperties } from "react";
import styles from "./vtpLabel.module.css";

interface LabelProps {
  text: string;
  style?: CSSProperties;
  className?: string;
}

const VTPLabel = (props: LabelProps) => {
  return (
    <div
      className={`${styles.vtpLabelContainer} ${props.className}`}
      style={props.style}
    >
      <label>{props.text}</label>
    </div>
  );
};

export default VTPLabel;
