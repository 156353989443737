import React, { useEffect, useState } from "react";
import VTPFormCard, { VTPFormCardTextInput } from "../base/vtpFormCard";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { AlertType, useAlert } from "../../hooks/useAlert";
import { withAuth0, WithAuth0Props } from "@auth0/auth0-react";
import { useAppContext } from "../../context/app-context";
import { useTranslation } from "react-i18next";

const AccountInformation: React.FC<WithAuth0Props> = (
  props: WithAuth0Props
) => {
  const { t } = useTranslation();
  const { pushAlert } = useAlert();
  const { updateUserProfile } = useVTPCloud();
  const { setUserProfile, getUserProfile } = useAppContext();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [userNameInput, setUserNameInput] = useState<string>("");
  const [emailInput, setEmailInput] = useState<string>("");
  const [nameIsValid, setNameIsValid] = useState(true);

  // Hook when exiting edit mode to revert inputs if cancelled
  useEffect(() => {
    if (!isEditMode) {
      if (getUserProfile) {
        setUserNameInput(getUserProfile.fullName);
        setEmailInput(getUserProfile.email);
      }
    }
  }, [isEditMode]);

  const updateUser = (name: string) => {
    updateUserProfile({ fullName: name })
      .then((response) => {
        setIsEditMode(false);
        pushAlert({
          message: t("accountPage.notifications.nameUpdateSuccess"),
          type: AlertType.Success,
        });

        setUserProfile(response);
      })
      .catch((error) => {
        pushAlert({
          message: t("accountPage.notifications.nameUpdateError"),
          type: AlertType.Error,
        });
      });
  };

  return (
    <VTPFormCard
      title={t("accountPage.accountInfo.header")}
      includeEditButtons={true}
      editButtonCallback={() => {
        setIsEditMode(true);
      }}
      cancelButtonCallback={() => {
        setIsEditMode(false);
      }}
      saveButtonCallback={() => {
        if (getUserProfile) {
          // Validates if name is empty or white spaces.
          const isEmptyName = /^\s*$/.test(userNameInput);
          updateUser(isEmptyName ? getUserProfile.email : userNameInput);
        }
      }}
      saveButtonDisabled={!nameIsValid}
      editMode={isEditMode}
      formCardRows={[
        {
          left: <div>{t("accountPage.accountInfo.name")}</div>,
          right: (
            <VTPFormCardTextInput
              value={userNameInput}
              placeholder={t("accountPage.inputs.typeHere")}
              onChange={(event) => setUserNameInput(event.target.value)}
              validationRules={[
                {
                  validationFunction: (input) => {
                    const isValid = input.length <= 23;
                    setNameIsValid(isValid);
                    return isValid;
                  },
                  validationErrorMessage: t(
                    "accountPage.validation.nameLength"
                  ),
                },
                {
                  // Define a regular expression pattern that matches characters that are not letters, digits, or whitespace
                  validationFunction: (input) => {
                    const isValid = !/[^a-zA-Z0-9\s@._]/.test(input);
                    setNameIsValid(isValid);
                    return isValid;
                  },
                  validationErrorMessage: t(
                    "accountPage.validation.allowedSymbols"
                  ),
                },
              ]}
              validateOnValueChange={true}
              readonly={!isEditMode}
            />
          ),
        },
        {
          left: <div>{t("accountPage.accountInfo.email")}</div>,
          right: <VTPFormCardTextInput value={emailInput} readonly={true} />,
        },
      ]}
    />
  );
};

export default withAuth0(AccountInformation);
