import { Spinner } from "react-bootstrap";
import React from "react";
import { StylingProps } from "../../lib/common";

interface Props extends StylingProps {
  small?: boolean;
  dark?: boolean;
}

const VTPLoadingSpinner = (props: Props) => {
  return (
    <Spinner
      as="span"
      animation="border"
      role="status"
      aria-hidden="true"
      size={props.small ? "sm" : undefined}
      style={props.style}
      className={`vtp-spinner-dark ${props.className ?? ""} ${
        props.dark ? "" : "text-light"
      }`}
    />
  );
};

export default VTPLoadingSpinner;
