import React, { useEffect, useState } from "react";
import styles from "./vtpCheckbox.module.css";
import { StylingProps } from "../../../lib/common";

interface CheckboxProps extends StylingProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

const VTPCheckbox = (props: CheckboxProps) => {
  const [checked, setChecked] = useState(false);
  const handleChange = (value: boolean) => {
    setChecked(value);
    props.onChange?.(value);
  };

  useEffect(() => {
    if (props.checked !== undefined) {
      setChecked(props.checked);
    }
  }, [props.checked]);

  return (
    <div className={`${props.className}`} style={props.style}>
      <input
        checked={props.checked}
        onChange={() => handleChange(!checked)}
        className={styles.vtpCheckbox}
        type={"checkbox"}
      />
    </div>
  );
};

export default VTPCheckbox;
export type { CheckboxProps };
