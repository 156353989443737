import { UserRoles } from "../lib/apiModels";
import { useAppContext } from "../context/app-context";

const useRoleBasedAccess = () => {
  const { getCurrentRole } = useAppContext();

  const userHasValidRole = (allowedRoles: UserRoles[]) => {
    return isRoleAllowed(getCurrentRole(), allowedRoles);
  };

  const userHasRequiredRoleOrHigher = (targetRole: UserRoles) => {
    const currentRoleValue = getUserRoleValue(
      getCurrentRole() || UserRoles.Guest
    );
    const targetRoleValue = getUserRoleValue(targetRole);
    return currentRoleValue >= targetRoleValue;
  };

  const userIsAdmin = () => {
    return userHasValidRole([UserRoles.Administrator]);
  };

  return { userHasValidRole, userIsAdmin, userHasRequiredRoleOrHigher };
};

// Handling legacy api string enum output to numeric values.
// This is not great... changing api to return numeric value will break VTP app.
const getUserRoleValue = (role: UserRoles) => {
  switch (role) {
    case UserRoles.Guest:
      return 100;
    case UserRoles.RegularUser:
      return 500;
    case UserRoles.Administrator:
      return 1000;
    default:
      return 0; // default value for unknown roles
  }
};

function isRoleAllowed(
  currentRole: UserRoles | undefined,
  allowedRoles: UserRoles[]
): boolean | undefined {
  if (!currentRole) {
    return undefined;
  }

  return allowedRoles.includes(currentRole);
}

export { useRoleBasedAccess, isRoleAllowed };
