import { LicenseStatus, Tenant, TenantLicense } from "../../lib/apiModels";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { AlertType, useAlert } from "../../hooks/useAlert";
import React, { useState } from "react";
import VTPSimpleModal from "../modal/vtpSimpleModal";
import styles from "./licenseModal.module.css";
import VTPStyles from "../../styles/vtpStyles";
import VTPTextInput, { BorderType } from "../base/form/vtpTextInput";
import VTPNumericInput from "../base/form/vtpNumericInput";
import VTPToggleSwitch from "../base/form/vtpToggleSwitch";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";

const LicenseModal = (props: {
  onCancel?: () => void;
  onConfirm?: () => void;
  tenant: Tenant;
  license?: TenantLicense;
}) => {
  const { supportPostLicense, supportUpdateLicense } = useVTPCloud();
  const { pushAlert } = useAlert();

  // Create subscription inputs
  const [name, setName] = useState<string>(props.license?.name ?? "");
  const [reference, setReference] = useState<string>(
    props.license?.reference ?? ""
  );
  const [expirationDate, setExpirationDate] = useState<Date>(
    props.license?.expiryDate
      ? new Date(props.license.expiryDate)
      : new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  );
  const [totalLicenses, setTotalLicenses] = useState<number>(
    props.license?.tokenCount ?? 1
  );
  const [autoRenew, setAutoRenew] = useState<boolean>(
    props.license?.autoRenew ?? true
  );

  // Update subscription inputs (includes all create subscription inputs)
  const [status, setStatus] = useState<LicenseStatus>(
    props.license?.status ?? LicenseStatus.Active
  );
  const [usedLicenses, setUsedLicenses] = useState<number>(
    props.license?.usedTokenCount ?? 0
  );
  const isUpdateMode = props.license !== undefined;

  const postLicense = () => {
    supportPostLicense(props.tenant.id, {
      name: name,
      autoRenew: autoRenew,
      expiryDate: expirationDate,
      reference: reference,
      tokenCount: totalLicenses,
    })
      .then(() => {
        pushAlert({
          message: `Successfully created subscription on ${props.tenant.displayName} organization`,
          type: AlertType.Success,
        });
      })
      .catch(() => {
        pushAlert({
          message: `Failed to create subscription on ${props.tenant.displayName} organization`,
          type: AlertType.Error,
        });
      })
      .finally(props.onConfirm);
  };

  const updateLicense = () => {
    supportUpdateLicense(props.tenant.id, {
      licenseId: props.license!.id,
      name: name,
      autoRenew: autoRenew,
      expiryDate: expirationDate,
      reference: reference,
      tokenCount: totalLicenses,
      status: status,
      usedTokenCount: usedLicenses,
    })
      .then(() => {
        pushAlert({
          message: `Successfully updated subscription on ${props.tenant.displayName} organization`,
          type: AlertType.Success,
        });
      })
      .catch(() => {
        pushAlert({
          message: `Failed to update subscription on ${props.tenant.displayName} organization`,
          type: AlertType.Error,
        });
      })
      .finally(props.onConfirm);
  };

  const confirmPressed = () => {
    if (isUpdateMode) {
      updateLicense();
    } else {
      postLicense();
    }
  };

  return (
    <VTPSimpleModal>
      <div className={styles.createSubscriptionModal}>
        <h2
          className={`${VTPStyles.Typography.Headers.H2SubheaderSmall} ${VTPStyles.Color.Text.PrimaryColor}`}
        >
          {isUpdateMode ? "Update" : "Create new"} subscription
        </h2>
        <VTPTextInput
          className={styles.modalInput}
          borderStyle={BorderType.RoundBorder}
          placeholder={"License name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <VTPTextInput
          className={styles.modalInput}
          borderStyle={BorderType.RoundBorder}
          placeholder={"Reference name"}
          value={reference}
          onChange={(e) => setReference(e.target.value)}
        />
        <div className={styles.modalInputGroup}>
          <div>Expiration date</div>
          <input
            min="1899-01-01"
            max="5000-01-01"
            className={styles.datePicker}
            onChange={(e) => {
              const parsedValue = new Date(e.target.value);
              if (!isNaN(parsedValue.getTime())) {
                setExpirationDate(parsedValue);
              }
            }}
            type="date"
            value={expirationDate?.toISOString().substring(0, 10)}
          />
        </div>

        {/*License count*/}
        <div className={styles.licenseCountGroup}>
          {isUpdateMode ? (
            <div className={styles.modalInputGroup}>
              <div className={`${VTPStyles.Typography.Body.Medium}`}>
                Used licenses
              </div>
              <VTPNumericInput
                value={usedLicenses}
                onChange={(val) => setUsedLicenses(val)}
              />
            </div>
          ) : null}
          <div className={styles.modalInputGroup}>
            <div className={`${VTPStyles.Typography.Body.Medium}`}>
              Total licenses
            </div>
            <VTPNumericInput
              value={totalLicenses}
              onChange={(val) => setTotalLicenses(val)}
            />
          </div>
        </div>

        {/*State toggles*/}
        <div className={styles.modalInputGroup}>
          <div className={`${VTPStyles.Typography.Body.Medium}`}>
            Automatic renewal
          </div>
          <VTPToggleSwitch
            checked={autoRenew}
            onChange={(val) => setAutoRenew(val)}
          />
        </div>
        {isUpdateMode ? (
          <div className={styles.modalInputGroup}>
            <div className={`${VTPStyles.Typography.Body.Medium}`}>
              Subscription status
            </div>
            <VTPToggleSwitch
              checked={status == LicenseStatus.Active}
              onChange={(val) =>
                setStatus(val ? LicenseStatus.Active : LicenseStatus.Inactive)
              }
            />
          </div>
        ) : null}
        <div className={styles.modalButtons}>
          <VTPButton
            size={ButtonSize.Medium}
            type={ButtonType.Tertiary}
            onClick={props.onCancel}
          >
            Cancel
          </VTPButton>
          <VTPButton
            size={ButtonSize.Medium}
            type={ButtonType.Primary}
            onClick={() => confirmPressed()}
          >
            Confirm
          </VTPButton>
        </div>
      </div>
    </VTPSimpleModal>
  );
};

export default LicenseModal;
