import React, { useEffect, useState } from "react";
import { IPaginatedResponse, IPagingOptions } from "../lib/apiModels";
import { useVTPCloud } from "../context/vtpCloud-context";

interface PagingInfo {
    CurrentPage: number;
    TotalPages: number;
    TotalItems: number;
}

export interface PagingHandles<T> {
  pageData: T[] | undefined;
  previousPage: () => void;
  nextPage: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  reset: () => void;
}

interface UseEndpointPaginationPagingHandles<T, P extends IPagingOptions>
  extends PagingHandles<T> {
  options: P;
  setOptions: React.Dispatch<React.SetStateAction<P>>;
  isLoading: boolean;
  reloadPage: () => void;
  pagingInfo: PagingInfo;
  
  // Useful to invalidate old UI based on last loaded data.
  lastLoadedTimestamp: number;
}

function useEndpointPagination<T, P extends IPagingOptions>(
  endpoint: (pagingOptions?: P) => Promise<IPaginatedResponse<T>>,
  itemsPerPage: number,
  pagingOptions?: P
): UseEndpointPaginationPagingHandles<T, P> {
  const defaultPagingOptions = (): P => {
    const pagingOptions: IPagingOptions = {};
    return pagingOptions as P;
  };

  // Internal
  const [currentPage, setCurrentPage] = useState(0);
  const [cachedData, setCachedData] = useState<IPaginatedResponse<T>[]>([]);
  
  // Public
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageData, setPageData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastLoadedTimestamp, setLastLoadedTimestamp] = useState(new Date().getTime());
  const [pagingInfo, setPagingInfo] = useState<PagingInfo>({TotalPages: 0, CurrentPage: 0, TotalItems: 0})
  const [options, setOptions] = useState<P>(
    pagingOptions ?? defaultPagingOptions()
  );
  
  // Triggers when component is loaded due options getting initalised for the first time.
  // Triggers when options changes.
  useEffect(() => {
    reset();
  }, [options]);

  useEffect(() => {
      setPageData(cachedData[currentPage]?.items);
      setHasNextPage(!!cachedData[currentPage]?.continueToken);
      setHasPreviousPage(!!cachedData[currentPage - 1]);
      preloadNextPage();
      setLastLoadedTimestamp(new Date().getTime());
      updatePagingInfo(cachedData[currentPage]);
  }, [cachedData, currentPage]);

  const preloadNextPage = () => {
    if (
      cachedData[currentPage]?.continueToken &&
      !cachedData[currentPage + 1]
    ) {
      endpoint({
        ...options,
        limit: itemsPerPage,
        continueToken: cachedData[currentPage].continueToken,
      }).then((page) => {
        cachedData[currentPage + 1] = page;
        setCachedData(cachedData.map((i) => i));
      });
    }
  };

  const reset = () => {
    setCurrentPage(0);
    setIsLoading(true);
    endpoint({
      ...options,
      limit: itemsPerPage,
    })
      .then((page) => {
        setCachedData([page]);
      })
      .finally(() => setIsLoading(false));
  };

  const reloadPage = () => {
    setIsLoading(true);
    endpoint({
      ...options,
      limit: itemsPerPage,
      continueToken: cachedData[currentPage-1]?.continueToken,
    }).then((page) => {
      cachedData[currentPage] = page;
      setCachedData(cachedData.map((i) => i));
    });
    
    setIsLoading(false);
  }

  const updatePagingInfo = (response?: IPaginatedResponse<T>) => {
    const totalCount = response?.totalCount ?? 1;
    const limit = itemsPerPage ?? 1;
    
    // Current page is used internally for checking cache array, starts at 0.
    let curentPageVal = currentPage + 1;
    const totalPages = Math.ceil(totalCount / limit);
    if(totalPages === 0)
      curentPageVal = 0;
    
    setPagingInfo({CurrentPage: curentPageVal, TotalPages: totalPages, TotalItems: response?.totalCount ?? 0});
  }
  
  const nextPage = () => setCurrentPage(currentPage + 1);
  const previousPage = () => setCurrentPage(Math.max(currentPage - 1, 0));

  return {
    pageData,
    previousPage,
    nextPage,
    hasNextPage,
    hasPreviousPage,
    reset,
    reloadPage,
    isLoading,
    setOptions,
    options,
    pagingInfo,
    lastLoadedTimestamp
  };
}

function useEndpointListPagination<T>(
  endpoint: (id: string) => Promise<T>,
  ids: string[],
  itemsPerPage: number
): PagingHandles<T> {
  // Internal
  const [currentPage, setCurrentPage] = useState(0);
  const [cachedData, setCachedData] = useState<T[][]>([]);
  const { isCheckedIn } = useVTPCloud();
  const getOffset = () => currentPage * itemsPerPage;
  const getLimit = () => Math.min(itemsPerPage, ids.length - getOffset());

  // Public
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageData, setPageData] = useState<T[]>([]);

  const itemsLeftNextPage = () => {
    return getOffset() + getLimit() < ids.length;
  };

  useEffect(() => {
    if (!isCheckedIn) return;

    setHasNextPage(false);
    loadPage(getOffset(), getLimit()).then((items) => {
      setHasNextPage(itemsLeftNextPage);
      cachedData[currentPage] = items;
      setPageData(cachedData[currentPage]);
    });
  }, [isCheckedIn]);

  useEffect(() => {
    setPageData(cachedData[currentPage]);
    setHasNextPage(itemsLeftNextPage);
    setHasPreviousPage(!!cachedData[currentPage - 1]);
  }, [cachedData, currentPage]);

  useEffect(() => {
    preloadNextPage();
  }, [currentPage]);

  const preloadNextPage = () => {
    if (!cachedData[currentPage + 1]) {
      const nextPageOffset = getOffset() + itemsPerPage;
      const nextPageLimit = Math.min(itemsPerPage, ids.length - nextPageOffset);
      if (nextPageLimit > 0) {
        setHasNextPage(false);
        loadPage(nextPageOffset, nextPageLimit).then((items) => {
          cachedData[currentPage + 1] = items;
          setHasNextPage(itemsLeftNextPage);
        });
      }
    }
  };

  const loadPage = async (offset: number, limit: number): Promise<T[]> => {
    const items = new Array<T>();
    for (let i = offset; i < offset + limit; i++) {
      await endpoint(ids[i]).then((item) => {
        items.push(item);
      });
    }

    return items;
  };

  const reset = () => {
    setCurrentPage(0);
    setCachedData([]);
    loadPage(getOffset(), getLimit()).then((items) => {
      setCachedData([items]);
    });
  };

  const nextPage = () => setCurrentPage(currentPage + 1);
  const previousPage = () => setCurrentPage(Math.max(currentPage - 1, 0));

  return {
    pageData,
    previousPage,
    nextPage,
    hasNextPage,
    hasPreviousPage,
    reset,
  };
}

function useDataPagination<T>(
  data: T[],
  itemsPerPage: number
): PagingHandles<T> {
  // Internal
  const [currentPage, setCurrentPage] = useState(0);
  const [cachedData, setCachedData] = useState<T[][]>([]);

  // Public
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [pageData, setPageData] = useState<T[]>([]);

  useEffect(() => {
    setCachedData(chunkArray(data, itemsPerPage));
  }, [data, itemsPerPage]);

  useEffect(() => {
    if (!pageData && hasPreviousPage) {
      previousPage();
    }
  }, [pageData]);

  useEffect(() => {
    setPageData(cachedData[currentPage]);
    setHasNextPage(!!cachedData[currentPage + 1]);
    setHasPreviousPage(!!cachedData[currentPage - 1]);
  }, [cachedData, currentPage]);

  const nextPage = () => setCurrentPage(currentPage + 1);
  const previousPage = () => setCurrentPage(Math.max(currentPage - 1, 0));
  const reset = () => setCurrentPage(0);

  return {
    pageData,
    previousPage,
    nextPage,
    hasNextPage,
    hasPreviousPage,
    reset,
  };
}

function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  let chunks: T[][] = [];
  if (array) {
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      chunks = [...chunks, chunk];
    }
  }
  return chunks;
}

export { useDataPagination, useEndpointPagination, useEndpointListPagination };
