import React, { useState } from "react";
import VTPSimpleModal from "../modal/vtpSimpleModal";
import styles from "./tokenModal.module.css";
import VTPStyles from "../../styles/vtpStyles";
import VTPToggleSwitch from "../base/form/vtpToggleSwitch";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { Tenant, TokenAssignment } from "../../lib/apiModels";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { AlertType, useAlert } from "../../hooks/useAlert";

const TokenModal = (props: {
  onCancel?: () => void;
  onConfirm?: () => void;
  tenant: Tenant;
  tokenAssignment: TokenAssignment;
}) => {
  const { supportUpdateTokenAssignment } = useVTPCloud();
  const { pushAlert } = useAlert();

  // States
  const [expirationDate, setExpirationDate] = useState<Date>(
    new Date(props.tokenAssignment.expiryDate)
  );
  const [autoRenew, setAutoRenew] = useState<boolean>(
    props.tokenAssignment.autoRenew
  );
  const [isActive, setIsActive] = useState<boolean>(
    props.tokenAssignment.isActive
  );

  const updateToken = () => {
    supportUpdateTokenAssignment(props.tenant.id, {
      id: props.tokenAssignment.id,
      autoRenew: autoRenew,
      expiryDate: expirationDate,
      isActive: isActive,
    })
      .then(() => {
        pushAlert({
          message: `Successfully updated license assignment on ${props.tenant.displayName} organization`,
          type: AlertType.Success,
        });
      })
      .catch(() => {
        pushAlert({
          message: `Failed to update license assignment on ${props.tenant.displayName} organization`,
          type: AlertType.Error,
        });
      })
      .finally(props.onConfirm);
  };

  return (
    <VTPSimpleModal>
      <div className={styles.container}>
        <h2 className={`${VTPStyles.Typography.Headers.H2SubheaderSmall}`}>
          Edit license assignment
        </h2>

        <div className={styles.modalInputGroup}>
          <div>Expiration date</div>
          <input
            className={styles.datePicker}
            min="1899-01-01"
            max="5000-01-01"
            onChange={(e) => {
              const parsedValue = new Date(e.target.value);
              if (!isNaN(parsedValue.getTime())) {
                setExpirationDate(parsedValue);
              }
            }}
            type="date"
            value={expirationDate?.toISOString().substring(0, 10)}
          />
        </div>
        <div className={styles.modalInputGroup}>
          <div className={`${VTPStyles.Typography.Body.Medium}`}>
            Automatic renewal
          </div>
          <VTPToggleSwitch
            checked={autoRenew}
            onChange={(val) => setAutoRenew(val)}
          />
        </div>
        <div className={styles.modalInputGroup}>
          <div className={`${VTPStyles.Typography.Body.Medium}`}>
            Course access
          </div>
          <VTPToggleSwitch
            checked={isActive}
            onChange={(val) => setIsActive(val)}
          />
        </div>
        <div className={styles.modalButtons}>
          <VTPButton
            size={ButtonSize.Medium}
            type={ButtonType.Tertiary}
            onClick={props.onCancel}
          >
            Cancel
          </VTPButton>
          <VTPButton
            size={ButtonSize.Medium}
            type={ButtonType.Primary}
            onClick={() => updateToken()}
          >
            Confirm
          </VTPButton>
        </div>
      </div>
    </VTPSimpleModal>
  );
};

export default TokenModal;
