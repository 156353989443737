import React, {useEffect, useMemo, useState} from "react";
import VTPTable from "../base/vtpTable";
import {useEndpointPagination} from "../../hooks/usePagination";
import {useVTPCloud} from "../../context/vtpCloud-context";
import {
  GetUserRole,
  GetUserRoles,
  IDeleteUserRoleRequest,
  IUpdateUserRoleRequest,
  User,
  UserRoles,
  UserSortingKey,
} from "../../lib/apiModels";
import VTPSortableTableHeader, {SortingState, useSortingStateMachine,} from "../base/vtpSortableTableHeader";
import VTPButton, {ButtonSize, ButtonType} from "../base/button";
import styles from "./userList.module.css";
import tableStyles from "../common/table.module.css";
import VTPDropdown, {IDropdownOption} from "../base/form/vtpDropdown";
import {useAppContext} from "../../context/app-context";
import CommonUtilities from "../../lib/common";
import {ReactComponent as TrashbinIcon} from "../../assets/trashbin.svg";
import {ReactComponent as LockIcon} from "../../assets/icons/lock.svg";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus.svg";
import TableSearchInput from "../base/table/tableSearchInput";
import TableButton from "../base/table/tableButton";
import TablePagination from "../base/table/tablePagination";
import {AlertType, useAlert} from "../../hooks/useAlert";
import VTPSimpleConfirmationModal from "../modal/vtpSimpleConfirmationModal";
import InviteUsersModal from "../modal/inviteUsersModal";
import {useRoleBasedAccess} from "../../hooks/useRoleBasedAccess";
import VTPTooltip, {TooltipPosition} from "../base/vtpTooltip";
import UserStatusLabel from "../common/userStatusLabel";
import UserAnalyticsButton from "../analytics/userAnalyticsButton";
import {useTranslation} from "react-i18next";

const UserList = () => {
  const { t, i18n } = useTranslation();
  const { getUsers, deleteUserRole, updateUserRole, refreshUserTenants } =
    useVTPCloud();
  const { pushAlert } = useAlert();
  const { getSelectedTenant } = useAppContext();
  const { userIsAdmin } = useRoleBasedAccess();

  const sortingStateMachine = useSortingStateMachine(UserSortingKey.Email);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const userRoles = useMemo<IDropdownOption[]>(() => GetUserRoles(t), [i18n.language]);
  const paging = useEndpointPagination(getUsers, 10, {
    sort: `${sortingStateMachine.state.value}:${sortingStateMachine.state.sortingState}`,
    search: searchQuery,
  });
  
  // Quick and easy solution to figure out if tenant has only 1 admin left
  const [tenantHasOneAdmin, setTenantHasOneAdmin] = useState(false);

  useEffect(() => {
    // Check if tenant has only 1 admin.
    checkIfTenantHasOnlyOneAdmin();
    
    paging.reset();
  }, [getSelectedTenant]);
  
  const checkIfTenantHasOnlyOneAdmin = () => {
    getUsers({limit: 2, sort: `${UserSortingKey.Role}:${SortingState.Desc}`}).then(
        (res) => {
          const admins = res.items.filter(i =>
              i.userTenantRoles.some(ut => ut.tenantId === getSelectedTenant?.id && ut.role === UserRoles.Administrator)
          );
          setTenantHasOneAdmin(admins.length === 1);
        }
    );
  }

  // States for modal actions
  const [showInviteUsers, setShowInviteUsers] = useState(false);
  const [removeUser, setRemoveUser] = useState<User>();
  const [updateUserRoleState, setUpdateUserRoleState] = useState<{
    user: User;
    oldRole: UserRoles;
    newRole: UserRoles;
  }>();

  // Sorting
  useEffect(() => {
    const sortingParam = `${sortingStateMachine.state.value}:${sortingStateMachine.state.sortingState}`;
    if (paging.options.sort !== sortingParam) {
      paging.setOptions({
        ...paging.options,
        sort: sortingParam,
      });
    }
  }, [sortingStateMachine.state]);

  // Search query with delay
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (paging.options.search !== searchQuery) {
        paging.setOptions({
          ...paging.options,
          search: searchQuery,
        });
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const columnHeaders = [
    <VTPSortableTableHeader
      key={UserSortingKey.Email}
      text={t("usersPage.tableColumns.email")}
      value={UserSortingKey.Email}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={UserSortingKey.UserName}
      text={t("usersPage.tableColumns.userName")}
      value={UserSortingKey.UserName}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={UserSortingKey.Role}
      text={t("usersPage.tableColumns.role")}
      value={UserSortingKey.Role}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={UserSortingKey.Status}
      text={t("usersPage.tableColumns.status")}
      value={UserSortingKey.Status}
      stateMachine={sortingStateMachine}
    />,
    // User Created
    <VTPSortableTableHeader
      key={UserSortingKey.UserCreated}
      text={t("usersPage.tableColumns.userCreated")}
      value={UserSortingKey.UserCreated}
      stateMachine={sortingStateMachine}
    />,
    <VTPSortableTableHeader
      key={UserSortingKey.LastActive}
      text={t("usersPage.tableColumns.lastActive")}
      value={UserSortingKey.LastActive}
      stateMachine={sortingStateMachine}
    />,
    null,
  ];

  const rows = (): any[][] => {
    if (!paging.pageData || !getSelectedTenant) return [];

    return paging.pageData.map((u) => {
      const isActiveInTenant =
        u.userTenantRoles.find((ut) => ut.tenantId === getSelectedTenant.id) !=
        undefined;
      const role = isActiveInTenant
        ? u.userTenantRoles.find((ut) => ut.tenantId === getSelectedTenant.id)
            ?.role
        : u.tenantInvites.find((i) => i.tenantId == getSelectedTenant.id)?.role;
      
      const lastAdminUser = (tenantHasOneAdmin && u.userTenantRoles.some(ut =>
          ut.tenantId === getSelectedTenant.id && ut.role === UserRoles.Administrator))

      return [
        <UserAnalyticsButton key={u.id} userId={u.id} email={u.email} />,
        u.fullName ?? "-",
        userIsAdmin() && 
        // prevents from showing a dropdown in case if user is last admin.
        !lastAdminUser
            ? (
          <VTPDropdown
            key={u.id + role + paging.lastLoadedTimestamp}
            className={styles.roleDropdown}
            small={true}
            options={userRoles}
            selectedValue={role}
            optionSelectedCallback={(newValue) => {
              if (role && newValue != role) {
                setUpdateUserRoleState({
                  user: u,
                  newRole: newValue as UserRoles,
                  oldRole: role,
                });
              }
            }}
          />
        ) : (
          <div className={styles.userRoleLabel}>{GetUserRole(role, t)}</div>
        ),
        <UserStatusLabel key={u.id} user={u} />,
        CommonUtilities.FormatDate(u.createdAt),
        CommonUtilities.FormatDate(u.lastSeen),
        <div key={u.id}>
          {(userIsAdmin() || role === UserRoles.Guest) && !lastAdminUser ? (
            <VTPButton
              size={ButtonSize.Small}
              type={ButtonType.Tertiary}
              className={`${styles.removeUserButton}`}
              onClick={() => setRemoveUser(u)}
            >
              <TrashbinIcon />
            </VTPButton>
          ) : (
            <VTPTooltip
              position={TooltipPosition.Top}
              tooltip={lastAdminUser && userIsAdmin() ? t("usersPage.tooltips.lastAdmin") : t("usersPage.tooltips.deleteUser")}
              tooltipWidth={185}
            >
              <div className={styles.lockIcon}>
                <LockIcon />
              </div>
            </VTPTooltip>
          )}
        </div>,
      ];
    });
  };

  const updateSelectedUserRole = () => {
    if (updateUserRoleState != null) {
      // Prepare payload
      const updateUserRoleRequest: IUpdateUserRoleRequest = {
        id: updateUserRoleState.user.id,
        role: updateUserRoleState.newRole,
      };

      // Update user-role, pop notification, reload table page.
      updateUserRole(updateUserRoleRequest)
        .then((updatedUser) => {
          pushAlert({
            message: t("usersPage.updateRoleModal.updateNotification"),
            type: AlertType.Success,
          });
        })
        .finally(() => {
          checkIfTenantHasOnlyOneAdmin();
          setUpdateUserRoleState(undefined);
          paging.reloadPage();
        });
    }
  };

  const removeSelectedUser = () => {
    if (removeUser) {
      const deleteUserRequest: IDeleteUserRoleRequest = {
        id: removeUser.id,
      };
      deleteUserRole(deleteUserRequest)
        .then(() => {
          pushAlert({
            message: t("usersPage.removeUserModal.deleteNotification"),
            type: AlertType.Success,
          });
        })
        .finally(() => {
          checkIfTenantHasOnlyOneAdmin();
          refreshUserTenants();
          setRemoveUser(undefined);
          paging.reloadPage();
        });
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${tableStyles.tableButtons} ${tableStyles.absolute}`}>
        <TableSearchInput
          key={"search"}
          placeHolder={t("usersPage.inputs.searchUsers")}
          onChange={(val) => setSearchQuery(val.target.value)}
        />
        <TableButton
          key={"addUser"}
          type={ButtonType.Primary}
          size={ButtonSize.Small}
          onClick={() => setShowInviteUsers(true)}
        >
          <PlusIcon className={styles.plusIcon} />
          {t("usersPage.inputs.addUser")}
        </TableButton>
      </div>
      <VTPTable
        className={styles.userTable}
        columns={columnHeaders}
        rows={rows()}
        isLoading={paging.isLoading}
      />
      <div className={tableStyles.tablePagination}>
        <TablePagination
          currentPage={paging.pagingInfo.CurrentPage}
          totalPages={paging.pagingInfo.TotalPages}
          nextPage={paging.nextPage}
          previousPage={paging.previousPage}
        />
      </div>

      {/* ======== Modal windows ======== */}
      {/* Change user role modal */}
      {updateUserRoleState ? (
        <VTPSimpleConfirmationModal
          header={t("usersPage.updateRoleModal.header", {
            email: updateUserRoleState.user.email,
          })}
          body={
            <div>
              <div>{t("usersPage.updateRoleModal.text1")}</div>
              <div>{t("usersPage.updateRoleModal.text2")}</div>
            </div>
          }
          buttons={[
            <VTPButton
              key={"cancel"}
              size={ButtonSize.Medium}
              type={ButtonType.Tertiary}
              onClick={() => {
                paging.reloadPage();
                setUpdateUserRoleState(undefined);
              }}
            >
              {t("common.inputs.cancel")}
            </VTPButton>,
            <VTPButton
              key={"join"}
              size={ButtonSize.Medium}
              type={ButtonType.Primary}
              onClick={() => updateSelectedUserRole()}
            >
              {t("common.inputs.saveChanges")}
            </VTPButton>,
          ]}
        />
      ) : null}

      {/* User removal modal */}
      {removeUser ? (
        <VTPSimpleConfirmationModal
          header={t("usersPage.removeUserModal.header", {
            email: removeUser.email,
          })}
          body={<div>{t("usersPage.removeUserModal.text1")}</div>}
          buttons={[
            <VTPButton
              key={"cancel"}
              size={ButtonSize.Medium}
              type={ButtonType.Tertiary}
              onClick={() => {
                setRemoveUser(undefined);
              }}
            >
              {t("common.inputs.cancel")}
            </VTPButton>,
            <VTPButton
              key={"remove"}
              size={ButtonSize.Medium}
              type={ButtonType.Primary}
              onClick={() => removeSelectedUser()}
            >
              {t("usersPage.removeUserModal.removeUser")}
            </VTPButton>,
          ]}
        />
      ) : null}

      {/* User invitation modal */}
      {showInviteUsers ? (
        <InviteUsersModal
          onCancel={() => setShowInviteUsers(false)}
          onSave={() => {
            paging.reloadPage();
          }}
        />
      ) : null}
    </div>
  );
};  

export default UserList;
