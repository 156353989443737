import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./firstTimeSetupFlow.module.css";
import flowLayoutStyles from "../../pages/guidedFlowLayout.module.css";
import useStateMachine from "../../hooks/useStateMachine";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import VTPTextInput, { BorderType } from "../base/form/vtpTextInput";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { useAuth0 } from "@auth0/auth0-react";
import { AlertType, useAlert } from "../../hooks/useAlert";
import { Row, Spinner } from "react-bootstrap";
import InviteUsersList, { UserInvite } from "../users/inviteUsersList";
import {
  BridgeErrorCode,
  IErrorResponse,
  User,
} from "../../lib/apiModels";
import { useAppContext } from "../../context/app-context";
import CommonUtilities from "../../lib/common";
import GuidedFlowLayout from "../../pages/guidedFlowLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import VTPStyles from "../../styles/vtpStyles";
import { useTranslation } from "react-i18next";
import InvitationHandler from "../utility/invitationHandler";
import { Config } from "../../config";

enum FlowState {
  Loading = 0,
  SetUserName = 1,
  CreateOrganisation = 2,
}

const FirstTimeSetupFlow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getAccessTokenSilently, user } = useAuth0();
  const {
    updateUserProfile,
    isCheckedIn,
    getTenants,
    refreshUserTenants,
    refreshSelectedTenant,
    refreshUserProfile,
  } = useVTPCloud();
  const { pushAlert } = useAlert();
  const { getUserProfile } = useAppContext();
  const stateMachine = useStateMachine<FlowState>(FlowState.Loading);

  const finishFlow = async () => {
    await refreshUserTenants();
    await refreshSelectedTenant();
    await updateUserProfile({ completedFirstTimeSetup: true });
    await refreshUserProfile();
    navigate(CommonUtilities.GetRedirectUrlFromParams(searchParams));
  };

  const Loading = () => {
    useEffect(() => {
      if (
        stateMachine.state == FlowState.Loading &&
        user != undefined &&
        isCheckedIn &&
        getUserProfile
      ) {
        getTenants().then((tenants) => {
          if (
            CommonUtilities.IsEmail(user.name) &&
            !getUserProfile.completedFirstTimeSetup
          ) {
            // The user has not yet chosen a name
            stateMachine.setState(FlowState.SetUserName);
          } else if (
            tenants.items.length == 0 &&
            Config.enableDashboardCreateOrganisation
          ) {
            // The user is only a member of their private tenant
            stateMachine.setState(FlowState.CreateOrganisation);
          } else {
            // The user was invited to another org and likely won't need to do setup
            finishFlow();
          }
        });
      }
    }, [user, isCheckedIn, getUserProfile]);

    return (
      <>
        <div
          className={"d-flex align-items-center"}
          style={{ height: "500px" }}
        >
          <Spinner animation="border" variant="dark" />
        </div>
      </>
    );
  };

  const SetUserName = () => {
    const [fullNameInput, setFullNameInput] = useState("");
    const [isUpdating, setIsUpdating] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const updateUser = async (name: string) => {
      if (user) {
        const isEmptyName = /^\s*$/.test(name);

        // If provided name is empty set name to e-mail instead.
        await updateUserProfile({ fullName: isEmptyName ? user.email : name });
        await getAccessTokenSilently({ ignoreCache: true }); // Get a new access token, as this may have an updated name included

        // Prompt to create organization if none is available.
        await getTenants().then((tenants) => {
          if (
            tenants.items.length < 1 &&
            Config.enableDashboardCreateOrganisation
          ) {
            stateMachine.setState(FlowState.CreateOrganisation);
          } else {
            finishFlow();
          }
        });
      }
    };

    return (
      <>
        <div className={"d-flex flex-column align-items-center"}>
          <h2 className={`${VTPStyles.Typography.Headers.H2SubheaderLarge}`}>
            {t("firstTimeSetup.nameSetup.text1")}
          </h2>
          <span
            className={`${flowLayoutStyles.guidedFlowText} ${VTPStyles.Typography.Body.Medium} ${VTPStyles.Color.Text.PrimaryColor}`}
          >
            {t("firstTimeSetup.nameSetup.text2")}
          </span>
        </div>
        <Row>
          <h3
            className={`${VTPStyles.Typography.Headers.H3EyebrowSmall} ${VTPStyles.Color.Text.SecondaryColor}`}
          >
            {t("firstTimeSetup.nameSetup.text3")}
          </h3>
          <VTPTextInput
            style={{ marginTop: "9px" }}
            placeholder={t("accountPage.inputs.typeHere")}
            borderStyle={BorderType.RoundBorder}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFullNameInput(event.target.value);
            }}
            validateOnValueChange={true}
            validationRules={[
              {
                validationFunction: (input) => {
                  const isValid = input.length <= 23;
                  setIsValid(isValid);
                  return isValid;
                },
                validationErrorMessage: t("accountPage.validation.nameLength"),
              },
              {
                // Define a regular expression pattern that matches characters that are not letters, digits, or whitespace
                validationFunction: (input) => {
                  const isValid = !/[^a-zA-Z0-9\s@._]/.test(input);
                  setIsValid(isValid);
                  return isValid;
                },
                validationErrorMessage: t(
                  "accountPage.validation.allowedSymbols"
                ),
              },
            ]}
          />
        </Row>
        <div
          className={flowLayoutStyles.guidedFlowButtons}
          style={{ marginRight: "16px" }}
        >
          <VTPButton
            size={ButtonSize.Medium}
            type={ButtonType.Tertiary}
            onClick={() => {
              finishFlow();
            }}
          >
            {t("common.inputs.skip")}
          </VTPButton>
          <VTPButton
            onClick={() => {
              setIsUpdating(true);
              updateUser(fullNameInput);
            }}
            disabled={!isValid || isUpdating}
            size={ButtonSize.Medium}
            type={ButtonType.Primary}
          >
            {t("common.inputs.continue")}
          </VTPButton>
        </div>
      </>
    );
  };

  const CreateOrganisation = () => {
    const [companyNameInput, setCompanyNameInput] = useState<string>("");
    const [userInvites, setUserInvites] = useState<UserInvite[]>([]);
    const [createOrgEnabled, setCreateOrgEnabled] = useState<boolean>(false);
    const [pollInvitations, setPollInvitations] = useState(false);

    useEffect(() => {
      setPollInvitations(true);
    }, []);

    const { postTenant, inviteUser } = useVTPCloud();
    const { setSelectedTenant} = useAppContext();

    const handleCompanyNameInput = (event: ChangeEvent<HTMLInputElement>) => {
      setCompanyNameInput(event.target.value);
    };
    const updateOrganisation = () => {
      postTenant({ displayName: companyNameInput })
        .then((t) => {
          // Make newly created tenant the active one
          setSelectedTenant(t);

          // Inviting users to newly created tenant.
          const invitePromises = Array<Promise<User>>();
          userInvites.forEach(function (inv) {
            invitePromises.push(
              inviteUser({
                email: inv.email,
                role: inv.role,
                tenantId: t.id,
              })
            );
          });

          Promise.all(invitePromises).finally(() => {
            setSelectedTenant(t);
            finishFlow();
          });
        })
        .catch((error: IErrorResponse) => {
          if (error.bridgeErrorCode == BridgeErrorCode.TenantNameNotUnique) {
            pushAlert({
              message: t(
                "firstTimeSetup.organisationSetup.validation.organisationExists"
              ),
              type: AlertType.Error,
            });
          }
        });
    };
    const companyNameInputValidation = (inputValue: string) => {
      if (inputValue) {
        setCreateOrgEnabled(true);
        return true;
      } else {
        setCreateOrgEnabled(false);
        return false;
      }
    };

    return (
      <>
        <div className={"d-flex flex-column align-items-center"}>
          <h2 className={`${VTPStyles.Typography.Headers.H2SubheaderLarge}`}>
            {t("firstTimeSetup.organisationSetup.text1")}
          </h2>
          <span
            className={`${flowLayoutStyles.guidedFlowText} ${VTPStyles.Typography.Body.Medium} ${VTPStyles.Color.Text.PrimaryColor}`}
          >
            {t("firstTimeSetup.organisationSetup.text2")}
          </span>
        </div>
        <div className={styles.guidedFlowCompanyDetails}>
          <h3
            className={`${VTPStyles.Typography.Headers.H3EyebrowSmall} ${VTPStyles.Color.Text.SecondaryColor}`}
          >
            {t("firstTimeSetup.organisationSetup.text3")}
          </h3>
          <VTPTextInput
            style={{ marginTop: "18px" }}
            placeholder={t("firstTimeSetup.organisationSetup.enterCompanyName")}
            borderStyle={BorderType.RoundBorder}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleCompanyNameInput(event)
            }
            // Validates if input is not empty on input focus lost.
            validationRules={[
              {
                validationFunction: companyNameInputValidation,
                validationErrorMessage: t(
                  "firstTimeSetup.organisationSetup.validation.empty"
                ),
              },
            ]}
          />
          <InviteUsersList
            listUpdateCallback={(items) => setUserInvites(items)}
            style={{ marginTop: "34px" }}
            canDoLaterHint={false}
            showLabel={true}
          />
        </div>
        <div
          className={flowLayoutStyles.guidedFlowButtons}
          style={{ marginRight: "16px" }}
        >
          <VTPButton
            disabled={!createOrgEnabled}
            onClick={() => updateOrganisation()}
            size={ButtonSize.Medium}
            type={ButtonType.Primary}
          >
            {t("common.inputs.continue")}
          </VTPButton>
        </div>
        <InvitationHandler
          startPolling={pollInvitations}
          onInvitationAccepted={() => finishFlow()}
        />
      </>
    );
  };

  const RenderState = (state: FlowState | undefined) => {
    switch (state) {
      case FlowState.Loading:
        return <Loading />;
      case FlowState.SetUserName:
        return <SetUserName />;
      case FlowState.CreateOrganisation:
        return <CreateOrganisation />;
      default:
        throw new Error(`Unknown state "${state}"`);
    }
  };

  return (
    <GuidedFlowLayout>
      <div className={styles.setupFlowContainer}>
        {RenderState(stateMachine.state)}
      </div>
    </GuidedFlowLayout>
  );
};

export default FirstTimeSetupFlow;
