import styles from "./vtpModal.module.css";
import React from "react";
import { Container, Modal, Row } from "react-bootstrap";
import { StylingProps } from "../../lib/common";
import { ReactComponent as DangerousIcon } from "../../assets/icons/dangerous.svg";
import { ReactComponent as DescriptionIcon } from "../../assets/icons/description.svg";
import { ReactComponent as AddUser } from "../../assets/icons/add-user.svg";
import { ReactComponent as AssignToken } from "../../assets/icons/briefcase.svg";
import VTPStyles from "../../styles/vtpStyles";

export enum ModalIcon {
  Dangerous = 0,
  Description = 1,
  AddUser = 3,
  AssignToken = 4,
}

interface VTPModalProps extends StylingProps {
  title: string;
  buttons: React.ReactNode[];
  icon?: ModalIcon;
  show?: boolean;
}

/**
 * @deprecated Use VTPSimpleModal instead.
 */
// Implements a basic layout for VTP modals with a title and buttons
const VTPModal = (props: VTPModalProps) => {
  const TitleIcon = () => {
    switch (props.icon) {
      case ModalIcon.Dangerous:
        return (
          <>
            <DangerousIcon
              className={`${styles.vtpModalTitleIcon}`}
            ></DangerousIcon>
            <h2
              className={`${VTPStyles.Typography.Headers.H2SubheaderSmall} ${VTPStyles.Color.Text.PrimaryColor} p-0 m-0`}
            >
              {props.title}
            </h2>
          </>
        );
      case ModalIcon.Description:
        return (
          <>
            <DescriptionIcon
              className={`${styles.vtpModalTitleIcon}`}
            ></DescriptionIcon>
            <h2
              className={`${VTPStyles.Typography.Headers.H2SubheaderSmall} ${VTPStyles.Color.Text.PrimaryColor} p-0 m-0`}
            >
              {props.title}
            </h2>
          </>
        );
      case ModalIcon.AddUser:
        return (
          <>
            <AddUser className={`${styles.vtpModalTitleIcon}`}></AddUser>
            <h2
              className={`${VTPStyles.Typography.Headers.H2SubheaderSmall} ${VTPStyles.Color.Text.PrimaryColor} p-0 m-0`}
            >
              {props.title}
            </h2>
          </>
        );
      case ModalIcon.AssignToken:
        return (
          <>
            <AssignToken
              className={`${styles.vtpModalTitleIcon}`}
            ></AssignToken>
            <h2
              className={`${VTPStyles.Typography.Headers.H2SubheaderSmall} ${VTPStyles.Color.Text.PrimaryColor} p-0 m-0`}
            >
              {props.title}
            </h2>
          </>
        );
    }
    return null;
  };

  const isVisible = (): boolean => {
    if (props.show !== undefined) {
      return props.show;
    }

    return true;
  };

  return (
    <Modal
      className={`${styles.vtpModal} ${props.className ?? ""}`}
      show={isVisible()}
    >
      <Modal.Body className={`show-grid p-0 ${styles.vtpModal}`}>
        <Container>
          <Row className={`${styles.vtpModalTitle}`}>
            {props.icon != null ? (
              <div className={`${styles.vtpModalTitleItems}`}>
                <TitleIcon></TitleIcon>
              </div>
            ) : (
              <h2
                className={`${VTPStyles.Typography.Headers.H2SubheaderSmall} ${VTPStyles.Color.Text.PrimaryColor} p-0 m-0`}
              >
                {props.title}
              </h2>
            )}
          </Row>
          {props.children}
          <Row style={{ marginTop: "30px" }}>
            <div className={`${styles.vtpModalButtons}`}>{props.buttons}</div>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default VTPModal;
