import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "./vtpDropdown.module.css";
import { ReactComponent as DropdownArrow } from "../../../assets/dropdown-arrow.svg";
import { ReactComponent as RadioUnchecked } from "../../../assets/dropdown-radio-unchecked.svg";
import { ReactComponent as RadioChecked } from "../../../assets/dropdown-radio-checked.svg";
import useClickOutside from "../../../hooks/useClickOutside";
import VTPStyles from "../../../styles/vtpStyles";

export interface IDropdownOption {
  name: string;
  value: string;
}

interface DropdownProps {
  options: IDropdownOption[];
  selectedValue?: string;
  optionSelectedCallback: (selectedOptionValue: string) => void;
  ignoreInternalValue?: boolean;

  small?: boolean;
  className?: string;
  style?: CSSProperties;
}

const VTPDropdown = (props: DropdownProps) => {
  const dropdownRef = useRef(null);
  useClickOutside([dropdownRef], () => setShowDropdown(false));
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>(
    props.selectedValue ?? props.options[0]?.value ?? ""
  );

  // Handles selected value prop
  useEffect(() => {
    if (props.selectedValue && props.selectedValue != selectedValue) {
      setSelectedValue(props.selectedValue);
    }
  }, [props.selectedValue]);

  const handleValueSelection = (selectedValue: string) => {
    if (props.ignoreInternalValue != true) {
      setSelectedValue(selectedValue);
    }
    // Fire selected value callback.
    props.optionSelectedCallback(selectedValue);
    setShowDropdown(false);
  };

  const selectedOptionName = () =>
    props.options.find((opt) => opt.value == selectedValue)?.name;

  return (
    <div
      ref={dropdownRef}
      className={`${styles.vtpDropdownContainer} no-text-select
        ${showDropdown ? ` ${styles.open} ` : ""}
        ${props.small ? styles.vtpDropdownContainerSmall : ""}
        ${props.className ?? ""} ${showDropdown ? "z-1 border-0" : ""}`}
      style={props.style}
    >
      <div
        className={`${styles.vtpDropdownHeader} ${
          props.small
            ? VTPStyles.Typography.Buttons.SizeSmall
            : VTPStyles.Typography.Buttons.SizeMedium
        }`}
        onClick={() => setShowDropdown(true)}
      >
        <div className={styles.vtpDropdownLabel}>{selectedOptionName()}</div>
        <DropdownArrow className={styles.vtpDropdownIcon} stroke={"#0C615A"} />
      </div>
      {showDropdown ? (
        <div className={styles.vtpDropdownOptionsMain}>
          <div
            className={`${styles.vtpDropdownHeader} ${
              props.small
                ? VTPStyles.Typography.Buttons.SizeSmall
                : VTPStyles.Typography.Buttons.SizeMedium
            }`}
            onClick={() => setShowDropdown(false)}
          >
            <div className={`${styles.vtpDropdownOptionsLabel}`}>
              {selectedOptionName()}
            </div>
            <DropdownArrow
              className={styles.vtpDropdownOptionsIcon}
              stroke={"#0C615A"}
            />
          </div>
          {props.options.map((option, index) => {
            const isSelected = option.value === selectedValue;
            return (
              <div
                key={index}
                onClick={() => {
                  handleValueSelection(option.value);
                }}
                className={styles.vtpDropdownOptionDetail}
              >
                <div
                  className={`${styles.vtpDropdownOptionLabel} ${
                    props.small
                      ? VTPStyles.Typography.Buttons.SizeSmall
                      : VTPStyles.Typography.Buttons.SizeMedium
                  }`}
                >
                  {option.name}
                </div>
                <div
                  className={
                    styles.vtpDropdownOptionIcon + " d-flex flex-row-reverse"
                  }
                >
                  {isSelected ? <RadioChecked /> : <RadioUnchecked />}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default VTPDropdown;
