import React, { PropsWithChildren } from "react";
import styles from "./guidedFlowLayout.module.css";
import VTPCard from "../components/base/vtpCard";

const GuidedFlowLayout = (props: PropsWithChildren) => {
  return (
    <VTPCard className={`${styles.guidedFlowContainer}`}>
      {props.children}
    </VTPCard>
  );
};

export default GuidedFlowLayout;
