import React from "react";
import VTPTextInput, { BorderType } from "../../base/form/vtpTextInput";
import styles from "./tableSearchInput.module.css";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { StylingProps } from "../../../lib/common";

interface Props extends StylingProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  placeHolder?: string;
}

const TableSearchInput = (props: Props) => {
  return (
    <VTPTextInput
      className={`${styles.search} ${props.className}`}
      borderStyle={BorderType.RoundBorder}
      placeholder={props.placeHolder ?? "Search"}
      onChange={props.onChange}
      prependElement={<SearchIcon />}
    />
  );
};

export default TableSearchInput;
