import React, { PropsWithChildren, useEffect, useState } from "react";
import TableSearchInput from "../base/table/tableSearchInput";
import styles from "./courseFiles.module.css";
import VTPStyles from "../../styles/vtpStyles";
import { Scenario, UserFile, UserFileSortingKey } from "../../lib/apiModels";
import { useVTPCloud } from "../../context/vtpCloud-context";
import VTPCard from "../base/vtpCard";
import CommonUtilities from "../../lib/common";
import { getSortingHeader, SortingState } from "./fileSorting";
import useStateMachine from "../../hooks/useStateMachine";
import VTPTable from "../base/vtpTable";
import { ReactComponent as ArrowIcon } from "../../assets/icons/tiny_arrow.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_pencil.svg";
import { ReactComponent as TrashbinIcon } from "../../assets/icons/trashbin.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { useDataPagination } from "../../hooks/usePagination";
import VTPPagination from "../base/vtpPagination";
import { GetFileCategoryIcon } from "../../pages/filesPage";
import VTPLoadingSpinner from "../base/vtpLoadingSpinner";
import { useAppContext } from "../../context/app-context";
import VTPConfirmationModal from "../modal/vtpConfirmationModal";
import EditUserFileModal from "./editUserFileModal";
import UploadUserFilesModal from "./uploadUserFilesModal";
import { AlertType, useAlert } from "../../hooks/useAlert";
import { useUploadManagerContext } from "../../context/useUploadManager-context";
import VTPContextMenu from "../base/form/vtpContextMenu";
import {useTranslation} from "react-i18next";

interface CourseUserFile extends UserFile {
  sharedStorage: boolean;
}

const getScenarioHeader = (scenario: Scenario) => {
  let header = scenario.scenarioName;

  const scenarioCategory = scenario.tags["mainCategory"];
  const appName = scenario.tags["appName"];

  if (scenarioCategory) {
    header = `${scenarioCategory} - ${header}`;
  }

  if (appName) {
    header = `${appName} - ${header}`;
  }

  return header;
};

interface CollapsibleCourseSectionProps extends PropsWithChildren {
  scenario: Scenario;
  courseFiles: CourseUserFile[];
  searchQuery: string;
}

const CollapsibleCourseSection = (props: CollapsibleCourseSectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState(
    props.courseFiles.length === 0
  );
  const [folderMap, setFolderMap] = useState(
    new Map<string | undefined, CourseUserFile[]>()
  );

  useEffect(() => {
    const filteredUserFiles = props.courseFiles.filter((u) => {
      if (props.searchQuery) {
        return u.displayName
          .toLowerCase()
          .includes(props.searchQuery.toLowerCase());
      } else {
        return u;
      }
    });

    setFolderMap(groupByFolder(filteredUserFiles));
  }, [props.courseFiles, props.searchQuery]);

  return (
    <div className={styles.collapsibleCourseSection}>
      <div
        className={`${styles.header} no-text-select`}
        onClick={() => setIsCollapsed((prevState) => !prevState)}
      >
        <ArrowIcon
          className={`${styles.arrowIcon} ${
            isCollapsed ? styles.closed : styles.open
          }`}
        />
        <h2 className={`${VTPStyles.Typography.Headers.H2SubheaderSmall}`}>
          {getScenarioHeader(props.scenario)}
        </h2>
      </div>
      {isCollapsed ? null : (
        <div className={`${styles.folderGroup}`}>
          {Array.from(folderMap.keys()).map((folderName, pos) => {
            const folderUserFiles = folderMap.get(folderName);
            return (
              <FileFolder
                key={folderName + props.scenario.id}
                folderName={folderName}
                files={folderUserFiles ?? []}
                isExpanded={!!props.searchQuery}
                scenario={props.scenario}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const FileFolder = (props: {
  folderName?: string;
  files: CourseUserFile[];
  isExpanded?: boolean;
  scenario: Scenario;
}) => {
  const { t } = useTranslation();
  const { getSharedUserFile, getUserFile, deleteUserFile } = useVTPCloud();
  const { fileListChanged } = useUploadManagerContext();
  const { pushAlert } = useAlert();
  const sortingState = useStateMachine({
    value: UserFileSortingKey.DisplayName,
    state: SortingState.Asc,
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const [sortedFiles, setSortedUserFiles] = useState<CourseUserFile[]>([]);

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [editUserFileModal, setEditUserFileModal] = useState<UserFile>();
  const [deleteUserFileModal, setDeleteUserFileModal] = useState<UserFile>();

  const initiateFileDownload = (
    userFile: CourseUserFile,
    previewInBrowser: boolean
  ) => {
    if (userFile.sharedStorage) {
      getSharedUserFile(userFile.id, { preview: previewInBrowser }).then(
        (res) => window.open(res.transferUrl, "_blank")
      );
    } else {
      getUserFile(userFile.id, { preview: previewInBrowser }).then((res) =>
        window.open(res.transferUrl, "_blank")
      );
    }
  };

  useEffect(() => {
    const sortedArray = props.files.sort((a, b) => {
      const currentState = sortingState.state;
      let leftSide = a;
      let rightSide = b;

      if (currentState.state == SortingState.Desc) {
        leftSide = b;
        rightSide = a;
      }

      if (currentState.value == UserFileSortingKey.DisplayName) {
        return leftSide.displayName.localeCompare(rightSide.displayName);
      } else if (currentState.value == UserFileSortingKey.ContentType) {
        return leftSide.contentType
          .replace(".", "")
          .localeCompare(rightSide.contentType.replace(".", ""));
      } else if (currentState.value == UserFileSortingKey.CreatedAt) {
        return (
          new Date(leftSide.createdAt).getTime() -
          new Date(rightSide.createdAt).getTime()
        );
      } else if (currentState.value == UserFileSortingKey.Size) {
        return leftSide.sizeKilobytes - rightSide.sizeKilobytes;
      } else {
        return 1;
      }
    });

    setSortedUserFiles(sortedArray.map((u) => u));
  }, [sortingState.state, props.files]);

  const filePaging = useDataPagination(sortedFiles, 8);

  const tableColumns = [
    getSortingHeader(t("filesPage.tableColumns.fileName"), UserFileSortingKey.DisplayName, sortingState),
    getSortingHeader(t("filesPage.tableColumns.type"), UserFileSortingKey.ContentType, sortingState),
    getSortingHeader(t("filesPage.tableColumns.size"), UserFileSortingKey.Size, sortingState),
    getSortingHeader(t("filesPage.tableColumns.uploaded"), UserFileSortingKey.CreatedAt, sortingState),
    "",
  ];

  const getTotalFileSize = () => {
    const totalKB = props.files.reduce(
      (sizeCounter, item) => sizeCounter + item.sizeKilobytes,
      0
    );
    return CommonUtilities.FormatFileSize(totalKB * 1000, true);
  };

  const handleUserFileDelete = (userFile: UserFile) => {
    deleteUserFile(userFile.id)
      .then(() => {
        pushAlert({
          message: t("filesPage.notifications.fileDeletedSuccess", {fileName: userFile.displayName}),
          type: AlertType.Success,
        });
      })
      .catch(() =>
        pushAlert({
          message: t("filesPage.notifications.fileDeletedError", {fileName: userFile.displayName}),
          type: AlertType.Error,
        })
      )
      .finally(() => {
        setDeleteUserFileModal(undefined);
        fileListChanged();
      });
  };

  return (
    <VTPCard className={`${styles.folder}`}>
      <div
        className={`${styles.folderHeader} no-text-select`}
        onClick={() => setIsExpanded((prevVal) => !prevVal)}
      >
        <div>
          <div className={`${VTPStyles.Typography.Body.Medium}`}>
            {props.folderName ?? t("filesPage.pageSwitcher.courseFiles")}
          </div>
          <div className={`${styles.folderNameUnderline}`} />
        </div>
        <div
          className={`${styles.folderSizeGroup} ${VTPStyles.Typography.Body.Small}`}
        >
          <div className={`${VTPStyles.Color.Text.SecondaryColor}`}>
            {t("filesPage.filesTotal")}
          </div>
          <div className={`${VTPStyles.Color.Text.PrimaryColor}`}>{`${
            props.files?.length
          } (${getTotalFileSize()})`}</div>
        </div>
        <VTPButton
          className={styles.addFilesButton}
          size={ButtonSize.Small}
          type={ButtonType.Tertiary}
          onClick={(e) => {
            e.stopPropagation();
            setShowFileUploadModal(true);
          }}
        >
          <PlusIcon />
          {t("filesPage.inputs.addFiles")}
        </VTPButton>
      </div>
      {isExpanded || (props.isExpanded && props.files.length > 0) ? (
        <div className={styles.courseFilesTable}>
          <VTPTable
            columns={tableColumns}
            rows={(filePaging.pageData ?? []).map((u) => {
              return [
                <div
                  className={`${styles.fileNameGroup} no-text-select`}
                  key={u.id}
                  onClick={() => initiateFileDownload(u, true)}
                >
                  <div>{GetFileCategoryIcon(u.contentType)}</div>
                  <div title={u.displayName} className={styles.fileName}>
                    {getFileAndFolderName(u.displayName).fileName}
                  </div>
                </div>,
                u.contentType.replace(".", ""),
                CommonUtilities.FormatFileSize(u.sizeKilobytes * 1000, true),
                CommonUtilities.FormatDate(u.createdAt),
                u.sharedStorage ? (
                  <VTPButton
                    key={u.id}
                    className={styles.downloadButton}
                    size={ButtonSize.Small}
                    type={ButtonType.Tertiary}
                    onClick={(e) => {
                      initiateFileDownload(u, false);
                    }}
                  >
                    <DownloadIcon />
                  </VTPButton>
                ) : (
                  <div className={styles.optionsButton}>
                    <VTPContextMenu
                      key={u.id}
                      className={styles.optionsButton}
                      options={[
                        {
                          icon: (
                            <EditIcon
                              style={{ width: "16px", height: "18px" }}
                            />
                          ),
                          name: t("filesPage.inputs.edit"),
                          value: "edit",
                        },
                        {
                          icon: (
                            <TrashbinIcon
                              style={{ width: "14px", height: "18px" }}
                            />
                          ),
                          name: t("filesPage.inputs.delete"),
                          value: "delete",
                        },
                        {
                          icon: (
                            <DownloadIcon
                              className={`${styles.downloadIcon}`}
                            />
                          ),
                          name: t("filesPage.inputs.download"),
                          value: "download",
                        },
                      ]}
                      onOptionSelected={(selectedOption) => {
                        if (selectedOption.value === "delete") {
                          setDeleteUserFileModal(u);
                        } else if (selectedOption.value === "edit") {
                          setEditUserFileModal(u);
                        } else if (selectedOption.value === "download") {
                          initiateFileDownload(u, false);
                        }
                      }}
                      alignRight={true}
                    />
                  </div>
                ),
              ];
            })}
          />
          <VTPPagination
            previousPageEnabled={filePaging.hasPreviousPage}
            nextPageEnabled={filePaging.hasNextPage}
            onPreviousPageSelect={filePaging.previousPage}
            onNextPageSelect={filePaging.nextPage}
          />
        </div>
      ) : null}
      {deleteUserFileModal ? (
        <VTPConfirmationModal
          header={t("filesPage.deleteFileModal.header", {fileName: getFileAndFolderName(deleteUserFileModal.displayName).fileName})}
          bodyText={t("filesPage.deleteFileModal.text1", {fileName: getFileAndFolderName(deleteUserFileModal.displayName).fileName})}
          onCancel={() => setDeleteUserFileModal(undefined)}
          onCompletionFunction={() => handleUserFileDelete(deleteUserFileModal)}
          confirmationButtonText={t("common.inputs.confirm")}
        />
      ) : null}
      {editUserFileModal ? (
        <EditUserFileModal
          userFile={editUserFileModal}
          onCancel={() => setEditUserFileModal(undefined)}
          scenarioFile={true}
          onConfirm={() => {
            setEditUserFileModal(undefined);
            fileListChanged();
          }}
        />
      ) : null}
      {showFileUploadModal ? (
        <UploadUserFilesModal
          onCancel={() => setShowFileUploadModal(false)}
          onSubmit={() => setShowFileUploadModal(false)}
          courseFileUploadOptions={{
            folderName: props.folderName,
            scenarioId: props.scenario.id,
          }}
        />
      ) : null}
    </VTPCard>
  );
};

const getFileAndFolderName = (path: string) => {
  const split = path.split("/");
  if (split.length < 2) {
    return { folderName: undefined, fileName: split[0] };
  }

  return {
    fileName: split[split.length - 1],
    folderName: split[split.length - 2],
  };
};

function groupByFolder(list: CourseUserFile[]) {
  const map = new Map<string | undefined, CourseUserFile[]>();
  list.forEach((item) => {
    const split = getFileAndFolderName(item.displayName);

    const userFilesArray = map.get(split.folderName) ?? [];
    userFilesArray.push(item);
    map.set(split.folderName, userFilesArray);
  });

  if (map.size === 0) {
    map.set(undefined, []);
  }

  return map;
}

const CourseFiles = () => {
  const {
    subscribeOnFileListChanged,
    unsubscribeOnFileListChanged,
  } = useUploadManagerContext();
  const { t } = useTranslation();
  const { getScenarios, getUserFiles, getSharedUserFiles } = useVTPCloud();
  const { getSelectedTenant } = useAppContext();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [scenarios, setScenarios] = useState<Scenario[]>();
  const [courseFiles, setCourseFiles] = useState<CourseUserFile[]>([]);

  useEffect(() => {
    const eventId = window.crypto.randomUUID();
    subscribeOnFileListChanged(eventId, () => loadCourseFiles());

    return () => {
      unsubscribeOnFileListChanged(eventId);
    };
  }, []);

  useEffect(() => {
    loadCourseFiles();
  }, [getSelectedTenant]);

  const loadCourseFiles = () => {
    Promise.all([
      getUserFiles({ scenarioOnly: true, limit: -1 }),
      getSharedUserFiles({ scenarioOnly: true, limit: -1 }),
      getScenarios({ limit: -1 }),
    ])
      .then(([tenantResponse, sharedResponse, scenarioResponse]) => {
        setIsLoading(true);
        setScenarios(scenarioResponse.items);
        const tenantFiles: CourseUserFile[] = tenantResponse.items.map(
          (file) => ({
            ...file,
            sharedStorage: false,
          })
        );

        const sharedFiles = sharedResponse.items.map((file) => ({
          ...file,
          sharedStorage: true,
        }));

        setCourseFiles([...tenantFiles, ...sharedFiles]);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.searchInput}`}>
        <TableSearchInput
          onChange={(e) => setSearchQuery(e.target.value)}
          placeHolder={t("filesPage.inputs.searchFiles")}
        />
      </div>
      <div className={styles.courseFileList}>
        {isLoading ? (
          <div className={styles.loadingSpinner}>
            <VTPLoadingSpinner small={false} dark={true} />
          </div>
        ) : null}
        {scenarios
          ?.sort((a, b) => {
            // Check if scenario 'a' has course files
            const aHasFiles = courseFiles.some((u) =>
              u.scenarioIds.includes(a.id)
            );
            // Check if scenario 'b' has course files
            const bHasFiles = courseFiles.some((u) =>
              u.scenarioIds.includes(b.id)
            );

            if (aHasFiles && !bHasFiles) {
              return -1; // 'a' has files and 'b' doesn't, 'a' should come first
            } else if (!aHasFiles && bHasFiles) {
              return 1; // 'b' has files and 'a' doesn't, 'b' should come first
            } else {
              // If both have files or both don't have files, sort alphabetically
              return getScenarioHeader(a).localeCompare(getScenarioHeader(b));
            }
          })
          .map((s) => {
            return (
              <CollapsibleCourseSection
                key={s.id}
                scenario={s}
                searchQuery={searchQuery}
                courseFiles={courseFiles.filter((u) =>
                  u.scenarioIds.some((id) => id === s.id)
                )}
              />
            );
          })}
      </div>
    </div>
  );
};

export default CourseFiles;
