import styles from "./vtpPagination.module.css";
import React, { MouseEventHandler } from "react";
import { ReactComponent as ChevronPrev } from "../../assets/chevron-prev.svg";
import { ReactComponent as ChevronNext } from "../../assets/chevron-next.svg";
import { StylingProps } from "../../lib/common";
import VTPStyles from "../../styles/vtpStyles";

interface Props extends StylingProps {
  onPreviousPageSelect?: MouseEventHandler;
  onNextPageSelect?: MouseEventHandler;

  previousPageEnabled: boolean;
  nextPageEnabled: boolean;
}

const VTPPagination = (props: Props) => {
  const handlePreviousPageClicked = (
    event: React.MouseEvent<HTMLSpanElement>
  ) => {
    if (props.previousPageEnabled) {
      props.onPreviousPageSelect?.call(event.target, event);
    }
  };

  const handleNextPageClicked = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (props.nextPageEnabled) {
      props.onNextPageSelect?.call(event.target, event);
    }
  };

  return (
    <div
      className={`${styles.paginationContainer} no-text-select ${
        props.className ?? ""
      }`}
      style={props.style}
    >
      <span
        onClick={handlePreviousPageClicked}
        className={`${styles.paginationStep} 
          ${VTPStyles.Typography.Buttons.SizeMedium}
        ${
          props.previousPageEnabled
            ? `${styles.paginationTextEnabled} ${VTPStyles.Color.UI.PrimaryColor}`
            : `${styles.paginationTextDisabled} ${VTPStyles.Color.UI.StatesInactive}`
        }`}
      >
        <ChevronPrev
          className={`${
            props.previousPageEnabled
              ? styles.paginationChevronEnabled
              : styles.paginationChevronDisabled
          }`}
        />
        Back
      </span>
      <span
        onClick={handleNextPageClicked}
        className={`${styles.paginationStep} 
        ${VTPStyles.Typography.Buttons.SizeMedium}
        ${
          props.nextPageEnabled
            ? `${styles.paginationTextEnabled} ${VTPStyles.Color.UI.PrimaryColor}`
            : `${styles.paginationTextDisabled} ${VTPStyles.Color.UI.StatesInactive}`
        }`}
      >
        Next
        <ChevronNext
          className={`${
            props.nextPageEnabled
              ? styles.paginationChevronEnabled
              : styles.paginationChevronDisabled
          }`}
        />
      </span>
    </div>
  );
};

export default VTPPagination;
