import React from "react";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import {Config} from "../../config";
import {useNavigate} from "react-router-dom";

const UserAnalyticsButton = (props: { userId: string, email?: string }) => {
  const navigate = useNavigate();
  
  return (
    <VTPButton
      size={ButtonSize.Small}
      type={ButtonType.Tertiary}
      style={{ userSelect: "text" }}
      onClick={() => Config.enableAnalytics ? navigate(`${Config.analytics}?user=${props.userId}`) : "#"}
    >
      {props.email}
    </VTPButton>
  );
};

export default UserAnalyticsButton;
