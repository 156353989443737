import "../App.css";
import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useEffect } from "react";
import AuthUtilities from "../lib/authUtilities";

// TODO: Deprecate this page once Auth0 rollout has finished
const LoginDeviceCode = () => {
  useEffect(() => {
    AuthUtilities.StartLegacyDeviceCodeInput();
  }, []);

  return (
      <Row className={"center"}>
        <Col
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 className={"header"}>Preparing to log in</h1>
          <Row className={"center"}>
            <Col
              className={"center"}
              style={{
                marginTop: "30px",
              }}
              md={1}
              xs={1}
            >
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                variant="light"
              />
            </Col>
          </Row>
        </Col>
      </Row>
  );
};

export default LoginDeviceCode;
