import React, {PropsWithChildren, useEffect, useState} from "react";
import {LicenseStatus, Tenant, UserProfileResponse, UserRoles,} from "../lib/apiModels";
import {Config} from "../config";

// AppContext is all-purpose storage for all underlying child components
// (Selected organization, current theme colors and etc.)
const AppContext = React.createContext<Context>({} as Context);
type Context = {
  setSelectedTenant: React.Dispatch<React.SetStateAction<Tenant | undefined>>;
  getSelectedTenant: Tenant | undefined;
  setUserTenants: React.Dispatch<React.SetStateAction<Tenant[]>>;
  getUserTenants: Tenant[];
  getUserProfile: UserProfileResponse | undefined;
  setUserProfile: React.Dispatch<
    React.SetStateAction<UserProfileResponse | undefined>
  >;
  getCurrentRole: () => UserRoles | undefined;
  getIsAuthenticatedWithSso: boolean;
  getTokenPoolState: TokenPoolState;
  setIsAuthenticatedWithSso: React.Dispatch<React.SetStateAction<boolean>>;
};

export interface TokenPoolState {
  usedTokens: number;
  unusedTokens: number;
  totalTokens: number;
  tenant?: Tenant;
}

const AppContextProvider = ({ children }: PropsWithChildren) => {
  const [getSelectedTenant, setSelectedTenant] = useState<Tenant>();
  const [getUserTenants, setUserTenants] = useState<Tenant[]>([]);
  const [getUserProfile, setUserProfile] = useState<UserProfileResponse>();
  const [getTokenPoolState, setTokenPoolState] = useState<TokenPoolState>({
    totalTokens: 0,
    usedTokens: 0,
    unusedTokens: 0,
  });

  useEffect(() => {
    if (getSelectedTenant) {
      const totalTokenCount = getSelectedTenant.licenses
        .filter((l) => l.status == LicenseStatus.Active)
        .reduce((accumulator, license) => {
          return accumulator + license.tokenCount;
        }, 0);

      const usedTokenCount = getSelectedTenant.licenses
        .filter((l) => l.status == LicenseStatus.Active)
        .reduce((accumulator, license) => {
          return accumulator + license.usedTokenCount;
        }, 0);

      setTokenPoolState({
        totalTokens: totalTokenCount,
        usedTokens: usedTokenCount,
        unusedTokens: totalTokenCount - usedTokenCount,
        tenant: getSelectedTenant,
      });
    }
  }, [getSelectedTenant]);

  const getCurrentRole = (): UserRoles | undefined => {
    if (getSelectedTenant && getUserProfile) {
      if(getUserProfile?.isSupport){
        return UserRoles.Administrator;
      }
      
      return getUserProfile.tenantRoles[getSelectedTenant.id];
    }
  };
  const [getIsAuthenticatedWithSso, setIsAuthenticatedWithSso] =
    useState<boolean>(false);

  useEffect(() => {
    if (getSelectedTenant) {
      window.localStorage.setItem(
        Config.localStorageTenantKey,
        JSON.stringify(getSelectedTenant)
      );
    }
  }, [getSelectedTenant]);

  return (
    <AppContext.Provider
      value={{
        getSelectedTenant,
        setSelectedTenant,
        getUserTenants,
        setUserTenants,
        getUserProfile,
        setUserProfile,
        getCurrentRole,
        getIsAuthenticatedWithSso,
        setIsAuthenticatedWithSso,
        getTokenPoolState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }

  return context;
};

export { AppContextProvider, useAppContext };
