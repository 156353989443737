import styles from "./vtpFormCard.module.css";
import React, { ReactNode, useState } from "react";
import VTPTextInput, {
  BorderType,
  InputProps,
  InputSize,
} from "./form/vtpTextInput";
import VTPButton, { ButtonSize, ButtonType } from "./button";
import VTPCard from "./vtpCard";
import { StylingProps } from "../../lib/common";
import vtpStyles from "../../styles/vtpStyles";
import VTPStyles from "../../styles/vtpStyles";
import { ReactComponent as ChevronDown } from "../../assets/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../../assets/chevron-up.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_pencil.svg";
import VTPContextMenu from "./form/vtpContextMenu";
import VTPInputHeader from "./vtpInputHeader";
import {useTranslation} from "react-i18next";

interface Props extends StylingProps {
  formCardRows: VTPFormCardRow[];

  // Allows to append element to head of the card
  header?: ReactNode;
  // Allows to append element to footer of the card
  footer?: ReactNode;

  title?: string;
  collapsable?: boolean;
  expandButtonCallback?: () => void;
  collapseButtonCallback?: () => void;

  includeEditButtons?: boolean;
  editMode?: boolean;
  editButtonCallback?: () => void;
  cancelButtonCallback?: () => void;
  saveButtonDisabled?: boolean;
  saveButtonCallback?: () => void;
}

interface VTPFormCardRow {
  left?: ReactNode;
  right?: ReactNode;
  className?: string;
}

const VTPFormCardTextInput = (props: InputProps) => {
  const newProps = { ...props };
  newProps.inputSize = InputSize.FormCard;
  newProps.borderStyle = BorderType.RoundBorder;
  newProps.className = ` ${vtpStyles.Typography.Body.Medium} ${styles.vtpFormCardTextInput}`;
  return VTPTextInput(newProps);
};

const VTPFormCard = (props: Props) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const EditModeButtons = () => {
    return props.editMode ? (
      <div className={styles.vtpFormCardButtons}>
        <VTPButton
          onClick={() => props.cancelButtonCallback?.()}
          size={ButtonSize.Small}
          type={ButtonType.Tertiary}
        >
          {t("common.inputs.cancel")}
        </VTPButton>
        <VTPButton
          onClick={() => {
            props.saveButtonCallback?.();
          }}
          size={ButtonSize.Small}
          type={ButtonType.Primary}
          disabled={props.saveButtonDisabled}
        >
          {t("common.inputs.saveChanges")}
        </VTPButton>
      </div>
    ) : null;
  };

  return (
    <div
      className={`${styles.vtpFormCardContainer} ${props.className ?? ""}`}
      style={props.style}
    >
      <VTPCard className={`${styles.vtpCardContainer}`}>
        {props.header}
        <div className={vtpStyles.Typography.Body.Small}>
          {props.includeEditButtons || props.title ? (
            <div className={styles.titleSection}>
              <VTPInputHeader value={props.title} readonly={true} />
              <VTPContextMenu
                alignRight={true}
                options={[
                  {
                    icon: (
                      <EditIcon
                        style={{
                          width: "14px",
                          height: "18px",
                        }}
                      />
                    ),
                    name: t("common.inputs.edit"),
                    value: "edit",
                  },
                ]}
                onOptionSelected={(selectedOption) => {
                  props.editButtonCallback?.();
                }}
              />
            </div>
          ) : null}

          {props.formCardRows.map((item, index) => {
            return (
              <div
                key={index}
                className={`${styles.vtpFormCardRowContainer} ${
                  item.className ?? ""
                }`}
              >
                <div className={`${styles.vtpFormCardRow}`}>
                  <div
                    className={`${styles.vtpFormCardRowLeft} ${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Text.SecondaryColor}`}
                  >
                    {item.left}
                  </div>
                  {item.right !== null ? (
                    <div
                      className={`${styles.vtpFormCardRowRight} ${VTPStyles.Typography.Body.Medium} ${VTPStyles.Color.Text.PrimaryColor}`}
                    >
                      {item.right}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        {props.collapsable ? (
          <div
            className={styles.vtpFormCardCollapsableSection}
            onClick={() => {
              if (isExpanded) {
                setIsExpanded(false);
                props.collapseButtonCallback?.();
              } else {
                setIsExpanded(true);
                props.expandButtonCallback?.();
              }
            }}
          >
            {isExpanded ? <ChevronUp></ChevronUp> : <ChevronDown />}
          </div>
        ) : null}
        {props.footer}
        {props.includeEditButtons ? <EditModeButtons /> : null}
      </VTPCard>
    </div>
  );
};

export default VTPFormCard;
export { VTPFormCardTextInput };
export type { VTPFormCardRow };
