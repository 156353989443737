import React from "react";
import {StylingProps} from "../../lib/common";
import {Outlet} from "react-router-dom";
import styles from "./headerLayout.module.css"
import { ReactComponent as KandaLogo } from "../../assets/logo/light_kanda_panda.svg";
import { ReactComponent as KandaLogoText } from "../../assets/logo/light_kanda_text.svg";

const HeaderLayout = (props: StylingProps) => {
    return (
      <div className={styles.container}>
          <div className={styles.header}>
              <KandaLogo />
              <KandaLogoText />
          </div>
          <div className={styles.content}>
              <Outlet />
          </div>
      </div>  
    );
}

export default HeaderLayout;