import "../App.css";
import styles from "../pages/login.module.css";

import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

import VTPButton, { ButtonSize, ButtonType } from "../components/base/button";
import { useMediaQuery } from "react-responsive";
import { Config } from "../config";
import { useNavigate } from "react-router-dom";
import { useVTPCloud } from "../context/vtpCloud-context";
import CommonUtilities from "../lib/common";
import { ReactComponent as KandaLogo } from "../assets/logo/light_kanda_stacked.svg";

const Login = () => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
  const { checkIn } = useVTPCloud();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const navigate = useNavigate();

  const login = () => {
      if (
          (Config.clientName == "app" && Config.environment == "dev") ||
          CommonUtilities.IsLocalhost()
      ) {
        loginWithRedirect({connection: "app-db"});
      } else {
        loginWithRedirect();
      }
  };

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      checkIn().then(() => {
        navigate(Config.users);
      });
    }
  }, [isLoading, isAuthenticated]);

  return (
    <Row
      className="col-md-12"
      style={{
        margin: "0%",
        padding: "0%",
        height: "100dvh",
        overflow: "hidden",
      }}
    >
      <Col md={5} style={{ backgroundColor: "#E3EBE9", minHeight: "385px" }}>
        <div className={styles.loginPaneContainer}>
          <Row
            className={`spaceBetweenContent ${
              isMobile ? styles.loginPaneSpacingMobile : styles.loginPaneSpacing
            }`}
          >
            <Col className={styles.loginHeaderContainer}>
              <h1 className={styles.loginHeader}>VTP Dashboard</h1>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px" }}>
            <Col>
              <Row>
                <Col id="child" className={"center login-button-container"}>
                  <VTPButton
                    onClick={login}
                    size={ButtonSize.Large}
                    type={ButtonType.Primary}
                  >
                    Login
                  </VTPButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
      <Col
        md={7}
        className="center"
        style={{
          backgroundColor: "#0C615A",
          position: "relative",
          minHeight: "148px",
        }}
      >
          <KandaLogo className={styles.kandaLogo}/>
      </Col>
    </Row>
  );
};

export default Login;
