import React from "react";
import VTPButton, {VTPButtonProps} from "../../base/button";
import styles from "./tableButton.module.css"

const TableButton = (props: VTPButtonProps) => {
    return (
        <VTPButton className={styles.container}  {...props}/>
    )
}

export default TableButton;