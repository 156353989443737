import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./vtpSimpleModal.module.css";
import { StylingProps } from "../../lib/common";

const VTPSimpleModal = (props: StylingProps) => {
  return (
    <Modal
      className={`${styles.container} ${props.className ?? ""}`}
      show={true}
    >
      <Modal.Body className={`show-grid p-0`}>
        <div>{props.children}</div>
      </Modal.Body>
    </Modal>
  );
};

export default VTPSimpleModal;
