import React, { PropsWithChildren, useMemo, useState } from "react";
import { Config } from "../config";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import axiosRetry from "axios-retry";
import {
  buildKeyGenerator,
  CacheInstance,
  CacheRequestConfig,
  setupCache,
} from "axios-cache-interceptor";
import {
  ICheckInResponse,
  IDeleteUserRoleRequest,
  IErrorResponse,
  IInviteUserRequest,
  IPaginatedResponse,
  IPagingOptions,
  IPostTenantRequest,
  IUpdateTenantRequest,
  IUpdateUserRoleRequest,
  Tenant,
  UpdateUserProfileRequest,
  User,
  UserProfileResponse,
  TenantInvite,
  AssignTokensRequest,
  AssignTokensResponse,
  IGetCoursesResponse,
  Course,
  UpdateTokenRequest,
  IPostLicense,
  IUpdateLicense,
  IUpdateTokenAssignmentRequest,
  UserFile,
  UserFileUploadRequest,
  UpdateUserFileRequest,
  IStorageState,
  Scenario,
  IUserFilesRequestOptions,
  IUserFileRequestOptions,
  TokenAssignment,
  ITokensRequestOptions,
  UserTenantInviteResponse,
  UpdateLicenseRequest,
  ISupportUpdateTenantRequest,
  CourseListAnalyticsViewModel,
  ISessionListAnalyticsViewRequestOptions,
  SessionListAnalyticsViewModel,
  SessionSummaryAnalyticsViewModel,
  SessionSummaryRequestOptions, INotification,
} from "../lib/apiModels";
import { useAuth0 } from "@auth0/auth0-react";
import { AlertType, useAlert } from "../hooks/useAlert";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./app-context";

const VTPCloudContext = React.createContext<Context>({} as Context);
type Context = {
  // Users
  getUsers: (
    pagingOptions?: IPagingOptions
  ) => Promise<IPaginatedResponse<User>>;
  getUser: (id: string) => Promise<User>;
  updateUserRole: (payload: IUpdateUserRoleRequest) => Promise<User>;
  deleteUserRole: (payload: IDeleteUserRoleRequest) => Promise<User>;
  triggerPasswordResetFlow: () => Promise<any>;
  logout: () => Promise<any>;

  // Profile
  getUserProfile: () => Promise<UserProfileResponse>;
  updateUserProfile: (
    payload: UpdateUserProfileRequest
  ) => Promise<UserProfileResponse>;

  // Invites
  getInvites: () => Promise<UserTenantInviteResponse[]>;
  inviteUser: (payload: IInviteUserRequest) => Promise<User>;
  acceptInvite: (id: string) => Promise<TenantInvite>;
  declineInvite: (id: string) => Promise<TenantInvite>;
  sendEmailVerificationMail: () => Promise<any>;

  // Tenants
  postTenant: (payload: IPostTenantRequest) => Promise<Tenant>;
  updateTenant: (payload: IUpdateTenantRequest) => Promise<Tenant>;
  getTenants: (
    pagingOptions?: IPagingOptions
  ) => Promise<IPaginatedResponse<Tenant>>;
  getTenant: (id: string) => Promise<Tenant>;
  deleteTenant: (id: string) => Promise<Tenant>;

  // Updates app context directly
  refreshSelectedTenant: () => Promise<void>;
  refreshUserTenants: () => Promise<IPaginatedResponse<Tenant>>;
  refreshUserProfile: () => Promise<void>;

  // License
  assignTokens: (payload: AssignTokensRequest) => Promise<AssignTokensResponse>;
  updateToken: (payload: UpdateTokenRequest) => Promise<void>;
  refreshLicense: () => Promise<void>;
  updateLicense: (payload: UpdateLicenseRequest) => Promise<Tenant>;
  getTokens: (
    requestOptions?: ITokensRequestOptions
  ) => Promise<IPaginatedResponse<TokenAssignment>>;

  // Courses
  getCourses: () => Promise<IGetCoursesResponse>;
  getCourse: (id: string) => Promise<Course>;

  // Checkin
  checkIn: () => Promise<ICheckInResponse>;
  isCheckedIn: boolean;

  // Support endpoints;
  supportGetUsers: (
    pagingOptions?: IPagingOptions
  ) => Promise<IPaginatedResponse<User>>;
  supportPostLicense: (
    tenantId: string,
    payload: IPostLicense
  ) => Promise<Tenant>;
  supportUpdateLicense: (
    tenantId: string,
    payload: IUpdateLicense
  ) => Promise<Tenant>;
  supportDeleteLicense: (id: string, tenantId: string) => Promise<Tenant>;
  supportUpdateTokenAssignment: (
    tenantId: string,
    payload: IUpdateTokenAssignmentRequest
  ) => Promise<Tenant>;
  supportGetTenants: (
    pagingOptions?: IPagingOptions
  ) => Promise<IPaginatedResponse<Tenant>>;
  supportDeleteTenant: (id: string) => Promise<Tenant>;
  supportUpdateTenant: (
    payload: ISupportUpdateTenantRequest
  ) => Promise<Tenant>;

  // User files
  getUserFiles: (
    pagingOptions?: IUserFilesRequestOptions
  ) => Promise<IPaginatedResponse<UserFile>>;
  getSharedUserFiles: (
    pagingOptions?: IUserFilesRequestOptions
  ) => Promise<IPaginatedResponse<UserFile>>;
  postUserFile: (payload: UserFileUploadRequest) => Promise<UserFile>;
  updateUserFile: (payload: UpdateUserFileRequest) => Promise<UserFile>;
  getUserFile: (
    id: string,
    options?: IUserFileRequestOptions
  ) => Promise<UserFile>;
  getSharedUserFile: (
    id: string,
    options?: IUserFileRequestOptions
  ) => Promise<UserFile>;
  deleteUserFile: (id: string) => Promise<UserFile>;
  getStorageState: () => Promise<IStorageState>;

  // Scenarios
  getScenarios: (
    pagingOptions?: IPagingOptions
  ) => Promise<IPaginatedResponse<Scenario>>;

  // Analytics
  getCourseListAnalyticsView: (
    requestOptions?: ISessionListAnalyticsViewRequestOptions
  ) => Promise<IPaginatedResponse<CourseListAnalyticsViewModel>>;

  getSessionListAnalyticsView: (
    requestOptions?: ISessionListAnalyticsViewRequestOptions
  ) => Promise<IPaginatedResponse<SessionListAnalyticsViewModel>>;

  getSessionSummaryView: (
    requestOptions?: SessionSummaryRequestOptions
  ) => Promise<SessionSummaryAnalyticsViewModel>;
  
  // Notifications
  getNotifications: (pagingOptions?: IPagingOptions) => Promise<IPaginatedResponse<INotification>>;

  // Misc
  uploadFileToBlobStorage: (
    uploadUrl: string,
    file: File,
    onUploadProgress?: (progressEvent: any) => void,
    abortSignal?: AbortSignal
  ) => Promise<void>;
};

const CreateCacheKeyGenerator = (cacheId = "") =>
  buildKeyGenerator(
    ({
      baseURL = "",
      url = "",
      method = "get",
      params,
      data,
      headers = {},
    }) => {
      return {
        url: baseURL + (baseURL && url ? "/" : "") + url,
        params: params,
        method: method,
        data: data,
        tenantHeader: headers["x-tenant-id"],
        cacheId: cacheId,
      };
    }
  );

const InvalidateCache = (cache: CacheInstance | undefined) => {
  const randomString = (Math.random() + 1).toString(36).substring(7);
  if (cache != undefined)
    cache.generateKey = CreateCacheKeyGenerator(randomString);
};

interface CachedAxiosInstance {
  instance: AxiosInstance;
  cache: CacheInstance | undefined;
}

const GetAxiosInstance = (useCache = false): CachedAxiosInstance => {
  if (Config.environment != "prod") {
    console.log("Axios instance initialized.");
  }

  // Set up Axios client
  const host = Config.apiPath;
  const protocol = "https";
  const axiosClient = axios.create({
    baseURL: `${protocol}://${host}`,
    responseType: "json",
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Headers": "content-type, accept, origin",
      "Access-Control-Allow-Origin": Config.dashboardOrigin,
      "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
      "Access-Control-Allow-Credentials": true,
    },
  });

  const cache = useCache
    ? setupCache(axiosClient, {
        ttl: 1000 * 60,
        methods: ["get"],
        generateKey: CreateCacheKeyGenerator(),
      })
    : undefined;

  // Configure exponential backoff retries
  axiosRetry(axiosClient, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: () => true,
  });

  return { instance: axiosClient, cache: cache };
};

const VTPCloudProvider = ({ children }: PropsWithChildren) => {
  const {
    getSelectedTenant,
    setSelectedTenant,
    setUserTenants,
    setIsAuthenticatedWithSso,
    setUserProfile,
  } = useAppContext();

  const axiosClient = useMemo(() => GetAxiosInstance(), [getSelectedTenant]);
  const { pushAlert } = useAlert();
  const auth0Client = useAuth0();
  const navigate = useNavigate();

  const [isCheckedIn, setIsCheckedIn] = useState(false);

  const sendEmailVerificationMail = (): Promise<any> => {
    return baseRequest({ method: "POST", url: "/users/sendverificationemail" });
  };

  const triggerPasswordResetFlow = (): Promise<any> => {
    return baseRequest({
      method: "POST",
      url: "/users/triggerpasswordresetflow",
    });
  };

  const inviteUser = (payload: IInviteUserRequest): Promise<User> => {
    return baseRequest(
      {
        method: "POST",
        url: "/users/invites",
        data: payload,
      },
      payload.tenantId
    );
  };

  const getInvites = (): Promise<UserTenantInviteResponse[]> => {
    return baseRequest({
      method: "GET",
      url: "/users/invites",
    });
  };

  const acceptInvite = (id: string): Promise<TenantInvite> => {
    return baseRequest({
      method: "PUT",
      url: `/users/invites/${id}`,
    });
  };

  const declineInvite = (id: string): Promise<TenantInvite> => {
    InvalidateCache(axiosClient.cache);
    return baseRequest({
      method: "DELETE",
      url: `/users/invites/${id}`,
    });
  };

  const deleteUserRole = (
    deleteUserRequest: IDeleteUserRoleRequest
  ): Promise<User> => {
    InvalidateCache(axiosClient.cache);
    return baseRequest({
      method: "DELETE",
      url: "/users/" + deleteUserRequest.id + "/role",
    });
  };

  const updateUserRole = (payload: IUpdateUserRoleRequest): Promise<User> => {
    InvalidateCache(axiosClient.cache);
    return baseRequest({
      method: "PUT",
      url: "/users/" + payload.id + "/role",
      data: payload,
    });
  };

  const getUsers = (
    pagingOptions?: IPagingOptions
  ): Promise<IPaginatedResponse<User>> => {
    return baseRequest({
      method: "GET",
      url: "/users",
      params: pagingOptions,
      paramsSerializer: (params) => parseParams(params),
    });
  };

  const getUser = (id: string): Promise<User> => {
    return baseRequest({
      method: "GET",
      url: `/users/${id}`,
      id: "users",
    });
  };

  const getUserProfile = (): Promise<UserProfileResponse> => {
    return baseRequest({
      method: "GET",
      url: "/users/profile",
      id: "users",
    });
  };

  const logout = (): Promise<any> => {
    return baseRequest({
      method: "POST",
      url: "/users/logout",
      data: {}
    });
  };

  const updateUserProfile = (
    payload: UpdateUserProfileRequest
  ): Promise<UserProfileResponse> => {
    InvalidateCache(axiosClient.cache);
    return baseRequest({
      method: "PUT",
      url: "/users/profile",
      data: payload,
    });
  };

  const postTenant = (payload: IPostTenantRequest): Promise<Tenant> => {
    InvalidateCache(axiosClient.cache);
    return baseRequest({
      method: "POST",
      url: "/tenants",
      data: payload,
    });
  };

  const updateTenant = (payload: IUpdateTenantRequest): Promise<Tenant> => {
    InvalidateCache(axiosClient.cache);
    return baseRequest({
      method: "PUT",
      url: "/tenants",
      data: payload,
    });
  };

  const getTenants = (
    pagingOptions?: IPagingOptions
  ): Promise<IPaginatedResponse<Tenant>> => {
    return baseRequest({
      method: "GET",
      url: "/tenants",
      params: pagingOptions,
    });
  };

  const getTenant = (id: string): Promise<Tenant> => {
    return baseRequest({
      method: "GET",
      url: `/tenants/${id}`,
      id: "tenants",
    });
  };

  const getCourses = (): Promise<IGetCoursesResponse> => {
    return baseRequest({
      method: "GET",
      url: "/sharedcourses",
    });
  };

  const getCourse = (id: string): Promise<Course> => {
    return baseRequest({
      method: "GET",
      url: `/sharedcourses/${id}`,
    });
  };

  const deleteTenant = (id: string): Promise<Tenant> => {
    return baseRequest({
      method: "DELETE",
      url: `/tenants/${id}`,
    });
  };

  const postUserFile = (payload: UserFileUploadRequest): Promise<UserFile> => {
    return baseRequest({
      method: "POST",
      url: "/userfiles",
      data: payload,
    });
  };

  const updateUserFile = (
    payload: UpdateUserFileRequest
  ): Promise<UserFile> => {
    return baseRequest({
      method: "PUT",
      url: "/userfiles",
      data: payload,
    });
  };

  const getStorageState = (): Promise<IStorageState> => {
    return baseRequest({
      method: "GET",
      url: "/userfiles/storage",
    });
  };

  const getUserFiles = (
    pagingOptions?: IUserFilesRequestOptions
  ): Promise<IPaginatedResponse<UserFile>> => {
    return baseRequest({
      method: "GET",
      url: "/userfiles",
      params: pagingOptions,
    });
  };

  const getSharedUserFiles = (
    pagingOptions?: IUserFilesRequestOptions
  ): Promise<IPaginatedResponse<UserFile>> => {
    return baseRequest({
      method: "GET",
      url: "/shareduserfiles",
      params: pagingOptions,
    });
  };

  const getUserFile = (
    id: string,
    options?: IUserFileRequestOptions
  ): Promise<UserFile> => {
    return baseRequest({
      method: "GET",
      url: `/userfiles/${id}`,
      params: options,
    });
  };

  const getSharedUserFile = (
    id: string,
    options?: IUserFileRequestOptions
  ): Promise<UserFile> => {
    return baseRequest({
      method: "GET",
      url: `/shareduserfiles/${id}`,
      params: options,
    });
  };

  const deleteUserFile = (id: string): Promise<UserFile> => {
    return baseRequest({
      method: "DELETE",
      url: `/userfiles/${id}`,
    });
  };

  const refreshSelectedTenant = async (): Promise<void> => {
    if (getSelectedTenant === undefined) {
      return;
    }

    const refreshedTenant = await getTenant(getSelectedTenant.id);
    setSelectedTenant(refreshedTenant);
  };

  const refreshUserTenants = async (): Promise<IPaginatedResponse<Tenant>> => {
    const tenantsResponse = await getTenants({ limit: -1 });

    setUserTenants(tenantsResponse.items);
    return tenantsResponse;
  };

  const refreshUserProfile = async () => {
    const userProfileResponse = await getUserProfile();
    setUserProfile(userProfileResponse);
  };

  const refreshLicense = (): Promise<void> => {
    InvalidateCache(axiosClient.cache);
    return baseRequest({
      method: "POST",
      url: "/licenses/refresh",
    });
  };

  const updateLicense = (payload: UpdateLicenseRequest): Promise<Tenant> => {
    InvalidateCache(axiosClient.cache);
    return baseRequest({
      method: "PUT",
      url: "/licenses",
      data: payload,
    });
  };

  const assignTokens = (
    payload: AssignTokensRequest
  ): Promise<AssignTokensResponse> => {
    InvalidateCache(axiosClient.cache);
    return baseRequest({
      method: "POST",
      url: `/tokens`,
      data: payload,
    });
  };

  const updateToken = (payload: UpdateTokenRequest): Promise<void> => {
    return baseRequest({
      method: "PUT",
      url: `/tokens`,
      data: payload,
    });
  };

  const getTokens = (
    requestOptions?: ITokensRequestOptions
  ): Promise<IPaginatedResponse<TokenAssignment>> => {
    return baseRequest({
      method: "GET",
      url: "/tokens",
      params: requestOptions,
    });
  };

  const getScenarios = (
    pagingOptions?: IPagingOptions
  ): Promise<IPaginatedResponse<Scenario>> => {
    return baseRequest({
      method: "GET",
      url: "/scenarios",
      params: pagingOptions,
    });
  };

  const checkIn = (): Promise<ICheckInResponse> => {
    return new Promise<ICheckInResponse>((resolve, reject) => {
      return baseRequest<ICheckInResponse>({
        method: "POST",
        url: "/checkins",
        data: { origin: "dashboard" },
      }).then((response: ICheckInResponse) => {
        if (!isCheckedIn) {
          setIsCheckedIn(true);
        }
        return resolve(response);
      });
    });
  };

  // Support endpoints
  const supportGetTenants = (
    pagingOptions?: IPagingOptions
  ): Promise<IPaginatedResponse<Tenant>> => {
    return baseRequest(
      {
        method: "GET",
        url: "/support/tenants",
        params: pagingOptions,
      },
      "blank-id"
    );
  };
  
  const supportGetUsers = (
    pagingOptions?: IPagingOptions
  ): Promise<IPaginatedResponse<User>> => {
    return baseRequest(
      {
        method: "GET",
        url: "/support/users",
        params: pagingOptions,
        paramsSerializer: (params) => parseParams(params),
      },
      "blank-id"
    );
  };

  const supportPostLicense = (
    tenantId: string,
    payload: IPostLicense
  ): Promise<Tenant> => {
    return baseRequest(
      {
        method: "POST",
        url: "/support/licenses",
        data: payload,
      },
      tenantId
    );
  };

  const supportUpdateLicense = (
    tenantId: string,
    payload: IUpdateLicense
  ): Promise<Tenant> => {
    return baseRequest(
      {
        method: "PUT",
        url: "/support/licenses",
        data: payload,
      },
      tenantId
    );
  };

  const supportDeleteLicense = (
    id: string,
    tenantId: string
  ): Promise<Tenant> => {
    return baseRequest(
      {
        method: "DELETE",
        url: `support/licenses/${id}`,
      },
      tenantId
    );
  };

  const supportUpdateTokenAssignment = (
    tenantId: string,
    payload: IUpdateTokenAssignmentRequest
  ): Promise<Tenant> => {
    return baseRequest(
      {
        method: "PUT",
        url: "/support/tokenassignments",
        data: payload,
      },
      tenantId
    );
  };

  const supportDeleteTenant = (id: string): Promise<Tenant> => {
    return baseRequest(
      {
        method: "DELETE",
        url: `support/tenants/${id}`,
      },
      id
    );
  };

  const supportUpdateTenant = (
    payload: ISupportUpdateTenantRequest
  ): Promise<Tenant> => {
    return baseRequest({
      method: "PUT",
      url: "support/tenants",
      data: payload,
    });
  };

  const uploadFileToBlobStorage = (
    uploadUrl: string,
    file: File,
    onUploadProgress?: (progressEvent: any) => void,
    abortSignal?: AbortSignal
  ): Promise<void> => {
    return axios.put(uploadUrl, file, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-ms-blob-type": "BlockBlob",
      },
      signal: abortSignal,
      onUploadProgress: onUploadProgress,
    });
  };

  const getCourseListAnalyticsView = (
    requestOptions?: ISessionListAnalyticsViewRequestOptions
  ): Promise<IPaginatedResponse<CourseListAnalyticsViewModel>> => {
    return baseRequest({
      method: "GET",
      url: "/activitylogs",
      params: { ...requestOptions, view: "courseList" },
      paramsSerializer: (params) => parseParams(params),
    });
  };

  const getSessionListAnalyticsView = (
    requestOptions?: ISessionListAnalyticsViewRequestOptions
  ): Promise<IPaginatedResponse<SessionListAnalyticsViewModel>> => {
    return baseRequest({
      method: "GET",
      url: "/activitylogs",
      params: { ...requestOptions, view: "sessionList" },
      paramsSerializer: (params) => parseParams(params),
    });
  };

  const getSessionSummaryView = (
    requestOptions?: SessionSummaryRequestOptions
  ): Promise<SessionSummaryAnalyticsViewModel> => {
    return baseRequest({
      method: "GET",
      url: "/activitylogs",
      params: { ...requestOptions, view: "sessionSummary" },
      paramsSerializer: (params) => parseParams(params),
    });
  };
  
  // Notifications
  const getNotifications = (
      requestOptions?: IPagingOptions
  ): Promise<IPaginatedResponse<INotification>> => {
    return baseRequest(
        {
          method: "GET",
          url: "/notifications",
          params: requestOptions,
        },
    );
  };

  async function baseRequest<T>(
    config: AxiosRequestConfig | CacheRequestConfig,
    tenantId?: string
  ): Promise<T> {
    try {
      const idToken = await auth0Client.getIdTokenClaims();
      if (idToken == undefined) {
        throw new Error("User did not have a valid ID token.");
      }

      // Note: we check the ID token here to see whether Single Sign On was used for authentication.
      // For that we rely on the "sub" claim of the ID token, which currently starts with
      // either "oauth2" or "auth0".
      // We may need to adapt this with future changes.
      setIsAuthenticatedWithSso(
        idToken.sub.toString().indexOf("oauth2", 0) > -1
      );

      config.headers = {
        Authorization: `Bearer ${idToken.__raw}`,
        "x-tenant-id": tenantId ?? getSelectedTenant?.id ?? "",
      };

      return new Promise<T>((resolve, reject) => {
        axiosClient.instance
          .request<T>(config)
          .then((result) => resolve(result.data))
          .catch((error: AxiosError) => {
            const errorResponse =
              error.response as AxiosResponse<IErrorResponse>;

            if (errorResponse.status === 500) {
              pushAlert({
                message: "Oops! An error occured.",
                type: AlertType.Error,
              });
              reject();
            } else if (errorResponse.status === 503) {
              pushAlert({
                message:
                  "Sorry, we cannot reach the backend service. Please try again later.",
                type: AlertType.Error,
              });
              reject();
            }
            else if (errorResponse.status === 401) {
              auth0Client.logout();
            }
            else {
              reject(errorResponse.data);
            }
          });
      });
    } catch (error) {
      navigate(Config.loginRoute);
      return Promise.reject();
    }
  }

  return (
    <VTPCloudContext.Provider
      value={{
        getUsers,
        getUser,
        updateUserRole,
        deleteUserRole,
        getUserProfile,
        logout,
        updateUserProfile,
        triggerPasswordResetFlow,
        getTenants,
        getTenant,
        getCourses,
        getCourse,
        postUserFile,
        updateUserFile,
        getStorageState,
        getUserFile,
        getSharedUserFile,
        getUserFiles,
        getSharedUserFiles,
        deleteUserFile,
        getScenarios,
        updateTenant,
        deleteTenant,
        refreshSelectedTenant,
        refreshUserTenants,
        refreshUserProfile,
        checkIn,
        inviteUser,
        getInvites,
        acceptInvite,
        declineInvite,
        postTenant,
        sendEmailVerificationMail,
        isCheckedIn,
        refreshLicense,
        updateLicense,
        getTokens,
        assignTokens,
        updateToken,
        getCourseListAnalyticsView,
        getSessionListAnalyticsView,
        getSessionSummaryView,
        supportGetTenants,
        supportUpdateTenant,
        supportGetUsers,
        supportPostLicense,
        supportUpdateLicense,
        supportDeleteLicense,
        supportDeleteTenant,
        supportUpdateTokenAssignment,
        uploadFileToBlobStorage,
        getNotifications
      }}
    >
      {children}
    </VTPCloudContext.Provider>
  );
};

function parseParams(params: any) {
  const keys = Object.keys(params);
  let options = "";
  keys.forEach((key) => {
    const isParamTypeObject = typeof params[key] === "object";
    const isParamTypeArray = isParamTypeObject && params[key].length >= 0;
    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`;
    }
    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach((element: any) => {
        options += `${key}=${element}&`;
      });
    }
  });
  return options ? options.slice(0, -1) : options;
}

const useVTPCloud = () => {
  const context = React.useContext(VTPCloudContext);
  if (context === undefined) {
    throw new Error("useVTPCloud must be used within a VTPCloudProvider");
  }

  return context;
};

export { VTPCloudProvider, useVTPCloud };
