import React, { useEffect, useState } from "react";
import styles from "./coursesSummaryAnalytics.module.css";
import VTPTable from "../base/vtpTable";
import VTPSortableTableHeader, {
  useSortingStateMachine,
} from "../base/vtpSortableTableHeader";
import { Course } from "../../lib/apiModels";
import { useEndpointPagination } from "../../hooks/usePagination";
import { useVTPCloud } from "../../context/vtpCloud-context";
import PageSection from "../base/pageSection";
import { ReactComponent as PersonIcon } from "../../assets/navigation/person.svg";
import { defaultDateRange, formatSessionLength } from "../../pages/analytics";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { useNavigate } from "react-router-dom";
import { Config } from "../../config";
import commonStyles from "../../pages/analytics.module.css";
import VTPStyles from "../../styles/vtpStyles";
import VTPDateRangePicker from "../base/vtpDateRangePicker";
import {useTranslation} from "react-i18next";

enum CourseAnalyticsSortingKey {
  Course = "course",
  NumberOfSessions = "numberOfSessions",
  AverageSessionLength = "averageSessionLength",
}

const CoursesSummaryAnalytics = (props: { courses: Course[] }) => {
  // Component dependencies.
  const { t } = useTranslation();
  const { getCourseListAnalyticsView } = useVTPCloud();
  const navigate = useNavigate();

  // Paging and sorting
  const [dateRange, setDateRange] = useState(defaultDateRange());
  const sortingStateMachine = useSortingStateMachine(
    CourseAnalyticsSortingKey.Course
  );
  const paging = useEndpointPagination(getCourseListAnalyticsView, -1, {
    sort: `${sortingStateMachine.state.value}:${sortingStateMachine.state.sortingState}`,
    from: dateRange.from.toISOString(),
    to: dateRange.to.toISOString(),
  });

  // Sorting
  useEffect(() => {
    const sortingParam = `${sortingStateMachine.state.value}:${sortingStateMachine.state.sortingState}`;
    if (paging.options.sort !== sortingParam) {
      paging.setOptions({
        ...paging.options,
        sort: sortingParam,
      });
    }
  }, [sortingStateMachine.state]);

  // Date range changes
  useEffect(() => {
    if (
      paging.options.from !== dateRange.from.toISOString() ||
      paging.options.to !== dateRange.to.toISOString()
    ) {
      paging.setOptions({
        ...paging.options,
        from: dateRange.from.toISOString(),
        to: dateRange.to.toISOString(),
      });
    }
  }, [dateRange]);

  const getCourseName = (courseId: string) => {
    const course = props.courses.find((c) => c.courseId === courseId);

    if (course) {
      return course.name;
    }
  };

  const columns = (): React.ReactNode[] => {
    return [
      <VTPSortableTableHeader
        key={CourseAnalyticsSortingKey.Course}
        text={t("analyticsPage.tableColumns.courseName")}
        value={CourseAnalyticsSortingKey.Course}
        stateMachine={sortingStateMachine}
      />,
      <VTPSortableTableHeader
        key={CourseAnalyticsSortingKey.NumberOfSessions}
        text={t("analyticsPage.tableColumns.numberOfSessions")}
        value={CourseAnalyticsSortingKey.NumberOfSessions}
        stateMachine={sortingStateMachine}
      />,
      <VTPSortableTableHeader
        key={CourseAnalyticsSortingKey.AverageSessionLength}
        text={t("analyticsPage.tableColumns.averageSessionLength")}
        value={CourseAnalyticsSortingKey.AverageSessionLength}
        stateMachine={sortingStateMachine}
      />,
    ];
  };

  const rows = (): React.ReactNode[][] => {
    if (!paging.pageData) {
      return [];
    }

    return paging.pageData?.map((data) => {
      return [
        <VTPButton
          key={data.courseId}
          size={ButtonSize.Small}
          type={ButtonType.Tertiary}
          onClick={() =>
            navigate(`${Config.analytics}?course=${data.courseId}`)
          }
        >
          {getCourseName(data.courseId)}
        </VTPButton>,
        data.numberOfSessions,
        formatSessionLength(data.averageSessionLength),
      ];
    });
  };

  return (
    <PageSection
      header={{
        icon: <PersonIcon />,
        text: t("analyticsPage.courseSummary.header"),
      }}
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <div className={commonStyles.datePickerGroup}>
        <div className={VTPStyles.Typography.Headers.H2SubheaderSmall}>
          {t("analyticsPage.courseSummary.subheader")}
        </div>
        <VTPDateRangePicker
          onSelected={(from, to) => setDateRange({ from: from, to: to })}
        />
      </div>
      <div className={styles.tableContainer}>
        <VTPTable columns={columns()} rows={rows()} />
      </div>
    </PageSection>
  );
};

export default CoursesSummaryAnalytics;
