import React from "react";
import { UserFileSortingKey } from "../../lib/apiModels";
import styles from "./fileSorting.module.css";
import { StateMachineHandle } from "../../hooks/useStateMachine";
import { ReactComponent as SortingIcon } from "../../assets/icons/sorting.svg";

enum SortingState {
  None = "none",
  Asc = "asc",
  Desc = "desc",
}

const sortingClassName = (sortingState: SortingState) => {
  switch (sortingState) {
    case SortingState.Asc:
      return styles.asc;
    case SortingState.Desc:
      return styles.desc;
    default:
      return "";
  }
};

const getSortingHeader = (
  text: string,
  value: UserFileSortingKey,
  sortingState: StateMachineHandle<{
    value: UserFileSortingKey;
    state: SortingState;
  }>
) => {
  let state = SortingState.None;
  if (sortingState.state.value === value) {
    state = sortingState.state.state;
  }

  return (
    <div
      className={`no-text-select ${styles.sortingLabel} ${sortingClassName(
        state
      )}`}
      onClick={() => {
        sortingState.setState({
          value: value,
          state:
            state == SortingState.Asc ? SortingState.Desc : SortingState.Asc,
        });
      }}
    >
      {text}
      <SortingIcon />
    </div>
  );
};

export { SortingState, getSortingHeader };
