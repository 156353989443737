import React, { useEffect, useState } from "react";
import styles from "./uploadManager.module.css";
import VTPStyles from "../../styles/vtpStyles";
import {
  FileUploadRequest,
  useUploadManagerContext,
} from "../../context/useUploadManager-context";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { GetFileCategoryIcon } from "../../pages/filesPage";
import { ReactComponent as TrashBin } from "../../assets/trashbin.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh.svg";
import { ReactComponent as TinyArrow } from "../../assets/icons/tiny_arrow.svg";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { UserFile } from "../../lib/apiModels";
import { AlertType, useAlert } from "../../hooks/useAlert";
import {useTranslation} from "react-i18next";

interface FileUploadItem extends FileUploadRequest {
  id: string;
  error?: boolean;
}

interface UploadStatus {
  total: number;
  uploaded: number;
}

const UploadManager = () => {
  const {
    subscribeOnRequestsAdded,
    unsubscribeOnRequestsAdded,
    fileListChanged,
  } = useUploadManagerContext();
  const { pushAlert } = useAlert();
  const { t } = useTranslation();
  const [uploadRequests, setUploadRequests] = useState<FileUploadItem[]>([]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [uploadStatus, setUploadStatus] = useState(
    new Map<string, UploadStatus>()
  );

  // Incoming upload request handling
  const requestAdded = (requests: FileUploadRequest[]) => {
    setUploadRequests((currentValues) => {
      return currentValues.concat(
        requests.map((i, pos) => {
          return { ...i, id: window.crypto.randomUUID() };
        })
      );
    });
  };

  useEffect(() => {
    const eventId = window.crypto.randomUUID();
    subscribeOnRequestsAdded(eventId, requestAdded);

    return () => unsubscribeOnRequestsAdded(eventId);
  }, []);

  // Controlling max simultaneous uploads
  const maxUploads = 8;
  const [uploadQueue, setUploadQueue] = useState<string[]>([]);

  // Clear remove upload items from queue
  useEffect(() => {
    setUploadQueue((prevState) =>
      prevState.filter((i) =>
        uploadRequests.filter((u) => !u.error).some((u) => u.id === i)
      )
    );
  }, [uploadRequests]);

  // Queue next items for upload
  useEffect(() => {
    const itemsNumToQueue = maxUploads - uploadQueue.length;
    const itemsToUpload = uploadRequests.filter((u) => !u.error);

    if (itemsNumToQueue > 0 && itemsToUpload.length > 0) {
      setUploadQueue((prevState) =>
        prevState.concat(itemsToUpload.slice(0, maxUploads).map((u) => u.id))
      );
    }
  }, [uploadQueue, uploadRequests]);

  const handleUploadFinished = (item: FileUploadItem) => {
    setUploadRequests((prevState) => prevState.filter((u) => u.id !== item.id));
  };

  const handleUploadFailure = (item: FileUploadItem) => {
    setUploadRequests((prevState) => {
      const col = prevState.filter((u) => u.id !== item.id);
      item.error = true;
      col.unshift(item);
      return col;
    });
  };

  const handleUploadCancelled = (item: FileUploadItem) => {
    setUploadRequests((prevState) => prevState.filter((u) => u.id !== item.id));
    setUploadStatus((prevState) => {
      prevState.delete(item.id);
      return new Map(prevState);
    });
  };

  const handleProgressUpdate = (item: FileUploadItem, progress: number) => {
    setUploadStatus((prevState) => {
      prevState.set(item.id, {
        uploaded: item.file.size * (progress / 100),
        total: item.file.size,
      });
      return new Map(prevState);
    });
  };

  // Summarize and notify user
  useEffect(() => {
    const failedRequestCount = uploadRequests.filter((u) => u.error).length;

    if (uploadRequests.length - failedRequestCount <= 0) {
      if (failedRequestCount > 0) {
        pushAlert({
          message: t("uploadManager.notifications.uploadError", {failedUploadCount: failedRequestCount}),
          type: AlertType.Error,
        });
      } else if (Array.from(uploadStatus.values()).length > 0) {
        pushAlert({
          message: t("uploadManager.notifications.uploadSuccess"),
          type: AlertType.Success,
        });
      }

      setUploadStatus(new Map<string, UploadStatus>());
      fileListChanged();
    }
  }, [uploadRequests]);

  const getUploadsProgress = () => {
    const values = Array.from(uploadStatus.values());
    const uploaded = values.reduce((a, b) => a + b.uploaded, 0);
    const total = values.reduce((a, b) => a + b.total, 0);
    const percentage = (uploaded / total) * 100;

    return {
      uploaded: (uploaded / 1000000).toFixed(2),
      total: (total / 1000000).toFixed(2),
      percentage,
    };
  };

  return (
    <div
      className={styles.container}
      style={{ display: uploadRequests.length > 0 ? "" : "none" }}
    >
      <div className={styles.uploadManager}>
        <div
          className={`${styles.header} ${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Text.SecondaryColor}`}
          onClick={() => setIsExpanded((prevState) => !prevState)}
        >
          {isExpanded ? (
            <div className={styles.headerOpen}>
              {t("uploadManager.filesProcessing", {fileCount: uploadRequests.length})}
            </div>
          ) : (
            <div className={styles.headerClosed}>
              <div>
                [{getUploadsProgress().uploaded}/{getUploadsProgress().total}]
                {`mb ${t("uploadManager.uploaded")}...`}
              </div>
              <div className={styles.progress}>
                <div
                  className={styles.progressBar}
                  style={{ width: `${getUploadsProgress().percentage}%` }}
                ></div>
              </div>
            </div>
          )}
          <div
            className={`${styles.arrow} ${
              isExpanded ? styles.arrowOpen : styles.arrowClosed
            }`}
          >
            <TinyArrow />
          </div>
        </div>
        <div
          className={`${styles.fileUploadRows}`}
          style={{ display: isExpanded ? "" : "none" }}
        >
          {uploadRequests.map((u, pos) => {
            return (
              <FileUploadRow
                key={u.id}
                startUploading={uploadQueue.some((i) => i === u.id)}
                uploadRequest={u}
                onComplete={() => handleUploadFinished(u)}
                onUploadError={() => handleUploadFailure(u)}
                onUploadProgress={(progress) =>
                  handleProgressUpdate(u, progress)
                }
                onCancel={() => handleUploadCancelled(u)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const FileUploadRow = (props: {
  startUploading: boolean;
  uploadRequest: FileUploadItem;
  onComplete?: () => void;
  onCancel?: () => void;
  onUploadError?: () => void;
  onUploadProgress?: (progress: number) => void;
}) => {
  const { uploadFileToBlobStorage, postUserFile, deleteUserFile } =
    useVTPCloud();

  const { t } = useTranslation();
  const [isUploadError, setIsUploadError] = useState(false);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [userFile, setUserFile] = useState<UserFile>();
  const [abortController, setAbortController] = useState<AbortController>();

  const getFileName = () => {
    return props.uploadRequest.fileName ?? props.uploadRequest.file.name;
  };

  // Kick off file upload if allowed
  useEffect(() => {
    if (props.startUploading && !isUploadError && !uploadStarted) {
      setUploadStarted(true);
      createUserFile(props.uploadRequest);
    }
  }, [props.startUploading]);

  // Once user-file is created, begin blob upload.
  useEffect(() => {
    if (userFile) {
      uploadFile(userFile);
    }
  }, [userFile]);

  // If upload failed, fire event so upload manager move to next item in queue.
  useEffect(() => {
    if (isUploadError) {
      props.onUploadError?.();
    }
  }, [isUploadError]);

  // Report upload progress to callback
  useEffect(() => {
    props.onUploadProgress?.(uploadProgress);
  }, [uploadProgress]);

  const createUserFile = (request: FileUploadRequest) => {
    const file = request.file;

    postUserFile({
      contentType: request.contentType,
      sharingOption: request.sharingOption,
      displayName: getFileName(),
      sizeKilobytes: file.size / 1000,
      scenarioIds: request.scenarioId ? [request.scenarioId] : undefined
    })
      .then((res) => {
        setUserFile(res);
      })
      .catch(() => setIsUploadError(true));
  };

  const uploadFile = (userFile: UserFile) => {
    const controller = new AbortController();
    setAbortController(controller);

    uploadFileToBlobStorage(
      userFile.transferUrl!,
      props.uploadRequest.file,
      handleProgressEvent,
      controller.signal
    )
      .then(() => props.onComplete?.())
      .catch(() => {
        setIsUploadError(true);
        setAbortController(undefined);
        deleteUserFile(userFile.id)
          .then(() => setUserFile(undefined))
          .catch();
      });
  };

  const handleProgressEvent = (progressEvent: any) => {
    const percentage = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadProgress(Math.min(100, percentage));
  };

  const retryUpload = () => {
    setUploadProgress(0);
    setIsUploadError(false);
    createUserFile(props.uploadRequest);
  };

  const cancelUpload = () => {
    // Cancel axios upload
    if (abortController) {
      abortController.abort();
    }

    // Delete user-file if it exists.
    if (userFile) {
      deleteUserFile(userFile.id).catch();
    }

    props.onCancel?.();
  };

  return (
    <div className={`${styles.fileUploadRow}`}>
      <div className={`${styles.fileIcon}`}>
        {GetFileCategoryIcon(props.uploadRequest.file.name.split(".").pop())}
      </div>
      <div
        className={`${styles.fileNameGroup} ${
          isUploadError ? styles.error : ""
        }`}
      >
        <div
          title={getFileName()}
          className={`${styles.fileName} ${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.UI.PrimaryColor}`}
        >
          {getFileName()}
        </div>
        {isUploadError ? (
          <h4
            className={`${styles.errorMessage} ${VTPStyles.Typography.Headers.H4Caption}`}
          >
            {t("uploadManager.uploadError")}
          </h4>
        ) : (
          <div className={styles.progress}>
            <div
              className={styles.progressBar}
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        )}
      </div>
      <div className={styles.buttonGroup}>
        {isUploadError ? (
          <VTPButton
            className={styles.removeButton}
            size={ButtonSize.Small}
            type={ButtonType.Tertiary}
            onClick={retryUpload}
          >
            <RefreshIcon />
          </VTPButton>
        ) : null}
        <VTPButton
          className={styles.removeButton}
          size={ButtonSize.Small}
          type={ButtonType.Tertiary}
          onClick={cancelUpload}
        >
          <TrashBin />
        </VTPButton>
      </div>
    </div>
  );
};

export default UploadManager;
