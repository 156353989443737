import React, { useEffect, useState } from "react";

export interface StateMachineHandle<T> {
  state: T;
  setState: React.Dispatch<React.SetStateAction<T>>;
}

function useStateMachine<T>(initialState: T): StateMachineHandle<T> {
  const [currentFlowState, setCurrentFlowState] = useState<T>(initialState);
  const [targetFlowState, setTargetFlowState] = useState<T>(initialState);

  useEffect(() => {
    if (currentFlowState === targetFlowState) return;
    setCurrentFlowState(targetFlowState);
  }, [targetFlowState]);

  return {
    state: currentFlowState,
    setState: setTargetFlowState,
  };
}

export default useStateMachine;
