import React, { MouseEventHandler } from "react";
import VTPStyles from "./../../styles/vtpStyles";
import { Button } from "react-bootstrap";
import buttonStyles from "./button.module.css";
import { StylingProps } from "../../lib/common";

export enum ButtonSize {
  Small,
  Medium,
  Large,
}

export enum ButtonType {
  Primary,
  Secondary,
  Tertiary,
}

interface VTPButtonProps extends StylingProps {
  onClick?: MouseEventHandler;
  disabled?: boolean;
  size: ButtonSize;
  type: ButtonType;
}

const VTPButton: React.FC<VTPButtonProps> = (props: VTPButtonProps) => {
  const getClassNames = () => {
    let sizeClass: string;
    switch (props.size) {
      case ButtonSize.Small:
        sizeClass = `${buttonStyles.buttonSmall} ${VTPStyles.Typography.Buttons.SizeSmall}`;
        break;
      case ButtonSize.Medium:
        sizeClass = `${buttonStyles.buttonMedium} ${VTPStyles.Typography.Buttons.SizeMedium}`;
        break;
      case ButtonSize.Large:
        sizeClass = `${buttonStyles.buttonLarge} ${VTPStyles.Typography.Buttons.SizeLarge}`;
        break;
    }

    let typeClass;
    switch (props.type) {
      case ButtonType.Primary:
        if (props.disabled) typeClass = buttonStyles.buttonPrimaryTypeInactive;
        else typeClass = `${buttonStyles.buttonPrimaryType}`;
        break;
      case ButtonType.Secondary:
        if (props.disabled)
          typeClass = buttonStyles.buttonSecondaryTypeInactive;
        else typeClass = `${buttonStyles.buttonSecondaryType}`;
        break;
      case ButtonType.Tertiary:
        if (props.disabled) {
          typeClass = buttonStyles.buttonTertiaryTypeInactive;
        } else {
          typeClass = `${buttonStyles.buttonTertiaryType}`;
        }
    }

    return `${sizeClass} ${typeClass}`;
  };

  return (
    <Button
      onClick={props.onClick}
      variant="outline-primary"
      style={props.style}
      disabled={props.disabled}
      className={`${getClassNames()} ${props.className}`}
    >
      <div className={buttonStyles.buttonContainer}>{props.children}</div>
    </Button>
  );
};

export default VTPButton;
export type { VTPButtonProps };
