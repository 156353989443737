import React, { ReactNode, useState } from "react";
import { StylingProps } from "../../lib/common";
import tableStyles from "./vtpTable.module.css";
import VTPStyles from "../../styles/vtpStyles";
import { ReactComponent as LoadingIcon } from "../../assets/icons/large_loading_icon.svg";

interface VTPTableProps extends StylingProps {
  tableInputs?: ReactNode[];
  columns: ReactNode[];
  rows: ReactNode[][];
  isLoading?: boolean;
}

const VTPTable = (props: VTPTableProps) => {
  const [rowsFocused, setRowsFocused] = useState<boolean[]>(
    Array(props.rows.length).fill(false)
  );

  const focusRow = (index: number) => {
    const newVal = Array(props.rows.length).fill(false);
    newVal[index] = true;
    setRowsFocused(newVal);
  };

  return (
    <div className={tableStyles.container}>
      <table className={`${tableStyles.vtpTable} ${props.className}`}>
        <thead>
          <tr>
            {props.columns.map((column, index) => {
              return (
                <th key={index}>
                  <h3
                    className={`${VTPStyles.Typography.Headers.H3EyebrowSmall} ${VTPStyles.Color.Text.SecondaryColor}`}
                  >
                    {column}
                  </h3>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.rows.map((row, index) => {
            return (
              <tr
                className={`${
                  rowsFocused[index] ? tableStyles.vtpTableFocusedRow : ""
                }`}
                key={index}
                onClick={() => {
                  focusRow(index);
                }}
              >
                {row.map((rowItem, rowItemIndex) => {
                  return (
                    <td
                      className={`${VTPStyles.Typography.Body.Medium} ${VTPStyles.Color.Text.PrimaryColor}`}
                      key={rowItemIndex}
                    >
                      {rowItem}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {props.isLoading ? (
        <div className={tableStyles.loadingContainer}>
          <div className={tableStyles.loadingBackdrop}>
            <div className={tableStyles.loadingSpinner}>
              <LoadingIcon />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default VTPTable;
