import styles from "./vtpNoItems.module.css";
import React from "react";
import { ReactComponent as KandaLogo } from "../../assets/logo/light_kanda_stacked.svg";
import { StylingProps } from "../../lib/common";

const VTPNoItems = (props: StylingProps) => {
  return (
    <div className={`${styles.container}`} style={props.style}>
      <div className={`${styles.noItems}`}>
        <KandaLogo />
        <div className={styles.message}>{props.children}</div>
      </div>
    </div>
  );
};

export default VTPNoItems;
