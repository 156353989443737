import React, { useEffect, useState } from "react";
import styles from "./vtpToggleSwitch.module.css";

interface Props {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  readonly?: boolean;
  disabled?: boolean;
  small?: boolean;
}

const VTPToggleSwitch = (props: Props) => {
  const [checked, setChecked] = useState(props.checked);
  const handleChange = () => {
    if (props.readonly) {
      return;
    }

    props.onChange?.(!checked);
  };

  useEffect(() => {
    if (props.checked !== undefined) {
      setChecked(props.checked);
    }
  }, [props.checked]);

  return (
    <label
      className={`${props.small ? styles.vtpToggleSwitchSmall: styles.vtpToggleSwitchLarge} ${
        props.readonly ? "no-pointer-events" : ""
      }`}
    >
      <input
        className={`${props.disabled ? "disabled" : ""}`}
        type="checkbox"
        onChange={() => handleChange()}
        checked={checked}
      />
      <span className={`${styles.slider}`} />
    </label>
  );
};

export default VTPToggleSwitch;
