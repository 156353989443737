import React from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";
import styles from "./vtpDateRangePicker.module.css";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";

const CustomCalendarIcon = () => <CalendarIcon />;

interface VTPDateRangePickerProps {
    defaultValue?: {from: Date, to: Date};
    onSelected?: (from: Date, to: Date) => void;
}

const VTPDateRangePicker = (props: VTPDateRangePickerProps) => {
  const defaultFromDate = () => {
      const currentDate = new Date();
      currentDate.setUTCMonth(currentDate.getUTCMonth() - 1);
      return currentDate;
  }

  return (
    <DateRangePicker
      className={styles.container}
      character={" - "}
      format={"dd/MM/yyyy"}
      showHeader={false}
      cleanable={false}
      defaultValue={props.defaultValue ? [props.defaultValue.from, props.defaultValue.to] : [defaultFromDate(), new Date()]}
      ranges={[]}
      onChange={(val) => {
        if (val) {
          props.onSelected?.(val[0], val[1]);
        }
      }}
      placement={"bottomEnd"}
      caretAs={CustomCalendarIcon}
    />
  );
};

export default VTPDateRangePicker;
