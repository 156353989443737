import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./vtpNumericInput.module.css";
import { StylingProps } from "../../../lib/common";
import { ReactComponent as InputSpinnerMinus } from "../../../assets/input-spinner-minus.svg";
import { ReactComponent as InputSpinnerPlus } from "../../../assets/input-spinner-plus.svg";

interface InputProps extends StylingProps {
  value?: number;
  minimum?: number;
  maximum?: number;
  onChange?: (value: number) => void;
  small?: boolean;
  readonly?: boolean;
  error?: boolean;
  disabled?: boolean;
}

const defaultInputWidth = 40;
const defaultContainerWidth = 140;
const smallInputWidth = 25;
const smallContainerWidth = 90;

const VTPNumericInput = (props: InputProps) => {
  const [value, setValue] = useState<string>(props.value?.toString() ?? "1");
  const [inputWidth, setInputWidth] = useState(
    props.small ? smallInputWidth : defaultInputWidth
  );
  const [containerWidth, setContainerWidth] = useState(
    props.small ? smallContainerWidth : defaultContainerWidth
  );

  useEffect(() => {
    setValue(props.value?.toString() ?? "1");
  }, [props.value]);

  // If the field is manually cleared, insert the minimum
  const valueBlurred = (newValue: string) => {
    const min = props.minimum?.toString() ?? "1";
    if (newValue == "") {
      setValue(min);
      props.onChange?.(+min);
    }
  };

  const getWidthAdjustment = () => {
    if (props.small) {
      return 0.65;
    } else {
      return 1;
    }
  };

  const getInputLengthAdjustment = (inputLength: number) => {
    return (inputLength - 2) * 16 * getWidthAdjustment();
  };

  const valueChanged = (newValue: string) => {
    let newNumber = +newValue;

    // Truncate and remove any decimal point
    newNumber = Math.trunc(newNumber);

    if (props.maximum) {
      newNumber = Math.min(props.maximum, newNumber);
    }

    if (props.minimum) {
      newNumber = Math.max(props.minimum, newNumber);
    }

    if (newNumber == 0) {
      setValue("");
    } else {
      setValue(newNumber.toString());
    }

    if (newValue.length <= 2) {
      setInputWidth(defaultInputWidth * getWidthAdjustment());
      setContainerWidth(defaultContainerWidth * getWidthAdjustment());
    } else if (newNumber <= (props.maximum ?? 1000000)) {
      setInputWidth(
        defaultInputWidth * getWidthAdjustment() +
          getInputLengthAdjustment(newValue.length)
      );
      setContainerWidth(
        defaultContainerWidth * getWidthAdjustment() +
          getInputLengthAdjustment(newValue.length)
      );
    }

    props.onChange?.(newNumber);
  };

  return (
    <div
      className={`${styles.vtpNumericInputBox} 
      ${props.small ? ` ${styles.small}` : ""} 
      ${props.error ? ` ${styles.error}` : ""}
      ${props.disabled ? ` ${styles.disabled}` : ""}`}
      style={{ width: containerWidth }}
    >
      <div
        className={`${styles.vtpNumericInputSpinnerMinus} no-text-select ${
          props.readonly ? "pe-none" : ""
        }`}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          if (+value > 1) {
            valueChanged((+value - 1).toString());
          }
        }}
      >
        <InputSpinnerMinus
          className={`${styles.vtpNumericInputSpinnerSvg}  
          ${props.small ? ` ${styles.small}` : ""} 
          ${props.error ? ` ${styles.error}` : ""}
          ${props.disabled ? ` ${styles.disabled}` : ""}`}
        />
      </div>
      <div className={styles.vtpNumericInputCounter}>
        <input
          className={`${styles.vtpNumericInputInput}  
          ${props.small ? ` ${styles.small}` : ""} 
          ${props.readonly ? "pe-none no-text-select" : ""} 
          ${props.error ? ` ${styles.error}` : ""}
          ${props.disabled ? ` ${styles.disabled}` : ""}`}
          id={styles.vtpNumericInputValue}
          type={"number"}
          value={value}
          onBlur={(event: ChangeEvent<HTMLInputElement>) => {
            valueBlurred(event.target.value);
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            valueChanged(event.target.value);
          }}
          style={{ maxWidth: inputWidth }}
        />
      </div>
      <div
        className={`${styles.vtpNumericInputSpinnerPlus} no-text-select ${
          props.readonly ? "pe-none" : ""
        }`}
        onClick={() => {
          if (props.readonly) {
            return;
          }

          valueChanged((+value + 1).toString());
        }}
      >
        <InputSpinnerPlus
          className={`${styles.vtpNumericInputSpinnerSvg}  
          ${props.small ? ` ${styles.small}` : ""} 
          ${props.error ? ` ${styles.error}` : ""}
          ${props.disabled ? ` ${styles.disabled}` : ""}`}
        />
      </div>
    </div>
  );
};

export default VTPNumericInput;
export type { InputProps };
