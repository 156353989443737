import React from "react";
import styles from "./vtpSortableTableHeader.module.css";
import {StylingProps} from "../../lib/common";
import {ReactComponent as SortingIcon} from "../../assets/icons/sorting.svg";
import useStateMachine, {StateMachineHandle} from "../../hooks/useStateMachine";

export enum SortingState {
    None = "none",
    Asc = "asc",
    Desc = "desc",
}

const sortingClassName = (sortingState: SortingState) => {
    switch (sortingState) {
        case SortingState.Asc:
            return styles.asc;
        case SortingState.Desc:
            return styles.desc;
        default:
            return "";
    }
};

interface SortingStateMachineProps<V> {
    value: V;
    sortingState: SortingState;
}

interface Props<T> extends StylingProps {
    text: string,
    value: T,
    stateMachine: StateMachineHandle<SortingStateMachineProps<T>>
}

export const useSortingStateMachine = <T, >(value: T, sort?: SortingState) => 
    useStateMachine<SortingStateMachineProps<T>>({sortingState: sort ?? SortingState.Asc, value: value});

const VTPSortableTableHeader = <T, >(props: Props<T>) => {
    // Compare state machine and prop value, 
    // if matching return state directly from state machine otherwise default to none.
    const getCurrentSortingState = ():SortingState => {
        if(props.stateMachine.state.value === props.value){
            return props.stateMachine.state.sortingState;
        }
        return SortingState.None;
    }
    
    return (
        <div
            className={`no-text-select ${styles.sortingLabel} ${sortingClassName(getCurrentSortingState())}`}
            onClick={() => {
                props.stateMachine.setState({
                    value: props.value,
                    sortingState: getCurrentSortingState() == SortingState.Asc ? SortingState.Desc : SortingState.Asc,
                });
            }}
        >
            <div>{props.text}</div>
            <SortingIcon/>
        </div>);
}

export default VTPSortableTableHeader;