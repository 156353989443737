import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import VTPTextInput, {BorderType} from "../base/form/vtpTextInput";
import {ReactComponent as PlusIcon} from "../../assets/icons/input_plus.svg";
import {ReactComponent as InfoIcon} from "../../assets/info.svg";
import {ReactComponent as ProfileIcon} from "../../assets/img/profile-icon.svg";
import {ReactComponent as CloseIcon} from "../../assets/close.svg";
import {IDropdownOption} from "../base/form/vtpDropdown";
import {GetUserRoles, UserRoles} from "../../lib/apiModels";
import CommonUtilities, {StylingProps} from "../../lib/common";
import styles from "./inviteUsersList.module.css";
import VTPStyles from "../../styles/vtpStyles";
import {useTranslation} from "react-i18next";
import {useVTPCloud} from "../../context/vtpCloud-context";

interface InviteUsersProps extends StylingProps {
  canDoLaterHint?: boolean;
  showLabel: boolean;
  listUpdateCallback?: (invites: UserInvite[]) => void;
}

interface UserInvite {
  email: string;
  role: UserRoles;
}

const InviteUsersList = (props: InviteUsersProps) => {
  const {t} = useTranslation();
  const [emailInput, setEmailInput] = useState<string>("");

  const [userInvites, setUserInvites] = useState<UserInvite[]>([]);
  const { getUsers } = useVTPCloud();
  useMemo<IDropdownOption[]>(() => GetUserRoles(t), []);
  const handleEmailInput = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value);
  };

  const addUserInvite = () => {
    if (emailInput && !userInvites.find((inv) => inv.email === emailInput)) {
      setUserInvites((oldArray) => [
        ...oldArray,
        { email: emailInput, role: UserRoles.Guest },
      ]);
      setEmailInput("");
    }
  };
  const removeUserInvite = (email: string) => {
    setUserInvites(userInvites.filter((item) => item.email !== email));
  };

  const validateEmailInput = (inputValue: string): boolean => {
    // Email input is optional if nothing is provided that it is valid.
    if (!inputValue) {
      return true;
    }

    // If input is provided then validate that it is an email
    return CommonUtilities.IsEmail(inputValue);
  };
  
  const checkIfUserIsNotMember = async (input: string) => {
    try {
      const result = await getUsers({ search: input.toLowerCase() });
      return !result.items.some(user => user.email === input.toLowerCase());
    } catch (error) {
      return false;
    }
  }
  
  const validateInput = async (email: string) =>{
    if(email && validateEmailInput(email)){
       if(await checkIfUserIsNotMember(email)){
         return true;
       }
    }
    
    return false;
  }

  const plusButtonClicked = () => {
    validateInput(emailInput).then((isValid) => {
      if(isValid){
        addUserInvite();
      }
    })
  };

  useEffect(() => {
    validateInput(emailInput).then((isValid) => {
      if(isValid){
        props.listUpdateCallback?.([...userInvites, { email: emailInput, role: UserRoles.Guest }]);
      } else {
        props.listUpdateCallback?.(userInvites);
      }
    })
  }, [emailInput]);

  useEffect(() => {
    props.listUpdateCallback?.(userInvites);
  }, [userInvites]);

  return (
    <div
      className={`${styles.inviteUsersListContainer} ${props.className ?? ""}`}
      style={props.style}
    >
      {props.showLabel ? (
          <h3 style={{ marginBottom: "18px" }} className={`${VTPStyles.Typography.Headers.H3EyebrowSmall} ${VTPStyles.Color.Text.SecondaryColor}`}>
            {t("inviteUserModal.subheader")}
          </h3>
      ) : null}
      <VTPTextInput
        placeholder={t("inviteUserModal.inputs.emailPlaceholder")}
        borderStyle={BorderType.RoundBorder}
        value={emailInput}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          handleEmailInput(event)
        }
        onSubmit={() => addUserInvite()}
        validationRules={[
          {
            validationFunction: validateEmailInput,
            validationErrorMessage: t("inviteUserModal.inputs.invalidEmail")
          },
          {
            validationFunction: (value) => {
              if(!value){
                return true;
              }
              
              return checkIfUserIsNotMember(value);
            },
            validationErrorMessage: t("inviteUserModal.inputs.alreadyInvited")
          }
        ]}
        appendElement={
          <div
            onClick={() => plusButtonClicked()}
            className={`${styles.inviteUserPlusButton}`}
          >
            <PlusIcon fill={"#0C615A"} />
          </div>
        }
      />
      {props.canDoLaterHint && userInvites.length == 0 ? (
        <div className={`${styles.inviteUsersListCanDoLaterInfo}`}>
          <InfoIcon />
          <span>
            {t("inviteUserModal.inviteLaterText1")}
            <br />
            {t("inviteUserModal.inviteLaterText2")}
          </span>
        </div>
      ) : null}
      {userInvites.length != 0 ? (
        <div className={`${styles.inviteUsersList}`}>
          {userInvites.map((inv) => {
            return (
              <div key={inv.email} className={`${styles.inviteUsersListItem}`}>
                <div className={"d-flex flex-grow-1"}>
                  <span className={`${styles.inviteUsersListItemUserIcon}`}>
                    <ProfileIcon fill={"#0C615A"} />
                  </span>
                  <span className={`${styles.inviteUsersListItemEmail}`}>
                    {inv.email}
                  </span>
                </div>
                <span
                  onClick={() => removeUserInvite(inv.email)}
                  className={`${styles.inviteUsersListItemCloseIcon}`}
                >
                  <CloseIcon />
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default InviteUsersList;
export type { UserInvite };
