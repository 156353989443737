import React, { useEffect, useState } from "react";
import { TenantLicense, TokenAssignment } from "../../lib/apiModels";
import CommonUtilities from "../../lib/common";
import VTPToggleSwitch from "../base/form/vtpToggleSwitch";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { AlertType, useAlert } from "../../hooks/useAlert";
import styles from "./subscriptionInfoCard.module.css";
import VTPStyles from "../../styles/vtpStyles";
import VTPCard from "../base/vtpCard";
import VTPContextMenu from "../base/form/vtpContextMenu";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_pencil.svg";
import { Spinner } from "react-bootstrap";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import BatchInfoModal from "./batchInfoModal";
import VTPInputHeader from "../base/vtpInputHeader";
import { useTranslation } from "react-i18next";

interface SubscriptionProps {
  license: TenantLicense;
}

interface ActivityEntry {
  createdAt: string;
  expiresAt: string;
  tokenAssignment: TokenAssignment[];
}

function groupBy(list: TokenAssignment[]) {
  const map = new Map<string, ActivityEntry>();
  list.forEach((item) => {
    const createdAt = item.createdAt.split("T")[0];
    const expiresAt = item.expiryDate.split("T")[0];

    const activityEntry = map.get(createdAt);
    if (!activityEntry) {
      map.set(createdAt, {
        createdAt: createdAt,
        expiresAt: expiresAt,
        tokenAssignment: [item],
      });
    } else {
      activityEntry.tokenAssignment.push(item);
    }
  });

  return Array.from(map.values()).sort(function (a, b) {
    return b.createdAt.localeCompare(a.createdAt);
  });
}

const SubscriptionInfoCard = (props: SubscriptionProps) => {
  const { t } = useTranslation();
  const {refreshSelectedTenant, updateLicense } =
    useVTPCloud();
  const { pushAlert } = useAlert();
  const [editEnabled, setEditEnabled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [subscriptionNameInput, setSubscriptionNameInput] = useState<string>(
    props.license.name
  );
  const [batchInfoProps, setBatchInfoProps] = useState<{
    tokenAssignments?: TokenAssignment[];
    addedOnDate?: string;
  }>();

  useEffect(() => {
    if (!isExpanded) {
      setEditEnabled(false);
      setSubscriptionNameInput(props.license.name);
    }
  }, [isExpanded]);

  const loadingSpinner = (small: boolean, inverted?: boolean) => {
    return (
      <Spinner
        as="span"
        animation="border"
        role="status"
        aria-hidden="true"
        size={small ? "sm" : undefined}
        className={`vtp-spinner-dark ${small && !inverted ? "" : "text-light"}`}
      />
    );
  };

  const SubscriptionInfoCardHeader = () => {
    return (
      <div
        className={`${styles.subscriptionInfoCardHeader}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div style={{ position: "relative" }}>
          <VTPInputHeader
            className={styles.subscriptionNameInput}
            value={subscriptionNameInput}
            readonly={!editEnabled}
            onChange={(event) => setSubscriptionNameInput(event.target.value)}
          />
          <div className={styles.subscriptionReference}>
            {props.license.reference}
          </div>
        </div>
        <div className={`${VTPStyles.Typography.Body.Small}`}>
          <div className={`${VTPStyles.Color.Text.SecondaryColor}`}>
            {t("subscriptionsPage.licenses")}
          </div>
          <div className={`${VTPStyles.Color.Text.PrimaryColor}`}>
            {props.license.usedTokenCount}/{props.license.tokenCount}
          </div>
        </div>
        <div>
          <div className={`${VTPStyles.Typography.Body.Small}`}>
            <div className={`${VTPStyles.Color.Text.SecondaryColor}`}>
              {t("subscriptionsPage.batchExpiration")}
            </div>
            <div className={`${VTPStyles.Color.Text.PrimaryColor}`}>
              {CommonUtilities.FormatDate(props.license.expiryDate)}
            </div>
          </div>
          <VTPContextMenu
            alignRight={true}
            style={{ marginLeft: "auto" }}
            onOptionSelected={(option) => {
              if (option.value === "edit-subscription") {
                {
                  setIsExpanded(true);
                  setEditEnabled(true);
                }
              }
            }}
            options={[
              {
                icon: <EditIcon style={{ width: "14px", height: "18px" }} />,
                name: "Edit subscription",
                value: "edit-subscription",
              },
            ]}
          />
        </div>
      </div>
    );
  };

  const SubscriptionInfoCardActivityHistory = () => {
    const activities = groupBy(props.license.tokenAssignments);

    return (
      <div className={`${styles.subscriptionActivityContainer}`}>
        {activities.map((a, pos) => {
          return (
            <div key={pos} className={`${styles.subscriptionActivityRow}`}>
              <div
                className={`${styles.subscriptionActivityPurchaseLabel}
             ${VTPStyles.Typography.Body.Small}`}
              >
                {t("subscriptionsPage.paid")}
              </div>
              <div
                className={`${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Text.SecondaryColor}`}
              >
                <span
                  onClick={() =>
                    setBatchInfoProps({
                      tokenAssignments: a.tokenAssignment,
                      addedOnDate: a.createdAt,
                    })
                  }
                  className={`${VTPStyles.Color.UI.PrimaryColor} ${styles.batchInfoLink}`}
                >
                  {t("subscriptionsPage.addedUsers", {userCount: a.tokenAssignment.length})}
                </span>{" "}
                {t("subscriptionsPage.licenseExpiration", {addedAt: CommonUtilities.FormatDate(a.createdAt), expiresAt: CommonUtilities.FormatDate(a.expiresAt)})}
                {a.tokenAssignment.some((t) => t.autoRenew == false)
                  ? ""
                  : ` ${t("subscriptionsPage.usersRenewalStatus")}`}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const SubscriptionDetails = () => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [autoRenew, setAutoRenew] = useState(props.license.autoRenew);
    const onSubscriptionSave = async () => {
      if (isUpdating) {
        return;
      }

      setIsUpdating(true);

      try {
        await updateLicense({
          id: props.license.id,
          shouldRenew: autoRenew,
          name: subscriptionNameInput,
        });
        await refreshSelectedTenant();
      } catch (err) {
        pushAlert({
          type: AlertType.Error,
          message: t("subscriptionsPage.notifications.errorUpdatingSubscription"),
        });
      }

      setIsUpdating(false);
      setEditEnabled(false);
    };

    return (
      <div className={`${styles.subscriptionDetails}`}>
        <div
          className={`${VTPStyles.Typography.Body.Medium} ${VTPStyles.Color.Text.PrimaryColor}`}
        >
          {t("subscriptionsPage.yourSubscription")}
        </div>
        <div
          className={`${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Text.PrimaryColor} ${styles.subscriptionDetailsEditForm}`}
        >
          <div className={`${styles.subscriptionDetailsEditFormRow}`}>
            <div>{t("subscriptionsPage.automaticRenewal")}</div>
            <VTPToggleSwitch
              checked={autoRenew}
              onChange={(val) => {
                setAutoRenew(val);
              }}
            />
          </div>
          <div
            className={`${VTPStyles.Color.Text.SecondaryColor} ${styles.subscriptionDetailsEditFormRow}`}
          >
            {t("subscriptionsPage.turnOffRenewal", {licenseCount: props.license.tokenCount})}
          </div>
        </div>
        <div className={`${styles.subscriptionDetailsButtons}`}>
          <VTPButton
            onClick={() => setIsExpanded(false)}
            size={ButtonSize.Small}
            type={ButtonType.Tertiary}
          >
            {t("common.inputs.cancel")}
          </VTPButton>
          <VTPButton
            onClick={onSubscriptionSave}
            size={ButtonSize.Small}
            type={ButtonType.Primary}
            className={styles.subscriptionDetailsSaveButton}
          >
            {isUpdating ? loadingSpinner(true, true) : t("common.inputs.saveChanges")}
          </VTPButton>
        </div>
      </div>
    );
  };

  return (
    <>
      <VTPCard className={`${styles.subscriptionInfoCard}`}>
        {SubscriptionInfoCardHeader()}
        {isExpanded ? (
          <>
            <SubscriptionInfoCardActivityHistory />
            {editEnabled ? <SubscriptionDetails /> : null}
          </>
        ) : null}
      </VTPCard>
      {batchInfoProps?.tokenAssignments && batchInfoProps.addedOnDate ? (
        <BatchInfoModal
          tokenAssignments={batchInfoProps.tokenAssignments}
          addedOnDate={batchInfoProps.addedOnDate}
          license={props.license}
          buttonClicked={() => {
            setBatchInfoProps(undefined);
          }}
        />
      ) : null}
    </>
  );
};

export default SubscriptionInfoCard;
