import React, { useState } from "react";
import styles from "./assignTokensModal.module.css";
import { Course, User } from "../../../lib/apiModels";
import VTPSimpleModal from "../../modal/vtpSimpleModal";
import useStateMachine from "../../../hooks/useStateMachine";
import UserSelectionState from "./states/userSelectionState";
import ConfirmState from "./states/confirmState";
import CloseButton from "../../common/closeButton";

interface AssignTokensModalProps {
  course: Course;
  onCancel: () => any;
  onSubmit: () => any;
}

enum AssignTokenModalState {
  UserSelection,
  Confirm,
}

const AssignTokensModal = (props: AssignTokensModalProps) => {
  const modalState = useStateMachine(AssignTokenModalState.UserSelection);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const renderState = () => {
    if (modalState.state === AssignTokenModalState.UserSelection) {
      return (
        <UserSelectionState
          course={props.course}
          modalState={modalState}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
      );
    } else {
      return (
        <ConfirmState
          modalState={modalState}
          selectedUsers={selectedUsers}
          course={props.course}
          onSubmit={props.onSubmit}
        />
      );
    }
  };

  return (
    <VTPSimpleModal>
      <div className={styles.container}>
        <div className={styles.closeButton}>
          <CloseButton onClick={props.onCancel} />
        </div>
        {renderState()}
      </div>
    </VTPSimpleModal>
  );
};

export default AssignTokensModal;
export { AssignTokenModalState };
