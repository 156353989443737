import React from "react";
import styles from "./filesPage.module.css";
import PageSection from "../components/base/pageSection";
import { ReactComponent as FilesIcon } from "../assets/navigation/file.svg";
import VTPButton, { ButtonSize, ButtonType } from "../components/base/button";
import useStateMachine from "../hooks/useStateMachine";

// File type icons
import { ReactComponent as PdfIcon } from "../assets/icons/files/pdf.svg";
import { ReactComponent as VideoIcon } from "../assets/icons/files/video.svg";
import { ReactComponent as ImageIcon } from "../assets/icons/files/image.svg";
import { useRoleBasedAccess } from "../hooks/useRoleBasedAccess";
import { UserRoles } from "../lib/apiModels";
import CourseFiles from "../components/files/courseFiles";
import UserUploads from "../components/files/userUploads";
import pageSectionStyles from "../components/base/pageSection.module.css";
import SessionFiles from "../components/files/sessionFiles";
import {useTranslation} from "react-i18next";

const GetFileCategoryIcon = (fileExtension: string | undefined) => {
  const extension = fileExtension?.toLowerCase().replace(".", "");
  if (extension === "mp4" || extension === "avi") {
    return <VideoIcon />;
  } else if (
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === "png"
  ) {
    return <ImageIcon />;
  } else if (extension === "pdf") {
    return <PdfIcon />;
  } else {
    // Unknown format
    return <></>;
  }
};

enum Subpage {
  UserUploads,
  CourseFiles,
  SessionFiles,
}

const FilesPage = () => {
  const stateMachine = useStateMachine(Subpage.UserUploads);
  const { userHasRequiredRoleOrHigher } = useRoleBasedAccess();
  const { t } = useTranslation();

  const FileSwitchButtons = () => {
    return (
      <div className={`${styles.fileSwitchButtons}`}>
        {userHasRequiredRoleOrHigher(UserRoles.RegularUser) ? (
          <VTPButton
            className={
              stateMachine.state === Subpage.CourseFiles ? styles.active : ""
            }
            size={ButtonSize.Small}
            type={ButtonType.Tertiary}
            onClick={() => stateMachine.setState(Subpage.CourseFiles)}
          >
              {t("filesPage.pageSwitcher.courseFiles")}
          </VTPButton>
        ) : null}
        <VTPButton
          className={
            stateMachine.state === Subpage.UserUploads ? styles.active : ""
          }
          size={ButtonSize.Small}
          type={ButtonType.Tertiary}
          onClick={() => stateMachine.setState(Subpage.UserUploads)}
        >
            {t("filesPage.pageSwitcher.userFiles")}
        </VTPButton>
        <VTPButton
          className={
            stateMachine.state === Subpage.SessionFiles ? styles.active : ""
          }
          size={ButtonSize.Small}
          type={ButtonType.Tertiary}
          onClick={() => stateMachine.setState(Subpage.SessionFiles)}
        >
            {t("filesPage.pageSwitcher.sessionFiles")}
        </VTPButton>
      </div>
    );
  };

  const RenderSubpage = () => {
    if (
      stateMachine.state === Subpage.CourseFiles &&
      userHasRequiredRoleOrHigher(UserRoles.RegularUser)
    ) {
      return <CourseFiles />;
    } else if (stateMachine.state === Subpage.SessionFiles) {
      return <SessionFiles />;
    } else {
      return <UserUploads />;
    }
  };

  return (
    <div
      className={pageSectionStyles.singleSectionPage}
      style={
        stateMachine.state == Subpage.UserUploads ||
        stateMachine.state == Subpage.SessionFiles
          ? { backgroundColor: "#FFFFFF" }
          : undefined
      }
    >
      <PageSection
        className={`${styles.container}`}
        style={{ backgroundColor: "#FFFFFF" }}
        header={{
          text: t("filesPage.header"),
          icon: <FilesIcon className={`${styles.fileIcon}`} />,
        }}
        subheader={t("filesPage.subheader")}
      >
        <FileSwitchButtons />
      </PageSection>
      <RenderSubpage />
    </div>
  );
};

export default FilesPage;
export { GetFileCategoryIcon };
