import React, { ReactNode, useEffect, useState } from "react";
import TableSearchInput from "../base/table/tableSearchInput";
import styles from "./userUploads.module.css";
import { ButtonSize, ButtonType } from "../base/button";
import VTPTable from "../base/vtpTable";
import useStateMachine from "../../hooks/useStateMachine";
import { useEndpointPagination } from "../../hooks/usePagination";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as TeamIcon } from "../../assets/icons/group_outline.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/person_outline.svg";
import { ReactComponent as AdminGroupIcon } from "../../assets/icons/admin_panel_settings.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_pencil.svg";
import { ReactComponent as TrashbinIcon } from "../../assets/icons/trashbin.svg";
import { useAppContext } from "../../context/app-context";
import VTPContextMenu, {
  IContextMenuOption,
} from "../base/form/vtpContextMenu";
import VTPTooltip, { TooltipPosition } from "../base/vtpTooltip";
import {
  FileSharingOptions,
  User,
  UserFile,
  UserFileSortingKey,
  UserProfileResponse,
  UserRoles,
} from "../../lib/apiModels";
import UploadUserFilesModal from "./uploadUserFilesModal";
import CommonUtilities from "../../lib/common";
import { GetFileCategoryIcon } from "../../pages/filesPage";
import { AlertType, useAlert } from "../../hooks/useAlert";
import { useUploadManagerContext } from "../../context/useUploadManager-context";
import EditUserFileModal from "./editUserFileModal";
import VTPConfirmationModal from "../modal/vtpConfirmationModal";
import { getSortingHeader, SortingState } from "./fileSorting";
import { useRoleBasedAccess } from "../../hooks/useRoleBasedAccess";
import tableStyles from "../common/table.module.css";
import TableButton from "../base/table/tableButton";
import TablePagination from "../base/table/tablePagination";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import {Spinner} from "react-bootstrap";

const formatContentType = (fileType: string) => {
  return fileType.replace(".", "");
};

const getSortKey = (sortState: {
  value: UserFileSortingKey;
  state: SortingState;
}) => {
  return `${sortState.value}:${sortState.state}`;
};

const getIcon = (
  userFile: UserFile,
  t: TFunction<"translation", undefined>,
  userProfile?: UserProfileResponse
) => {
  const visibleAsAdmin = userProfile?.id === userFile.createdBy;

  if (userFile.sharingOption === FileSharingOptions.Private) {
    return (
      <VTPTooltip
        position={TooltipPosition.Top}
        tooltip={
          visibleAsAdmin
            ? t("filesPage.tooltips.visibleToYouAndAdmins")
            : t("filesPage.tooltips.visibleToYouAsAdmin")
        }
        tooltipWidth={visibleAsAdmin ? 205 : 160}
      >
        <UserIcon fill={"#0C615A"} className={styles.privateUserFileIcon} />
      </VTPTooltip>
    );
  } else if (userFile.sharingOption === FileSharingOptions.Everyone) {
    return (
      <VTPTooltip
        position={TooltipPosition.Top}
        tooltip={t("filesPage.tooltips.sharedWithEveryone")}
        tooltipWidth={150}
      >
        <TeamIcon className={styles.sharedUserFileIcon} />
      </VTPTooltip>
    );
  } else {
    return (
      <VTPTooltip
        position={TooltipPosition.Top}
        tooltip={t("filesPage.tooltips.sharedWithInstructors")}
        tooltipWidth={150}
      >
        <AdminGroupIcon className={styles.sharedUserFileIcon} />
      </VTPTooltip>
    );
  }
};

const UserUploads = () => {
  const { t } = useTranslation();
  const { getUserFiles, deleteUserFile, getUserFile } = useVTPCloud();
  const { userIsAdmin, userHasRequiredRoleOrHigher } = useRoleBasedAccess();
  const { getUserProfile } = useAppContext();

  const {
    subscribeOnFileListChanged,
    unsubscribeOnFileListChanged,
    fileListChanged,
  } = useUploadManagerContext();
  const { pushAlert } = useAlert();
  const [searchQuery, setSearchQuery] = useState<string>();

  // Modal states
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [editUserFileModal, setEditUserFileModal] = useState<UserFile>();
  const [deleteUserFileModal, setDeleteUserFileModal] = useState<UserFile>();

  const initialSortState = {
    value: UserFileSortingKey.DisplayName,
    state: SortingState.Asc,
  };

  const sortingState = useStateMachine(initialSortState);
  const userFilesPaging = useEndpointPagination(getUserFiles, 10, {
    search: searchQuery,
    sort: getSortKey(initialSortState),
    excludeScenarioFiles: true,
    excludeRoomContainerFiles: true,
    excludeOtherPrivateFiles: !userIsAdmin(),
    loadUsers: true,
  });

  // Sorting
  useEffect(() => {
    const sortKey = getSortKey(sortingState.state);
    if (userFilesPaging.options.sort !== sortKey) {
      userFilesPaging.setOptions({
        ...userFilesPaging.options,
        sort: sortKey,
      });
    }
  }, [sortingState.state]);

  // Search query with delay
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (userFilesPaging.options.search !== searchQuery) {
        userFilesPaging.setOptions({
          ...userFilesPaging.options,
          search: searchQuery,
        });
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);


  useEffect(() => {
    // If there are files that are not ready for transfer, reload page.
    const pollingRequired = userFilesPaging.pageData?.some(u => !u.isReadyForTransfer);
    if (!pollingRequired)
    {
      return;
    }

    const intervalId = setInterval(() => {
      userFilesPaging.reloadPage();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [userFilesPaging.pageData]);
  
  const fileListChangedCallback = () => {
    userFilesPaging.reset();
  };

  useEffect(() => {
    const eventId = window.crypto.randomUUID();
    subscribeOnFileListChanged(eventId, fileListChangedCallback);

    return () => {
      unsubscribeOnFileListChanged(eventId);
    };
  }, []);

  const formatUploadedBy = (user?: User) => {
    if (!user) {
      return t("filesPage.uploadedBy.others");
    }

    if (user.id == getUserProfile?.id) {
      return t("filesPage.uploadedBy.you");
    } else {
      return user.email;
    }
  };

  const initiateFileDownload = (
    userFileId: string,
    previewInBrowser: boolean
  ) => {
    getUserFile(userFileId, { preview: previewInBrowser }).then((res) => {
      window.open(res.transferUrl, "_blank");
    });
  };

  const handleUserFileDelete = (userFile: UserFile) => {
    deleteUserFile(userFile.id)
      .then(() => {
        pushAlert({
          message: t("filesPage.notifications.fileDeletedSuccess", {
            fileName: userFile.displayName,
          }),
          type: AlertType.Success,
        });
      })
      .catch(() =>
        pushAlert({
          message: t("filesPage.notifications.fileDeletedError", {
            fileName: userFile.displayName,
          }),
          type: AlertType.Error,
        })
      )
      .finally(() => {
        setDeleteUserFileModal(undefined);
        fileListChanged();
      });
  };

  const columns = () => {
    return [
      getSortingHeader(
        t("filesPage.tableColumns.fileName"),
        UserFileSortingKey.DisplayName,
        sortingState
      ),
      getSortingHeader(
        t("filesPage.tableColumns.type"),
        UserFileSortingKey.ContentType,
        sortingState
      ),
      getSortingHeader(
        t("filesPage.tableColumns.shared"),
        UserFileSortingKey.SharingOption,
        sortingState
      ),
      getSortingHeader(
        t("filesPage.tableColumns.size"),
        UserFileSortingKey.Size,
        sortingState
      ),
      getSortingHeader(
        t("filesPage.tableColumns.uploadedBy"),
        UserFileSortingKey.UploadedBy,
        sortingState
      ),
      null,
    ];
  };

  const contextMenuOptions = (userFile: UserFile): IContextMenuOption[] => {
    const canEdit =
      (userFile.sharingOption === FileSharingOptions.Everyone &&
        userIsAdmin()) ||
      (userFile.sharingOption === FileSharingOptions.Instructor &&
        userHasRequiredRoleOrHigher(UserRoles.RegularUser)) ||
      userFile.sharingOption === FileSharingOptions.Private;

    const editOptions = [
      {
        icon: <EditIcon style={{ width: "16px", height: "18px" }} />,
        name: t("filesPage.inputs.edit"),
        value: "edit",
      },
      {
        icon: <TrashbinIcon style={{ width: "14px", height: "18px" }} />,
        name: t("filesPage.inputs.delete"),
        value: "delete",
      },
    ];

    const readOptions = [
      {
        icon: <DownloadIcon className={`${styles.downloadIcon}`} />,
        name: t("filesPage.inputs.download"),
        value: "download",
      },
    ];

    return canEdit ? editOptions.concat(readOptions) : readOptions;
  };

  const rows = (): ReactNode[][] => {
    const userFiles = userFilesPaging.pageData ?? [];

    return userFiles.map((u, pos) => {
      return [
        <div
          key={pos}
          className={styles.fileInfoColumn}
          onClick={() => initiateFileDownload(u.id, true)}
        >
          <span className={styles.fileIcon}>
            {GetFileCategoryIcon(u.contentType)}
          </span>
          <div className={styles.fileName} title={u.displayName}>
            {u.displayName}
          </div>
        </div>,
        formatContentType(u.contentType),
        <div key={u.id} className={styles.sharedIconColumn}>
          {getIcon(u, t, getUserProfile)}
        </div>,
        CommonUtilities.FormatFileSize(u.sizeKilobytes * 1000, true),
        <div key={u.id} className={styles.uploadedByColumn}>
          {formatUploadedBy(u.createdByUser)}
        </div>,
        <div key={u.displayName}>{FileActionsMenu(u)}</div>,
      ];
    });
  };

  const FileActionsMenu = (userFile: UserFile) => {
    if (userFile.isReadyForTransfer) {
      return (
        <VTPContextMenu
          className={styles.userFileContextMenu}
          key={userFile.id}
          options={contextMenuOptions(userFile)}
          onOptionSelected={(selectedOption) => {
            if (selectedOption.value === "delete") {
              setDeleteUserFileModal(userFile);
            } else if (selectedOption.value === "edit") {
              setEditUserFileModal(userFile);
            } else if (selectedOption.value === "download") {
              initiateFileDownload(userFile.id, false);
            }
          }}
          alignRight={true}
        />
      );
    } else {
      return (
        <div className={styles.fileLoadingIcon}>
          <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
          />
        </div>
      );
    }
  };

  return (
    <div>
      <div className={`${styles.tableContainer}`}>
        <div className={`${tableStyles.tableButtons} ${tableStyles.absolute}`}>
          <TableSearchInput
            key={"search"}
            placeHolder={t("filesPage.inputs.searchFiles")}
            onChange={(val) => setSearchQuery(val.target.value)}
          />
          <TableButton
            key={"addUser"}
            type={ButtonType.Primary}
            size={ButtonSize.Small}
            onClick={() => setShowFileUploadModal(true)}
          >
            <UploadIcon className={`${styles.uploadIcon}`} />
            {t("filesPage.inputs.uploadFile")}
          </TableButton>
        </div>
        <VTPTable
          columns={columns()}
          rows={rows()}
          isLoading={userFilesPaging.isLoading}
        />
        <TablePagination
          currentPage={userFilesPaging.pagingInfo.CurrentPage}
          totalPages={userFilesPaging.pagingInfo.TotalPages}
          nextPage={userFilesPaging.nextPage}
          previousPage={userFilesPaging.previousPage}
        />
      </div>
      {deleteUserFileModal ? (
        <VTPConfirmationModal
          header={t("filesPage.deleteFileModal.header", {
            fileName: deleteUserFileModal.displayName,
          })}
          bodyText={t("filesPage.deleteFileModal.text1", {
            fileName: deleteUserFileModal.displayName,
          })}
          onCancel={() => setDeleteUserFileModal(undefined)}
          onCompletionFunction={() => handleUserFileDelete(deleteUserFileModal)}
          confirmationButtonText={t("common.inputs.confirm")}
        />
      ) : null}
      {editUserFileModal ? (
        <EditUserFileModal
          userFile={editUserFileModal}
          onCancel={() => setEditUserFileModal(undefined)}
          onConfirm={() => {
            setEditUserFileModal(undefined);
            userFilesPaging.reset();
          }}
        />
      ) : null}
      {showFileUploadModal ? (
        <UploadUserFilesModal
          onCancel={() => setShowFileUploadModal(false)}
          onSubmit={() => setShowFileUploadModal(false)}
        />
      ) : null}
    </div>
  );
};

export default UserUploads;
export { getSortKey, formatContentType, getIcon };
